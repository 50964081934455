export const Samples = [
    {
        Name: "_1",
        Start: { StartDate: "2022.02.10 02:00", Balance: 1000, Gap: 333, _GoalLine: 666, SafeAreayPoint: 99, Aversion: 0, Bomo: 13, Spread: 30 },
        Treads: [
            {},
            {
                Profit: 0.1,
                StartDate: "2022.02.10 02:00",
                EndDate: "2022.02.10 15:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 15:30",
                BuyPoints: 942,
                SalePoints: 579,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 999.97,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.74,
                StartDate: "2022.02.10 15:33",
                EndDate: "2022.02.10 15:41",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 15:33",
                BuyPoints: 772,
                SalePoints: 1135,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1000.07,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.03,
                StartDate: "2022.02.10 15:41",
                EndDate: "2022.02.10 19:01",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.10 17:06",
                BuyPoints: 613,
                SalePoints: 1054,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1000.81,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.61,
                StartDate: "2022.02.10 19:01",
                EndDate: "2022.02.10 19:46",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 19:01",
                BuyPoints: 1002,
                SalePoints: 639,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1001.84,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.13,
                StartDate: "2022.02.10 19:46",
                EndDate: "2022.02.11 03:06",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 21:40",
                BuyPoints: 958,
                SalePoints: 595,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1002.45,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.92,
                StartDate: "2022.02.11 03:06",
                EndDate: "2022.02.11 15:08",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.11 09:49",
                BuyPoints: 594,
                SalePoints: 983,
                MaxRisk: -0.82000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1002.58,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.74,
                StartDate: "2022.02.11 15:08",
                EndDate: "2022.02.11 20:32",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 17:15",
                BuyPoints: 904,
                SalePoints: 1267,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1003.5,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.07,
            },
            {
                Profit: 1.08,
                StartDate: "2022.02.11 20:32",
                EndDate: "2022.02.11 20:46",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 20:35",
                BuyPoints: 1070,
                SalePoints: 1433,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1004.24,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 3.01,
                StartDate: "2022.02.11 20:46",
                EndDate: "2022.02.14 17:38",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.14 15:49",
                BuyPoints: 599,
                SalePoints: 1222,
                MaxRisk: -9.830000000000041,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1005.32,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.75,
                StartDate: "2022.02.14 17:38",
                EndDate: "2022.02.14 18:36",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.14 17:38",
                BuyPoints: 1144,
                SalePoints: 781,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1008.33,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.02.14 18:36",
                EndDate: "2022.02.14 20:32",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.14 18:36",
                BuyPoints: 592,
                SalePoints: 955,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1009.08,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.39,
                StartDate: "2022.02.14 20:32",
                EndDate: "2022.02.15 05:33",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.14 20:54",
                BuyPoints: 724,
                SalePoints: 1087,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1009.64,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.66,
                StartDate: "2022.02.15 05:33",
                EndDate: "2022.02.15 10:10",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.15 05:33",
                BuyPoints: 1051,
                SalePoints: 688,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1010.03,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.78,
                StartDate: "2022.02.15 10:10",
                EndDate: "2022.02.15 10:45",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.15 10:23",
                BuyPoints: 1283,
                SalePoints: 920,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1010.69,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 2.87,
                StartDate: "2022.02.15 10:45",
                EndDate: "2022.02.15 14:22",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.15 12:07",
                BuyPoints: 1238,
                SalePoints: 797,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1011.47,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.6,
                StartDate: "2022.02.15 14:22",
                EndDate: "2022.02.15 15:40",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.15 14:22",
                BuyPoints: 628,
                SalePoints: 991,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1014.34,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.4,
                StartDate: "2022.02.15 15:40",
                EndDate: "2022.02.16 18:36",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.02.16 15:49",
                BuyPoints: 631,
                SalePoints: 1540,
                MaxRisk: -53.25999999999999,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1014.94,
                SafeAreayPoint: 63,
                Aversion: 78,
                LostTreads: -119.72,
            },
            {
                Profit: 0.97,
                StartDate: "2022.02.16 18:36",
                EndDate: "2022.02.17 06:16",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.16 21:05",
                BuyPoints: 607,
                SalePoints: 1048,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1021.34,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.21,
                StartDate: "2022.02.17 06:16",
                EndDate: "2022.02.17 10:27",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.17 10:26",
                BuyPoints: 633,
                SalePoints: 996,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1022.31,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 5.61,
                StartDate: "2022.02.17 10:27",
                EndDate: "2022.02.17 15:44",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.17 15:05",
                BuyPoints: 661,
                SalePoints: 1284,
                MaxRisk: -9.830000000000041,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1022.52,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.02.17 15:44",
                EndDate: "2022.02.17 16:02",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.17 15:44",
                BuyPoints: 950,
                SalePoints: 587,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1028.13,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.44,
                StartDate: "2022.02.17 16:02",
                EndDate: "2022.02.17 18:46",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.17 16:21",
                BuyPoints: 555,
                SalePoints: 1074,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1028.69,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 3.6,
                StartDate: "2022.02.17 18:46",
                EndDate: "2022.02.18 03:56",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.18 03:32",
                BuyPoints: 1176,
                SalePoints: 657,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1030.13,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 1.48,
                StartDate: "2022.02.18 03:56",
                EndDate: "2022.02.18 15:19",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.18 12:51",
                BuyPoints: 707,
                SalePoints: 1096,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1033.73,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.02.18 15:19",
                EndDate: "2022.02.18 17:45",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.18 15:19",
                BuyPoints: 950,
                SalePoints: 587,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1035.21,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.59,
                StartDate: "2022.02.18 17:45",
                EndDate: "2022.02.18 23:23",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.18 17:45",
                BuyPoints: 620,
                SalePoints: 983,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1035.77,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.16,
                StartDate: "2022.02.18 23:23",
                EndDate: "2022.02.21 02:36",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.21 01:01",
                BuyPoints: 609,
                SalePoints: 972,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1036.36,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.55,
                StartDate: "2022.02.21 02:36",
                EndDate: "2022.02.21 02:54",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.21 02:36",
                BuyPoints: 938,
                SalePoints: 575,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1036.52,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.31,
                StartDate: "2022.02.21 02:54",
                EndDate: "2022.02.21 06:38",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.21 03:09",
                BuyPoints: 1181,
                SalePoints: 740,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1037.07,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.54,
                StartDate: "2022.02.21 06:38",
                EndDate: "2022.02.21 10:44",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.21 06:38",
                BuyPoints: 571,
                SalePoints: 934,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1039.38,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.42,
                StartDate: "2022.02.21 10:44",
                EndDate: "2022.02.22 01:01",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.21 20:00",
                BuyPoints: 908,
                SalePoints: 1271,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1039.92,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.4,
            },
            {
                Profit: 21.9,
                StartDate: "2022.02.22 01:01",
                EndDate: "2022.02.22 10:57",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.22 09:57",
                BuyPoints: 1557,
                SalePoints: 804,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1040.34,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 0.92,
                StartDate: "2022.02.22 10:57",
                EndDate: "2022.02.22 15:32",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.22 14:31",
                BuyPoints: 594,
                SalePoints: 983,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1062.24,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.63,
                StartDate: "2022.02.22 15:32",
                EndDate: "2022.02.22 17:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.22 15:32",
                BuyPoints: 1021,
                SalePoints: 658,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1063.16,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.9399999999999999,
                StartDate: "2022.02.22 17:11",
                EndDate: "2022.02.22 20:14",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.22 17:57",
                BuyPoints: 600,
                SalePoints: 989,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1063.79,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.65,
                StartDate: "2022.02.22 20:14",
                EndDate: "2022.02.23 06:59",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.22 20:14",
                BuyPoints: 1046,
                SalePoints: 683,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1064.73,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 14.5,
                StartDate: "2022.02.23 06:59",
                EndDate: "2022.02.23 17:00",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.23 15:20",
                BuyPoints: 717,
                SalePoints: 1470,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1065.38,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 1.57,
                StartDate: "2022.02.23 17:00",
                EndDate: "2022.02.24 04:49",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.23 22:55",
                BuyPoints: 666,
                SalePoints: 1107,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1079.88,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.14,
                StartDate: "2022.02.24 04:49",
                EndDate: "2022.02.24 05:09",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 04:51",
                BuyPoints: 601,
                SalePoints: 964,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1081.45,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 2.21,
                StartDate: "2022.02.24 05:09",
                EndDate: "2022.02.24 06:11",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.24 05:34",
                BuyPoints: 730,
                SalePoints: 1171,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1081.59,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.22,
                StartDate: "2022.02.24 06:11",
                EndDate: "2022.02.24 06:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:12",
                BuyPoints: 641,
                SalePoints: 1004,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1083.8,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.85,
                StartDate: "2022.02.24 06:17",
                EndDate: "2022.02.24 06:23",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:17",
                BuyPoints: 1245,
                SalePoints: 882,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1084.02,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.59,
                StartDate: "2022.02.24 06:23",
                EndDate: "2022.02.24 06:27",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:23",
                BuyPoints: 624,
                SalePoints: 987,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1084.87,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.58,
                StartDate: "2022.02.24 06:27",
                EndDate: "2022.02.24 06:35",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:27",
                BuyPoints: 968,
                SalePoints: 605,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1085.46,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.98,
                StartDate: "2022.02.24 06:35",
                EndDate: "2022.02.24 11:28",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.24 08:15",
                BuyPoints: 628,
                SalePoints: 1147,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1086.04,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.08,
                StartDate: "2022.02.24 11:28",
                EndDate: "2022.02.24 12:05",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 11:48",
                BuyPoints: 568,
                SalePoints: 931,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1089.02,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.58,
                StartDate: "2022.02.24 12:05",
                EndDate: "2022.02.24 12:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 12:13",
                BuyPoints: 820,
                SalePoints: 1183,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1089.1,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 8.550000000000001,
                StartDate: "2022.02.24 12:17",
                EndDate: "2022.02.24 14:17",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.24 13:52",
                BuyPoints: 1400,
                SalePoints: 647,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1089.68,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 1.13,
                StartDate: "2022.02.24 14:17",
                EndDate: "2022.02.24 15:03",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.24 14:35",
                BuyPoints: 637,
                SalePoints: 1026,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1098.23,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.79,
                StartDate: "2022.02.24 15:03",
                EndDate: "2022.02.24 15:48",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 15:03",
                BuyPoints: 1187,
                SalePoints: 824,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1099.36,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.08,
                StartDate: "2022.02.24 15:48",
                EndDate: "2022.02.24 16:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 16:17",
                BuyPoints: 1434,
                SalePoints: 1071,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1100.15,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 2.62,
                StartDate: "2022.02.24 16:33",
                EndDate: "2022.02.24 16:54",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.24 16:39",
                BuyPoints: 1212,
                SalePoints: 771,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1101.23,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.6,
                StartDate: "2022.02.24 16:54",
                EndDate: "2022.02.24 17:03",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 16:54",
                BuyPoints: 633,
                SalePoints: 996,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1103.85,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.62,
                StartDate: "2022.02.24 17:03",
                EndDate: "2022.02.24 17:16",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:03",
                BuyPoints: 1016,
                SalePoints: 653,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1104.45,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.41,
                StartDate: "2022.02.24 17:16",
                EndDate: "2022.02.24 17:18",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:17",
                BuyPoints: 1097,
                SalePoints: 734,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1105.07,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 1.66,
                StartDate: "2022.02.24 17:18",
                EndDate: "2022.02.24 17:44",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.24 17:22",
                BuyPoints: 743,
                SalePoints: 1132,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1105.48,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.61,
                StartDate: "2022.02.24 17:44",
                EndDate: "2022.02.24 18:22",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:44",
                BuyPoints: 999,
                SalePoints: 636,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1107.14,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.71,
                StartDate: "2022.02.24 18:22",
                EndDate: "2022.02.24 21:09",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.24 20:50",
                BuyPoints: 1421,
                SalePoints: 980,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1107.75,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.65,
                StartDate: "2022.02.24 21:09",
                EndDate: "2022.02.24 21:20",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:14",
                BuyPoints: 1219,
                SalePoints: 856,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1112.46,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.18,
                StartDate: "2022.02.24 21:20",
                EndDate: "2022.02.24 21:27",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:27",
                BuyPoints: 984,
                SalePoints: 621,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1113.11,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 3.89,
                StartDate: "2022.02.24 21:27",
                EndDate: "2022.02.24 22:27",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.24 22:14",
                BuyPoints: 671,
                SalePoints: 1190,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1113.29,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.46,
                StartDate: "2022.02.24 22:27",
                EndDate: "2022.02.24 23:28",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 22:46",
                BuyPoints: 762,
                SalePoints: 1125,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1117.18,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 3.68,
                StartDate: "2022.02.24 23:28",
                EndDate: "2022.02.25 03:01",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.25 02:24",
                BuyPoints: 878,
                SalePoints: 1319,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1117.64,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 1.29,
                StartDate: "2022.02.25 03:01",
                EndDate: "2022.02.25 03:25",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 03:08",
                BuyPoints: 1059,
                SalePoints: 670,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1121.32,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.9,
                StartDate: "2022.02.25 03:25",
                EndDate: "2022.02.25 08:45",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 03:45",
                BuyPoints: 591,
                SalePoints: 980,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1122.61,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 1.13,
                StartDate: "2022.02.25 08:45",
                EndDate: "2022.02.25 10:16",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 09:23",
                BuyPoints: 1026,
                SalePoints: 637,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1123.51,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.49,
                StartDate: "2022.02.25 10:16",
                EndDate: "2022.02.25 13:40",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 13:24",
                BuyPoints: 1137,
                SalePoints: 774,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1124.64,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.86,
                StartDate: "2022.02.25 13:40",
                EndDate: "2022.02.25 14:31",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 13:47",
                BuyPoints: 582,
                SalePoints: 971,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1125.13,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.66,
                StartDate: "2022.02.25 14:31",
                EndDate: "2022.02.25 14:53",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 14:31",
                BuyPoints: 1049,
                SalePoints: 686,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1125.99,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.48,
                StartDate: "2022.02.25 14:53",
                EndDate: "2022.02.25 15:56",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.25 15:09",
                BuyPoints: 1099,
                SalePoints: 658,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1126.65,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.54,
                StartDate: "2022.02.25 15:56",
                EndDate: "2022.02.25 16:44",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 15:56",
                BuyPoints: 568,
                SalePoints: 931,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1128.130000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.54,
                StartDate: "2022.02.25 16:44",
                EndDate: "2022.02.25 17:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 16:44",
                BuyPoints: 935,
                SalePoints: 572,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1128.670000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 7.67,
                StartDate: "2022.02.25 17:00",
                EndDate: "2022.02.28 09:54",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.25 19:53",
                BuyPoints: 1042,
                SalePoints: 1561,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1129.21,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -15.2,
            },
            {
                Profit: 0.87,
                StartDate: "2022.02.28 09:54",
                EndDate: "2022.02.28 12:55",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.28 12:14",
                BuyPoints: 597,
                SalePoints: 1038,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1136.88,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 2.6,
                StartDate: "2022.02.28 12:55",
                EndDate: "2022.02.28 18:31",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.28 17:46",
                BuyPoints: 1330,
                SalePoints: 577,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1137.75,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 1.17,
                StartDate: "2022.02.28 18:31",
                EndDate: "2022.02.28 22:32",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.28 20:22",
                BuyPoints: 644,
                SalePoints: 1033,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1140.35,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 1.16,
                StartDate: "2022.02.28 22:32",
                EndDate: "2022.03.01 11:40",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.01 10:12",
                BuyPoints: 625,
                SalePoints: 1066,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1141.52,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 6.54,
                StartDate: "2022.03.01 11:40",
                EndDate: "2022.03.01 17:48",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.01 16:38",
                BuyPoints: 683,
                SalePoints: 1306,
                MaxRisk: -9.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1142.680000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.24,
                StartDate: "2022.03.01 17:48",
                EndDate: "2022.03.01 20:23",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 18:43",
                BuyPoints: 651,
                SalePoints: 1014,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1149.22,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 7.5,
                StartDate: "2022.03.01 20:23",
                EndDate: "2022.03.02 12:26",
                EndLot: 2.56,
                LastTread: "Sale",
                BuyLot: 1.7,
                SaleLot: 3.41,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.03.02 10:24",
                BuyPoints: 1756,
                SalePoints: 665,
                MaxRisk: -126.37,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1149.46,
                SafeAreayPoint: 56.00000000000001,
                Aversion: 91,
                LostTreads: -264.54,
            },
            {
                Profit: 1.05,
                StartDate: "2022.03.02 12:26",
                EndDate: "2022.03.02 12:26",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 12:26",
                BuyPoints: 1420,
                SalePoints: 1057,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1156.96,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.83,
                StartDate: "2022.03.02 12:26",
                EndDate: "2022.03.02 12:27",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 12:26",
                BuyPoints: 859,
                SalePoints: 1222,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1158.01,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: -29.54,
                StartDate: "2022.03.02 12:27",
                EndDate: "2022.03.03 13:32",
                EndLot: 5.12,
                LastTread: "Buy",
                BuyLot: 6.82,
                SaleLot: 3.41,
                LotDifference: 3.41,
                LastLotSartedDate: "2022.03.02 22:05",
                BuyPoints: 667,
                SalePoints: 1966,
                MaxRisk: -302.3100000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1158.84,
                SafeAreayPoint: 50,
                Aversion: 104,
                LostTreads: -589.6800000000001,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.03 13:32",
                EndDate: "2022.03.03 14:37",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.03 13:32",
                BuyPoints: 974,
                SalePoints: 611,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1129.3,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.55,
                StartDate: "2022.03.03 14:37",
                EndDate: "2022.03.03 22:18",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.03 19:44",
                BuyPoints: 703,
                SalePoints: 1222,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1129.88,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.23,
                StartDate: "2022.03.03 22:18",
                EndDate: "2022.03.04 02:44",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 02:24",
                BuyPoints: 646,
                SalePoints: 1009,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1134.43,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.04 02:44",
                EndDate: "2022.03.04 03:03",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 02:44",
                BuyPoints: 1047,
                SalePoints: 684,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1134.66,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.33,
                StartDate: "2022.03.04 03:03",
                EndDate: "2022.03.04 03:52",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 03:24",
                BuyPoints: 1056,
                SalePoints: 693,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1135.31,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.04 03:52",
                EndDate: "2022.03.04 04:58",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 03:52",
                BuyPoints: 647,
                SalePoints: 1010,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1135.64,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.09,
                StartDate: "2022.03.04 04:58",
                EndDate: "2022.03.04 14:14",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 11:17",
                BuyPoints: 577,
                SalePoints: 940,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1136.26,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.04 14:14",
                EndDate: "2022.03.04 15:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 14:14",
                BuyPoints: 1007,
                SalePoints: 644,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1136.35,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.04 15:06",
                EndDate: "2022.03.04 16:15",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 15:06",
                BuyPoints: 570,
                SalePoints: 933,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1136.96,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.32,
                StartDate: "2022.03.04 16:15",
                EndDate: "2022.03.04 16:38",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 16:30",
                BuyPoints: 689,
                SalePoints: 1052,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1137.5,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.6,
                StartDate: "2022.03.04 16:38",
                EndDate: "2022.03.04 16:59",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 16:38",
                BuyPoints: 996,
                SalePoints: 633,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1137.82,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.98,
                StartDate: "2022.03.04 16:59",
                EndDate: "2022.03.04 17:47",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.04 17:10",
                BuyPoints: 607,
                SalePoints: 996,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1138.42,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.35,
                StartDate: "2022.03.04 17:47",
                EndDate: "2022.03.04 21:41",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 18:09",
                BuyPoints: 705,
                SalePoints: 1068,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1139.4,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.07000000000000001,
                StartDate: "2022.03.04 21:41",
                EndDate: "2022.03.07 01:02",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:01",
                BuyPoints: 567,
                SalePoints: 930,
                MaxRisk: 0,
                MaxProfit: 0.009999999999990905,
                StartedProfit: 0.01,
                StartEqity: 1139.75,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.7,
                StartDate: "2022.03.07 01:02",
                EndDate: "2022.03.07 01:13",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:05",
                BuyPoints: 879,
                SalePoints: 1242,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1139.820000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.07 01:13",
                EndDate: "2022.03.07 01:19",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:13",
                BuyPoints: 972,
                SalePoints: 609,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1140.520000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.07 01:19",
                EndDate: "2022.03.07 03:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:19",
                BuyPoints: 582,
                SalePoints: 945,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1141.100000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.07 03:00",
                EndDate: "2022.03.07 03:44",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.07 03:38",
                BuyPoints: 589,
                SalePoints: 1030,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1141.650000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.07 03:44",
                EndDate: "2022.03.07 04:09",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 03:44",
                BuyPoints: 1052,
                SalePoints: 689,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1142.450000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.05,
                StartDate: "2022.03.07 04:09",
                EndDate: "2022.03.07 08:07",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 04:31",
                BuyPoints: 1420,
                SalePoints: 1057,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1143.110000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.07 08:07",
                EndDate: "2022.03.07 09:33",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 08:07",
                BuyPoints: 595,
                SalePoints: 958,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1144.160000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.49,
                StartDate: "2022.03.07 09:33",
                EndDate: "2022.03.07 10:18",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 09:39",
                BuyPoints: 777,
                SalePoints: 1140,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1144.730000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 8.58,
                StartDate: "2022.03.07 10:18",
                EndDate: "2022.03.07 13:34",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.07 13:26",
                BuyPoints: 1414,
                SalePoints: 895,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1145.220000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 17.12,
                StartDate: "2022.03.07 13:34",
                EndDate: "2022.03.07 15:20",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.07 15:11",
                BuyPoints: 1558,
                SalePoints: 935,
                MaxRisk: -9.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1153.800000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.31,
                StartDate: "2022.03.07 15:20",
                EndDate: "2022.03.07 15:41",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:32",
                BuyPoints: 1047,
                SalePoints: 684,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1170.920000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.07 15:41",
                EndDate: "2022.03.07 15:47",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:41",
                BuyPoints: 650,
                SalePoints: 1013,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1171.230000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.52,
                StartDate: "2022.03.07 15:47",
                EndDate: "2022.03.07 15:56",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:52",
                BuyPoints: 790,
                SalePoints: 1153,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1171.850000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.07 15:56",
                EndDate: "2022.03.07 16:07",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:56",
                BuyPoints: 1017,
                SalePoints: 654,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1172.370000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.17,
                StartDate: "2022.03.07 16:07",
                EndDate: "2022.03.07 16:43",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.07 16:29",
                BuyPoints: 780,
                SalePoints: 1299,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1172.99,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 2.84,
                StartDate: "2022.03.07 16:43",
                EndDate: "2022.03.07 20:33",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.07 20:05",
                BuyPoints: 595,
                SalePoints: 1218,
                MaxRisk: -9.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1179.16,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 14.67,
                StartDate: "2022.03.07 20:33",
                EndDate: "2022.03.08 03:26",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.08 02:38",
                BuyPoints: 1472,
                SalePoints: 719,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1182,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.08 03:26",
                EndDate: "2022.03.08 07:05",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 03:26",
                BuyPoints: 659,
                SalePoints: 1022,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1196.67,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1,
                StartDate: "2022.03.08 07:05",
                EndDate: "2022.03.08 08:33",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 07:59",
                BuyPoints: 1030,
                SalePoints: 1393,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1197.3,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 3.62,
                StartDate: "2022.03.08 08:33",
                EndDate: "2022.03.08 08:57",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 08:42",
                BuyPoints: 871,
                SalePoints: 1312,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1198.3,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 5.29,
                StartDate: "2022.03.08 08:57",
                EndDate: "2022.03.08 10:15",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 09:49",
                BuyPoints: 1257,
                SalePoints: 738,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1201.920000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 2.38,
                StartDate: "2022.03.08 10:15",
                EndDate: "2022.03.08 15:46",
                EndLot: 2.56,
                LastTread: "Buy",
                BuyLot: 3.41,
                SaleLot: 1.7,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.03.08 15:11",
                BuyPoints: 650,
                SalePoints: 1741,
                MaxRisk: -126.3700000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1207.21,
                SafeAreayPoint: 56.00000000000001,
                Aversion: 91,
                LostTreads: -264.54,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.08 15:46",
                EndDate: "2022.03.08 16:40",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 16:38",
                BuyPoints: 818,
                SalePoints: 1181,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1209.59,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.13,
                StartDate: "2022.03.08 16:40",
                EndDate: "2022.03.08 16:53",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 16:49",
                BuyPoints: 595,
                SalePoints: 958,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1210.170000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.08 16:53",
                EndDate: "2022.03.08 17:06",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:05",
                BuyPoints: 810,
                SalePoints: 1173,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1210.300000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.15,
                StartDate: "2022.03.08 17:06",
                EndDate: "2022.03.08 17:07",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:07",
                BuyPoints: 605,
                SalePoints: 968,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1210.860000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.85,
                StartDate: "2022.03.08 17:07",
                EndDate: "2022.03.08 17:09",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:07",
                BuyPoints: 1245,
                SalePoints: 882,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1211.010000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.19,
                StartDate: "2022.03.08 17:09",
                EndDate: "2022.03.08 17:14",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:09",
                BuyPoints: 987,
                SalePoints: 624,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1211.860000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.72,
                StartDate: "2022.03.08 17:14",
                EndDate: "2022.03.08 17:18",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:14",
                BuyPoints: 745,
                SalePoints: 1108,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1212.050000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.08 17:18",
                EndDate: "2022.03.08 17:20",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:18",
                BuyPoints: 946,
                SalePoints: 583,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1212.770000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.72,
                StartDate: "2022.03.08 17:20",
                EndDate: "2022.03.08 17:24",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:20",
                BuyPoints: 749,
                SalePoints: 1112,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1213.320000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.31,
                StartDate: "2022.03.08 17:24",
                EndDate: "2022.03.08 17:37",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:28",
                BuyPoints: 685,
                SalePoints: 1048,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1214.040000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 4.24,
                StartDate: "2022.03.08 17:37",
                EndDate: "2022.03.08 17:50",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 17:47",
                BuyPoints: 1207,
                SalePoints: 688,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1214.350000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 1,
                StartDate: "2022.03.08 17:50",
                EndDate: "2022.03.08 18:00",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 17:58",
                BuyPoints: 1050,
                SalePoints: 609,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1218.590000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.89,
                StartDate: "2022.03.08 18:00",
                EndDate: "2022.03.08 18:06",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 18:00",
                BuyPoints: 923,
                SalePoints: 1286,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1219.590000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.42,
                StartDate: "2022.03.08 18:06",
                EndDate: "2022.03.08 18:55",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 18:55",
                BuyPoints: 752,
                SalePoints: 1193,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1220.480000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 3.7,
                StartDate: "2022.03.08 18:55",
                EndDate: "2022.03.08 19:11",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 19:02",
                BuyPoints: 1181,
                SalePoints: 662,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1222.900000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.39,
                StartDate: "2022.03.08 19:11",
                EndDate: "2022.03.08 19:13",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:12",
                BuyPoints: 1089,
                SalePoints: 726,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1226.600000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 2.88,
                StartDate: "2022.03.08 19:13",
                EndDate: "2022.03.08 19:17",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 19:17",
                BuyPoints: 1239,
                SalePoints: 798,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1226.990000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 2.95,
                StartDate: "2022.03.08 19:17",
                EndDate: "2022.03.08 19:22",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:20",
                BuyPoints: 1001,
                SalePoints: 1390,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1229.870000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.77,
                StartDate: "2022.03.08 19:22",
                EndDate: "2022.03.08 19:28",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:22",
                BuyPoints: 1161,
                SalePoints: 798,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1232.820000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.48,
                StartDate: "2022.03.08 19:28",
                EndDate: "2022.03.08 19:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:31",
                BuyPoints: 1131,
                SalePoints: 768,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1233.590000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.08 19:33",
                EndDate: "2022.03.08 19:36",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:33",
                BuyPoints: 593,
                SalePoints: 956,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1234.070000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.08 19:36",
                EndDate: "2022.03.08 19:37",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:36",
                BuyPoints: 1190,
                SalePoints: 827,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1234.630000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.08 19:37",
                EndDate: "2022.03.08 19:38",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:37",
                BuyPoints: 590,
                SalePoints: 953,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1235.430000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.86,
                StartDate: "2022.03.08 19:38",
                EndDate: "2022.03.08 19:48",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:44",
                BuyPoints: 971,
                SalePoints: 582,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1235.990000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.08 19:48",
                EndDate: "2022.03.08 19:51",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:48",
                BuyPoints: 583,
                SalePoints: 946,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1236.850000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.08 19:51",
                EndDate: "2022.03.08 20:03",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:51",
                BuyPoints: 972,
                SalePoints: 609,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1237.400000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.59,
                StartDate: "2022.03.08 20:03",
                EndDate: "2022.03.08 20:14",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:03",
                BuyPoints: 620,
                SalePoints: 983,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1237.980000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.31,
                StartDate: "2022.03.08 20:14",
                EndDate: "2022.03.08 20:31",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:22",
                BuyPoints: 685,
                SalePoints: 1048,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1238.570000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.52,
                StartDate: "2022.03.08 20:31",
                EndDate: "2022.03.08 20:42",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:31",
                BuyPoints: 789,
                SalePoints: 1152,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1238.880000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.88,
                StartDate: "2022.03.08 20:42",
                EndDate: "2022.03.08 20:47",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:42",
                BuyPoints: 1275,
                SalePoints: 912,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1239.400000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.3,
                StartDate: "2022.03.08 20:47",
                EndDate: "2022.03.08 21:44",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 21:19",
                BuyPoints: 1180,
                SalePoints: 739,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1240.280000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.03.08 21:44",
                EndDate: "2022.03.08 22:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 21:44",
                BuyPoints: 721,
                SalePoints: 1084,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1242.580000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.12,
                StartDate: "2022.03.08 22:00",
                EndDate: "2022.03.08 22:29",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 22:12",
                BuyPoints: 591,
                SalePoints: 954,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1243.270000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 1.94,
                StartDate: "2022.03.08 22:29",
                EndDate: "2022.03.09 02:30",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 01:21",
                BuyPoints: 1188,
                SalePoints: 799,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1243.390000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 3.18,
                StartDate: "2022.03.09 02:30",
                EndDate: "2022.03.09 04:21",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.09 03:08",
                BuyPoints: 638,
                SalePoints: 1157,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1245.330000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.2,
            },
            {
                Profit: 0.09,
                StartDate: "2022.03.09 04:21",
                EndDate: "2022.03.09 05:45",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 04:42",
                BuyPoints: 575,
                SalePoints: 938,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1248.510000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.09 05:45",
                EndDate: "2022.03.09 07:51",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 05:45",
                BuyPoints: 977,
                SalePoints: 614,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1248.600000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.07,
                StartDate: "2022.03.09 07:51",
                EndDate: "2022.03.09 09:32",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 09:03",
                BuyPoints: 1157,
                SalePoints: 716,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1249.180000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.09 09:32",
                EndDate: "2022.03.09 10:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 09:32",
                BuyPoints: 576,
                SalePoints: 939,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1251.250000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 7.77,
                StartDate: "2022.03.09 10:00",
                EndDate: "2022.03.09 11:03",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.09 10:52",
                BuyPoints: 1375,
                SalePoints: 856,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1251.800000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 1.13,
                StartDate: "2022.03.09 11:03",
                EndDate: "2022.03.09 11:29",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 11:17",
                BuyPoints: 1064,
                SalePoints: 623,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1259.570000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 1.05,
                StartDate: "2022.03.09 11:29",
                EndDate: "2022.03.09 12:01",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 11:48",
                BuyPoints: 1055,
                SalePoints: 614,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1260.700000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.24,
                StartDate: "2022.03.09 12:01",
                EndDate: "2022.03.09 12:08",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 12:03",
                BuyPoints: 1014,
                SalePoints: 651,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1261.750000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.76,
                StartDate: "2022.03.09 12:08",
                EndDate: "2022.03.09 12:16",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 12:08",
                BuyPoints: 792,
                SalePoints: 1155,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1261.990000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 34.31,
                StartDate: "2022.03.09 12:16",
                EndDate: "2022.03.09 15:02",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.09 14:55",
                BuyPoints: 1703,
                SalePoints: 950,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1262.750000000001,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.09 15:02",
                EndDate: "2022.03.09 15:07",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:02",
                BuyPoints: 594,
                SalePoints: 957,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1297.060000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.09 15:07",
                EndDate: "2022.03.09 15:22",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:07",
                BuyPoints: 947,
                SalePoints: 584,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1297.620000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.28,
                StartDate: "2022.03.09 15:22",
                EndDate: "2022.03.09 15:26",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:25",
                BuyPoints: 1034,
                SalePoints: 671,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1298.170000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.09 15:26",
                EndDate: "2022.03.09 15:29",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:26",
                BuyPoints: 668,
                SalePoints: 1031,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1298.450000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.09 15:29",
                EndDate: "2022.03.09 15:51",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:29",
                BuyPoints: 1144,
                SalePoints: 781,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1299.090000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 9.18,
                StartDate: "2022.03.09 15:51",
                EndDate: "2022.03.09 16:08",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.09 16:06",
                BuyPoints: 1369,
                SalePoints: 746,
                MaxRisk: -9.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1299.840000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.72,
                StartDate: "2022.03.09 16:08",
                EndDate: "2022.03.09 16:13",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:08",
                BuyPoints: 752,
                SalePoints: 1115,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1309.020000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.16,
                StartDate: "2022.03.09 16:13",
                EndDate: "2022.03.09 16:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:14",
                BuyPoints: 608,
                SalePoints: 971,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1309.740000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.09 16:17",
                EndDate: "2022.03.09 16:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:17",
                BuyPoints: 998,
                SalePoints: 635,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1309.900000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 7.7,
                StartDate: "2022.03.09 16:31",
                EndDate: "2022.03.09 17:36",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.09 16:52",
                BuyPoints: 637,
                SalePoints: 1390,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1310.510000000001,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 1,
                StartDate: "2022.03.09 17:36",
                EndDate: "2022.03.09 19:23",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 19:11",
                BuyPoints: 1000,
                SalePoints: 611,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1318.21,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.17,
                StartDate: "2022.03.09 19:23",
                EndDate: "2022.03.09 21:08",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 19:34",
                BuyPoints: 976,
                SalePoints: 613,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1319.21,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.79,
                StartDate: "2022.03.09 21:08",
                EndDate: "2022.03.09 21:50",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 21:08",
                BuyPoints: 819,
                SalePoints: 1182,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1319.380000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 11.1,
                StartDate: "2022.03.09 21:50",
                EndDate: "2022.03.10 03:00",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 02:11",
                BuyPoints: 1430,
                SalePoints: 677,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1320.170000000001,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 1.72,
                StartDate: "2022.03.10 03:00",
                EndDate: "2022.03.10 08:33",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 07:32",
                BuyPoints: 755,
                SalePoints: 1144,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1331.27,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.72,
                StartDate: "2022.03.10 08:33",
                EndDate: "2022.03.10 09:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 08:33",
                BuyPoints: 1108,
                SalePoints: 745,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1332.99,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.14,
                StartDate: "2022.03.10 09:00",
                EndDate: "2022.03.10 10:43",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.10 10:03",
                BuyPoints: 588,
                SalePoints: 1107,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1333.71,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.10 10:43",
                EndDate: "2022.03.10 11:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 10:43",
                BuyPoints: 1263,
                SalePoints: 900,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1335.85,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.10 11:00",
                EndDate: "2022.03.10 11:55",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 11:00",
                BuyPoints: 779,
                SalePoints: 1142,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1336.72,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.82,
                StartDate: "2022.03.10 11:55",
                EndDate: "2022.03.10 12:13",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 12:11",
                BuyPoints: 939,
                SalePoints: 1302,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1337.47,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.10 12:13",
                EndDate: "2022.03.10 12:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 12:13",
                BuyPoints: 938,
                SalePoints: 575,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1338.29,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.7,
                StartDate: "2022.03.10 12:25",
                EndDate: "2022.03.10 13:02",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 12:25",
                BuyPoints: 734,
                SalePoints: 1097,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1338.84,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 9.43,
                StartDate: "2022.03.10 13:02",
                EndDate: "2022.03.10 15:51",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 15:50",
                BuyPoints: 1411,
                SalePoints: 658,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1339.54,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.09,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.10 15:51",
                EndDate: "2022.03.10 15:55",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 15:51",
                BuyPoints: 589,
                SalePoints: 952,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1348.97,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.11,
                StartDate: "2022.03.10 15:55",
                EndDate: "2022.03.10 17:11",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 16:57",
                BuyPoints: 1021,
                SalePoints: 632,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1349.53,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 1.2,
                StartDate: "2022.03.10 17:11",
                EndDate: "2022.03.10 18:05",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 17:21",
                BuyPoints: 651,
                SalePoints: 1040,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1350.64,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.10 18:05",
                EndDate: "2022.03.10 18:35",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 18:05",
                BuyPoints: 1037,
                SalePoints: 674,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1351.84,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.10 18:35",
                EndDate: "2022.03.10 19:04",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 18:35",
                BuyPoints: 575,
                SalePoints: 938,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1352.48,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 8.16,
                StartDate: "2022.03.10 19:04",
                EndDate: "2022.03.11 03:12",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 22:58",
                BuyPoints: 1396,
                SalePoints: 643,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1353.03,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.09,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.11 03:12",
                EndDate: "2022.03.11 04:38",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 03:12",
                BuyPoints: 659,
                SalePoints: 1022,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1361.19,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.11 04:38",
                EndDate: "2022.03.11 06:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 04:38",
                BuyPoints: 935,
                SalePoints: 572,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1361.82,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.81,
                StartDate: "2022.03.11 06:25",
                EndDate: "2022.03.11 13:25",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.11 13:23",
                BuyPoints: 1231,
                SalePoints: 790,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1362.36,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 1.08,
                StartDate: "2022.03.11 13:25",
                EndDate: "2022.03.11 13:52",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 13:48",
                BuyPoints: 626,
                SalePoints: 1015,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1365.17,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.11 13:52",
                EndDate: "2022.03.11 14:05",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 13:52",
                BuyPoints: 1139,
                SalePoints: 776,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1366.25,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.31,
                StartDate: "2022.03.11 14:05",
                EndDate: "2022.03.11 14:15",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 14:08",
                BuyPoints: 1046,
                SalePoints: 683,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1367,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.11 14:15",
                EndDate: "2022.03.11 14:27",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 14:15",
                BuyPoints: 591,
                SalePoints: 954,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1367.31,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.89,
                StartDate: "2022.03.11 14:27",
                EndDate: "2022.03.11 14:32",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 14:27",
                BuyPoints: 1278,
                SalePoints: 915,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1367.87,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.67,
                StartDate: "2022.03.11 14:32",
                EndDate: "2022.03.11 14:55",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 14:32",
                BuyPoints: 703,
                SalePoints: 1066,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1368.76,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.04,
                StartDate: "2022.03.11 14:55",
                EndDate: "2022.03.11 15:46",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 15:31",
                BuyPoints: 1408,
                SalePoints: 1019,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1369.43,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.86,
                StartDate: "2022.03.11 15:46",
                EndDate: "2022.03.11 16:07",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 15:46",
                BuyPoints: 891,
                SalePoints: 1254,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1372.47,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.35,
                StartDate: "2022.03.11 16:07",
                EndDate: "2022.03.11 17:01",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 16:36",
                BuyPoints: 705,
                SalePoints: 1068,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1373.33,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.11 17:01",
                EndDate: "2022.03.11 17:27",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 17:01",
                BuyPoints: 959,
                SalePoints: 596,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1373.680000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.11 17:27",
                EndDate: "2022.03.11 17:59",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 17:27",
                BuyPoints: 609,
                SalePoints: 972,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1374.25,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.11 17:59",
                EndDate: "2022.03.11 19:28",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.11 18:14",
                BuyPoints: 596,
                SalePoints: 1037,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1374.83,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.03.11 19:28",
                EndDate: "2022.03.11 20:21",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 19:28",
                BuyPoints: 1083,
                SalePoints: 720,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1375.700000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.08,
                StartDate: "2022.03.11 20:21",
                EndDate: "2022.03.14 02:05",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 01:02",
                BuyPoints: 935,
                SalePoints: 572,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1376.390000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 1,
                StartDate: "2022.03.14 02:05",
                EndDate: "2022.03.14 11:37",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.14 10:45",
                BuyPoints: 1050,
                SalePoints: 609,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1376.470000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 8.130000000000001,
                StartDate: "2022.03.14 11:37",
                EndDate: "2022.03.14 15:55",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.14 15:35",
                BuyPoints: 1344,
                SalePoints: 721,
                MaxRisk: -9.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1377.470000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.03.14 15:55",
                EndDate: "2022.03.14 15:58",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 15:55",
                BuyPoints: 722,
                SalePoints: 1085,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1385.600000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.55,
                StartDate: "2022.03.14 15:58",
                EndDate: "2022.03.15 02:00",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.14 19:31",
                BuyPoints: 1127,
                SalePoints: 608,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1386.290000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.2,
            },
            {
                Profit: 0.78,
                StartDate: "2022.03.15 02:00",
                EndDate: "2022.03.15 03:52",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.15 02:53",
                BuyPoints: 1029,
                SalePoints: 588,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1388.840000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.27,
                StartDate: "2022.03.15 03:52",
                EndDate: "2022.03.15 08:24",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 06:59",
                BuyPoints: 1028,
                SalePoints: 665,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1389.620000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 26.63,
                StartDate: "2022.03.15 08:24",
                EndDate: "2022.03.15 16:10",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.15 13:56",
                BuyPoints: 1659,
                SalePoints: 750,
                MaxRisk: -53.25999999999999,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1389.890000000001,
                SafeAreayPoint: 63,
                Aversion: 78,
                LostTreads: -119.72,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.15 16:10",
                EndDate: "2022.03.15 16:39",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 16:10",
                BuyPoints: 672,
                SalePoints: 1035,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1416.520000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.15 16:39",
                EndDate: "2022.03.15 17:07",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 16:39",
                BuyPoints: 970,
                SalePoints: 607,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1417.160000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.08,
                StartDate: "2022.03.15 17:07",
                EndDate: "2022.03.15 17:34",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 17:13",
                BuyPoints: 934,
                SalePoints: 571,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1417.740000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.15 17:34",
                EndDate: "2022.03.15 17:49",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 17:34",
                BuyPoints: 686,
                SalePoints: 1049,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1417.820000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.5,
                StartDate: "2022.03.15 17:49",
                EndDate: "2022.03.15 18:55",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.15 18:23",
                BuyPoints: 659,
                SalePoints: 1100,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1418.480000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 1.77,
                StartDate: "2022.03.15 18:55",
                EndDate: "2022.03.15 22:31",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.15 20:47",
                BuyPoints: 1153,
                SalePoints: 764,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1419.980000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.93,
                StartDate: "2022.03.15 22:31",
                EndDate: "2022.03.16 04:18",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.15 23:51",
                BuyPoints: 596,
                SalePoints: 985,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1421.750000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.16 04:18",
                EndDate: "2022.03.16 07:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 04:18",
                BuyPoints: 1030,
                SalePoints: 667,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1422.680000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.73,
                StartDate: "2022.03.16 07:11",
                EndDate: "2022.03.16 14:42",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.16 11:42",
                BuyPoints: 711,
                SalePoints: 1230,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1423.320000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.16 14:42",
                EndDate: "2022.03.16 15:23",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 14:42",
                BuyPoints: 1019,
                SalePoints: 656,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1428.050000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.95,
                StartDate: "2022.03.16 15:23",
                EndDate: "2022.03.16 17:15",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:11",
                BuyPoints: 1367,
                SalePoints: 1004,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1428.680000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.16 17:15",
                EndDate: "2022.03.16 17:26",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:15",
                BuyPoints: 580,
                SalePoints: 943,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1429.630000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 31.57,
                StartDate: "2022.03.16 17:26",
                EndDate: "2022.03.16 21:17",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.16 21:14",
                BuyPoints: 1671,
                SalePoints: 918,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1430.180000000001,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 1.58,
                StartDate: "2022.03.16 21:17",
                EndDate: "2022.03.16 21:40",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.16 21:21",
                BuyPoints: 726,
                SalePoints: 1115,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1461.750000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.28,
                StartDate: "2022.03.16 21:40",
                EndDate: "2022.03.16 21:52",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 21:45",
                BuyPoints: 669,
                SalePoints: 1032,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1463.330000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.24,
                StartDate: "2022.03.16 21:52",
                EndDate: "2022.03.16 22:53",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 22:18",
                BuyPoints: 651,
                SalePoints: 1014,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1463.610000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.18,
                StartDate: "2022.03.16 22:53",
                EndDate: "2022.03.17 06:22",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.17 04:29",
                BuyPoints: 620,
                SalePoints: 983,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1463.850000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.17 06:22",
                EndDate: "2022.03.17 10:24",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.17 06:22",
                BuyPoints: 973,
                SalePoints: 610,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1464.030000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.59,
                StartDate: "2022.03.17 10:24",
                EndDate: "2022.03.17 11:20",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.17 10:24",
                BuyPoints: 617,
                SalePoints: 980,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1464.610000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.8,
                StartDate: "2022.03.17 11:20",
                EndDate: "2022.03.17 17:48",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.17 15:35",
                BuyPoints: 594,
                SalePoints: 1217,
                MaxRisk: -9.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1465.200000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.17 17:48",
                EndDate: "2022.03.17 19:47",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.17 17:48",
                BuyPoints: 1009,
                SalePoints: 646,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1468.000000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.85,
                StartDate: "2022.03.17 19:47",
                EndDate: "2022.03.18 06:35",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.18 04:56",
                BuyPoints: 1435,
                SalePoints: 994,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1468.620000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.18 06:35",
                EndDate: "2022.03.18 06:51",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.18 06:35",
                BuyPoints: 678,
                SalePoints: 1041,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1473.470000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.8100000000000001,
                StartDate: "2022.03.18 06:51",
                EndDate: "2022.03.18 16:21",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.18 16:01",
                BuyPoints: 962,
                SalePoints: 573,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1474.120000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.9399999999999999,
                StartDate: "2022.03.18 16:21",
                EndDate: "2022.03.18 16:33",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.18 16:29",
                BuyPoints: 599,
                SalePoints: 988,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1474.930000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 1.35,
                StartDate: "2022.03.18 16:33",
                EndDate: "2022.03.18 19:35",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.18 18:00",
                BuyPoints: 1070,
                SalePoints: 681,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1475.870000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 13.98,
                StartDate: "2022.03.18 19:35",
                EndDate: "2022.03.21 17:34",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.21 16:49",
                BuyPoints: 711,
                SalePoints: 1464,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1477.220000000002,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 0.6,
                StartDate: "2022.03.21 17:34",
                EndDate: "2022.03.21 19:32",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 17:34",
                BuyPoints: 992,
                SalePoints: 629,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1491.200000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.78,
                StartDate: "2022.03.21 19:32",
                EndDate: "2022.03.21 21:57",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.21 20:46",
                BuyPoints: 968,
                SalePoints: 1357,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1491.800000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.21 21:57",
                EndDate: "2022.03.21 22:07",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 21:57",
                BuyPoints: 954,
                SalePoints: 591,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1494.580000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.16,
                StartDate: "2022.03.21 22:07",
                EndDate: "2022.03.22 11:58",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.22 11:07",
                BuyPoints: 1066,
                SalePoints: 625,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1495.140000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.22 11:58",
                EndDate: "2022.03.22 15:50",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.22 11:58",
                BuyPoints: 576,
                SalePoints: 939,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1496.300000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.22 15:50",
                EndDate: "2022.03.22 16:32",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.22 15:50",
                BuyPoints: 950,
                SalePoints: 587,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1496.850000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.41,
                StartDate: "2022.03.22 16:32",
                EndDate: "2022.03.22 17:06",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.22 17:03",
                BuyPoints: 1291,
                SalePoints: 850,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1497.410000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.22 17:06",
                EndDate: "2022.03.22 18:53",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.22 17:06",
                BuyPoints: 568,
                SalePoints: 931,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1500.820000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.14,
                StartDate: "2022.03.22 18:53",
                EndDate: "2022.03.23 12:33",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.23 11:02",
                BuyPoints: 626,
                SalePoints: 1249,
                MaxRisk: -9.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1501.360000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 2.8,
                StartDate: "2022.03.23 12:33",
                EndDate: "2022.03.23 21:54",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.23 19:27",
                BuyPoints: 594,
                SalePoints: 1217,
                MaxRisk: -9.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1505.500000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 25.78,
                StartDate: "2022.03.23 21:54",
                EndDate: "2022.03.24 14:40",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.24 13:19",
                BuyPoints: 745,
                SalePoints: 1654,
                MaxRisk: -53.25999999999999,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1508.300000000001,
                SafeAreayPoint: 63,
                Aversion: 78,
                LostTreads: -119.72,
            },
            {
                Profit: 1.41,
                StartDate: "2022.03.24 14:40",
                EndDate: "2022.03.24 15:25",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.24 15:16",
                BuyPoints: 1082,
                SalePoints: 693,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1534.080000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 1.28,
                StartDate: "2022.03.24 15:25",
                EndDate: "2022.03.24 16:27",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.24 15:30",
                BuyPoints: 667,
                SalePoints: 1056,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1535.490000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 1.1,
                StartDate: "2022.03.24 16:27",
                EndDate: "2022.03.24 17:31",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.24 17:14",
                BuyPoints: 619,
                SalePoints: 1060,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1536.770000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.24 17:31",
                EndDate: "2022.03.24 23:40",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.24 17:31",
                BuyPoints: 937,
                SalePoints: 574,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1537.870000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.24 23:40",
                EndDate: "2022.03.25 05:26",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.24 23:40",
                BuyPoints: 596,
                SalePoints: 959,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1538.410000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.25 05:26",
                EndDate: "2022.03.25 09:43",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.25 05:26",
                BuyPoints: 1046,
                SalePoints: 683,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1538.980000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.13,
                StartDate: "2022.03.25 09:43",
                EndDate: "2022.03.25 16:35",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.25 16:11",
                BuyPoints: 1225,
                SalePoints: 602,
                MaxRisk: -9.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1539.630000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.25 16:35",
                EndDate: "2022.03.25 17:06",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.25 16:35",
                BuyPoints: 599,
                SalePoints: 962,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1542.760000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 5.75,
                StartDate: "2022.03.25 17:06",
                EndDate: "2022.03.28 06:56",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.28 04:17",
                BuyPoints: 1279,
                SalePoints: 760,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1543.330000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.3,
                StartDate: "2022.03.28 06:56",
                EndDate: "2022.03.28 10:30",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 08:30",
                BuyPoints: 1044,
                SalePoints: 681,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1549.080000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 12.97,
                StartDate: "2022.03.28 10:30",
                EndDate: "2022.03.28 15:16",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.28 15:12",
                BuyPoints: 699,
                SalePoints: 1452,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1549.380000000001,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 0.83,
                StartDate: "2022.03.28 15:16",
                EndDate: "2022.03.28 16:13",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.28 15:21",
                BuyPoints: 967,
                SalePoints: 578,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1562.350000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 11.28,
                StartDate: "2022.03.28 16:13",
                EndDate: "2022.03.28 22:05",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.28 21:51",
                BuyPoints: 1419,
                SalePoints: 796,
                MaxRisk: -9.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1563.180000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 2.67,
                StartDate: "2022.03.28 22:05",
                EndDate: "2022.03.29 11:40",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 10:32",
                BuyPoints: 1218,
                SalePoints: 777,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1574.46,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 2.03,
                StartDate: "2022.03.29 11:40",
                EndDate: "2022.03.29 15:23",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 15:00",
                BuyPoints: 1154,
                SalePoints: 713,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1577.130000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 3.4,
                StartDate: "2022.03.29 15:23",
                EndDate: "2022.03.29 16:55",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.29 16:48",
                BuyPoints: 648,
                SalePoints: 1167,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1579.160000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.77,
                StartDate: "2022.03.29 16:55",
                EndDate: "2022.03.29 19:45",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 18:00",
                BuyPoints: 587,
                SalePoints: 1028,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1582.560000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 1.02,
                StartDate: "2022.03.29 19:45",
                EndDate: "2022.03.30 09:18",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.30 04:02",
                BuyPoints: 612,
                SalePoints: 1053,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1583.330000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.30 09:18",
                EndDate: "2022.03.30 11:22",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 09:18",
                BuyPoints: 1033,
                SalePoints: 670,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1584.350000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.30 11:22",
                EndDate: "2022.03.30 15:13",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 11:22",
                BuyPoints: 571,
                SalePoints: 934,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1584.990000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.63,
                StartDate: "2022.03.30 15:13",
                EndDate: "2022.03.30 17:51",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.30 16:34",
                BuyPoints: 673,
                SalePoints: 1114,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1585.530000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.30 17:51",
                EndDate: "2022.03.31 03:15",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 17:51",
                BuyPoints: 950,
                SalePoints: 587,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1587.160000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.11,
                StartDate: "2022.03.31 03:15",
                EndDate: "2022.03.31 07:28",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 04:29",
                BuyPoints: 948,
                SalePoints: 585,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1587.720000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.67,
                StartDate: "2022.03.31 07:28",
                EndDate: "2022.03.31 10:10",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 07:28",
                BuyPoints: 702,
                SalePoints: 1065,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1587.830000000002,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.6,
                StartDate: "2022.03.31 10:10",
                EndDate: "2022.03.31 16:34",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.31 15:20",
                BuyPoints: 637,
                SalePoints: 1260,
                MaxRisk: -9.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1588.500000000002,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 3.81,
                StartDate: "2022.03.31 16:34",
                EndDate: "2022.04.01 09:57",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.31 22:34",
                BuyPoints: 1186,
                SalePoints: 667,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1593.100000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 10.57,
                StartDate: "2022.04.01 09:57",
                EndDate: "2022.04.01 17:33",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.01 17:08",
                BuyPoints: 1402,
                SalePoints: 779,
                MaxRisk: -9.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1596.910000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.6,
                StartDate: "2022.04.01 17:33",
                EndDate: "2022.04.01 17:48",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 17:33",
                BuyPoints: 633,
                SalePoints: 996,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1607.480000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.22,
                StartDate: "2022.04.01 17:48",
                EndDate: "2022.04.01 20:33",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.01 18:55",
                BuyPoints: 1158,
                SalePoints: 639,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1608.080000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.54,
                StartDate: "2022.04.01 20:33",
                EndDate: "2022.04.01 21:41",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 20:33",
                BuyPoints: 567,
                SalePoints: 930,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1611.300000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.78,
                StartDate: "2022.04.01 21:41",
                EndDate: "2022.04.04 07:21",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 21:41",
                BuyPoints: 1176,
                SalePoints: 813,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1611.840000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.65,
                StartDate: "2022.04.04 07:21",
                EndDate: "2022.04.04 09:31",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.04 07:21",
                BuyPoints: 682,
                SalePoints: 1045,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1612.620000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.09,
                StartDate: "2022.04.04 09:31",
                EndDate: "2022.04.04 15:28",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.04 09:54",
                BuyPoints: 577,
                SalePoints: 940,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1613.270000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 3.74,
                StartDate: "2022.04.04 15:28",
                EndDate: "2022.04.04 17:00",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.04 16:44",
                BuyPoints: 1183,
                SalePoints: 664,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1613.360000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.57,
                StartDate: "2022.04.04 17:00",
                EndDate: "2022.04.04 17:31",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.04 17:00",
                BuyPoints: 600,
                SalePoints: 963,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1617.100000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.97,
                StartDate: "2022.04.04 17:31",
                EndDate: "2022.04.05 15:09",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.04 18:27",
                BuyPoints: 993,
                SalePoints: 604,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1617.670000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.57,
                StartDate: "2022.04.05 15:09",
                EndDate: "2022.04.05 15:34",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.05 15:09",
                BuyPoints: 602,
                SalePoints: 965,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1618.640000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.19,
                StartDate: "2022.04.05 15:34",
                EndDate: "2022.04.05 16:33",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.05 15:46",
                BuyPoints: 623,
                SalePoints: 986,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1619.210000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.85,
                StartDate: "2022.04.05 16:33",
                EndDate: "2022.04.05 17:12",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.05 17:05",
                BuyPoints: 970,
                SalePoints: 581,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1619.400000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 6.12,
                StartDate: "2022.04.05 17:12",
                EndDate: "2022.04.05 18:59",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.05 17:21",
                BuyPoints: 1562,
                SalePoints: 1121,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1620.250000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 1.21,
                StartDate: "2022.04.05 18:59",
                EndDate: "2022.04.06 12:04",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.06 10:21",
                BuyPoints: 653,
                SalePoints: 1042,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1626.370000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 1.15,
                StartDate: "2022.04.06 12:04",
                EndDate: "2022.04.06 14:11",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.06 13:30",
                BuyPoints: 1030,
                SalePoints: 641,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1627.580000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.06 14:11",
                EndDate: "2022.04.06 15:25",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 14:11",
                BuyPoints: 592,
                SalePoints: 955,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1628.730000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.59,
                StartDate: "2022.04.06 15:25",
                EndDate: "2022.04.06 21:09",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.06 17:29",
                BuyPoints: 1128,
                SalePoints: 609,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1629.290000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.58,
                StartDate: "2022.04.06 21:09",
                EndDate: "2022.04.06 21:35",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 21:09",
                BuyPoints: 611,
                SalePoints: 974,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1631.880000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.06 21:35",
                EndDate: "2022.04.06 21:42",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 21:35",
                BuyPoints: 948,
                SalePoints: 585,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1632.460000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.06 21:42",
                EndDate: "2022.04.06 21:58",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 21:42",
                BuyPoints: 580,
                SalePoints: 943,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1633.020000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 7.96,
                StartDate: "2022.04.06 21:58",
                EndDate: "2022.04.07 17:48",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.07 16:01",
                BuyPoints: 717,
                SalePoints: 1340,
                MaxRisk: -9.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1633.570000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 0.54,
                StartDate: "2022.04.07 17:48",
                EndDate: "2022.04.07 18:21",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.07 17:48",
                BuyPoints: 937,
                SalePoints: 574,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1641.530000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.18,
                StartDate: "2022.04.07 18:21",
                EndDate: "2022.04.08 15:14",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.08 08:30",
                BuyPoints: 648,
                SalePoints: 1037,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1642.070000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 0.63,
                StartDate: "2022.04.08 15:14",
                EndDate: "2022.04.08 15:38",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.08 15:14",
                BuyPoints: 1026,
                SalePoints: 663,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1643.250000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.6,
                StartDate: "2022.04.08 15:38",
                EndDate: "2022.04.08 16:34",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.08 15:38",
                BuyPoints: 629,
                SalePoints: 992,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1643.880000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.36,
                StartDate: "2022.04.08 16:34",
                EndDate: "2022.04.11 01:04",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.08 17:00",
                BuyPoints: 645,
                SalePoints: 1086,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1644.480000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.11 01:04",
                EndDate: "2022.04.11 05:30",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 01:04",
                BuyPoints: 940,
                SalePoints: 577,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1645.840000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.58,
                StartDate: "2022.04.11 05:30",
                EndDate: "2022.04.11 11:18",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 05:30",
                BuyPoints: 605,
                SalePoints: 968,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1646.390000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.16,
                StartDate: "2022.04.11 11:18",
                EndDate: "2022.04.11 12:19",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 11:20",
                BuyPoints: 610,
                SalePoints: 973,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1646.970000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.36,
                StartDate: "2022.04.11 12:19",
                EndDate: "2022.04.11 15:39",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 14:59",
                BuyPoints: 708,
                SalePoints: 1071,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1647.130000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.54,
                StartDate: "2022.04.11 15:39",
                EndDate: "2022.04.11 16:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 15:39",
                BuyPoints: 935,
                SalePoints: 572,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1647.490000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 8.720000000000001,
                StartDate: "2022.04.11 16:00",
                EndDate: "2022.04.11 16:56",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.11 16:48",
                BuyPoints: 1358,
                SalePoints: 735,
                MaxRisk: -9.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1648.030000000001,
                SafeAreayPoint: 75,
                Aversion: 52,
                LostTreads: -25.01,
            },
            {
                Profit: 4.13,
                StartDate: "2022.04.11 16:56",
                EndDate: "2022.04.12 06:40",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.11 22:01",
                BuyPoints: 683,
                SalePoints: 1202,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1656.750000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.12 06:40",
                EndDate: "2022.04.12 11:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 06:40",
                BuyPoints: 957,
                SalePoints: 594,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1660.880000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.74,
                StartDate: "2022.04.12 11:25",
                EndDate: "2022.04.12 15:14",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 11:25",
                BuyPoints: 767,
                SalePoints: 1130,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1661.440000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.35,
                StartDate: "2022.04.12 15:14",
                EndDate: "2022.04.12 15:54",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 15:33",
                BuyPoints: 704,
                SalePoints: 1067,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1662.180000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 0.58,
                StartDate: "2022.04.12 15:54",
                EndDate: "2022.04.12 16:49",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 15:54",
                BuyPoints: 977,
                SalePoints: 614,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1662.530000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.08,
                StartDate: "2022.04.12 16:49",
                EndDate: "2022.04.12 17:44",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.12 17:17",
                BuyPoints: 626,
                SalePoints: 1015,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1663.110000000001,
                SafeAreayPoint: 93,
                Aversion: 13,
                LostTreads: -2.09,
            },
            {
                Profit: 2.6,
                StartDate: "2022.04.12 17:44",
                EndDate: "2022.04.13 03:29",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.12 20:11",
                BuyPoints: 1129,
                SalePoints: 610,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1664.190000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.13 03:29",
                EndDate: "2022.04.13 05:28",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 03:29",
                BuyPoints: 579,
                SalePoints: 942,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1666.790000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.76,
                StartDate: "2022.04.13 05:28",
                EndDate: "2022.04.13 15:17",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.13 11:16",
                BuyPoints: 585,
                SalePoints: 1026,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1667.340000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.63,
                StartDate: "2022.04.13 15:17",
                EndDate: "2022.04.13 15:26",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 15:17",
                BuyPoints: 1026,
                SalePoints: 663,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1668.100000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.13 15:26",
                EndDate: "2022.04.13 16:34",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 15:26",
                BuyPoints: 582,
                SalePoints: 945,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1668.730000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.13 16:34",
                EndDate: "2022.04.14 08:26",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 16:34",
                BuyPoints: 940,
                SalePoints: 577,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1669.280000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.82,
                StartDate: "2022.04.14 08:26",
                EndDate: "2022.04.14 16:59",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.14 16:45",
                BuyPoints: 1233,
                SalePoints: 792,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1669.830000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.6,
                StartDate: "2022.04.14 16:59",
                EndDate: "2022.04.14 17:22",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.14 16:59",
                BuyPoints: 633,
                SalePoints: 996,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1672.650000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.33,
                StartDate: "2022.04.14 17:22",
                EndDate: "2022.04.18 01:51",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.14 19:36",
                BuyPoints: 743,
                SalePoints: 1184,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1673.250000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.16,
                StartDate: "2022.04.18 01:51",
                EndDate: "2022.04.18 09:34",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.18 02:33",
                BuyPoints: 610,
                SalePoints: 973,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1675.580000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: -1.06,
            },
            {
                Profit: 5.12,
                StartDate: "2022.04.18 09:34",
                EndDate: "2022.04.18 20:36",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.18 17:36",
                BuyPoints: 1249,
                SalePoints: 730,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1675.740000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 28.67,
                StartDate: "2022.04.18 20:36",
                EndDate: "2022.04.19 15:47",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.19 15:37",
                BuyPoints: 1671,
                SalePoints: 762,
                MaxRisk: -53.32999999999993,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1680.860000000001,
                SafeAreayPoint: 63,
                Aversion: 78,
                LostTreads: -119.72,
            },
            {
                Profit: 3.32,
                StartDate: "2022.04.19 15:47",
                EndDate: "2022.04.19 17:38",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.19 16:59",
                BuyPoints: 1282,
                SalePoints: 841,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1709.530000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.72,
                StartDate: "2022.04.19 17:38",
                EndDate: "2022.04.19 22:35",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.19 22:12",
                BuyPoints: 1022,
                SalePoints: 581,
                MaxRisk: -2,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1712.850000000001,
                SafeAreayPoint: 87,
                Aversion: 26,
                LostTreads: -5.380000000000001,
            },
            {
                Profit: 0.58,
                StartDate: "2022.04.19 22:35",
                EndDate: "2022.04.20 02:11",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.19 22:35",
                BuyPoints: 605,
                SalePoints: 968,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1713.570000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.58,
                StartDate: "2022.04.20 02:11",
                EndDate: "2022.04.20 04:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.20 02:11",
                BuyPoints: 970,
                SalePoints: 607,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1714.150000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 5.41,
                StartDate: "2022.04.20 04:25",
                EndDate: "2022.04.20 13:15",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.04.20 11:47",
                BuyPoints: 610,
                SalePoints: 1363,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1714.730000000001,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 0.54,
                StartDate: "2022.04.20 13:15",
                EndDate: "2022.04.20 15:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.20 13:15",
                BuyPoints: 932,
                SalePoints: 569,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1720.140000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.6,
                StartDate: "2022.04.20 15:31",
                EndDate: "2022.04.20 17:30",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.20 15:31",
                BuyPoints: 634,
                SalePoints: 997,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1720.680000000001,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.75,
                StartDate: "2022.04.20 17:30",
                EndDate: "2022.04.21 11:33",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.21 05:14",
                BuyPoints: 1326,
                SalePoints: 807,
                MaxRisk: -4.25,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1721.280000000001,
                SafeAreayPoint: 81,
                Aversion: 39,
                LostTreads: -11.19,
            },
            {
                Profit: 7.77,
                StartDate: "2022.04.21 11:33",
                EndDate: "2022.04.21 21:52",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.04.21 20:14",
                BuyPoints: 638,
                SalePoints: 1391,
                MaxRisk: -22.46000000000004,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1728.03,
                SafeAreayPoint: 69,
                Aversion: 65,
                LostTreads: -54.05,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.21 21:52",
                EndDate: "2022.04.22 06:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.21 21:52",
                BuyPoints: 940,
                SalePoints: 577,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1735.8,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.49,
                StartDate: "2022.04.22 06:06",
                EndDate: "2022.04.22 07:21",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.22 06:06",
                BuyPoints: 516,
                SalePoints: 879,
                MaxRisk: 0,
                MaxProfit: 0.5199999999999818,
                StartedProfit: 0,
                StartEqity: 1736.35,
                SafeAreayPoint: 99,
                Aversion: 0,
                LostTreads: 0,
            },
        ],

        End: {
            Balance: 1736.87,
            MaxmimLotsRiched: 5.12,
            Deals: 344,
            Eqity: 1736.84,
            Profit: -0.03,
            Ask: 1952.24,
            StartPoint: 0,
            AllLost: 1,
            AllLostProfit: -29.54,
            AllWin: 342,
            AllWinProfit: 766.4099999999995,
            EndDate: "2022.04.22 07:21",
            Spread: 30,
        },
        Reason: 0,
    },

    {
        Name: "_2",

        Start: { StartDate: "2022.02.10 02:00", Balance: 1000, Gap: 450, _GoalLine: 900, SafeAreayPoint: 130, Aversion: 0, Bomo: 20, Spread: 30 },
        Treads: [
            {},
            {
                Profit: 8.720000000000001,
                StartDate: "2022.02.10 02:00",
                EndDate: "2022.02.11 20:32",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.11 17:15",
                BuyPoints: 843,
                SalePoints: 1923,
                MaxRisk: -30.61000000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 999.97,
                SafeAreayPoint: 80,
                Aversion: 100,
                LostTreads: -74.64,
            },
            {
                Profit: 0.39,
                StartDate: "2022.02.11 20:32",
                EndDate: "2022.02.11 20:46",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 20:37",
                BuyPoints: 898,
                SalePoints: 1378,
                MaxRisk: -0.5,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1008.69,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.73,
                StartDate: "2022.02.11 20:46",
                EndDate: "2022.02.14 20:57",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.14 15:49",
                BuyPoints: 852,
                SalePoints: 1452,
                MaxRisk: -2.610000000000014,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1009.08,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 3.76,
                StartDate: "2022.02.14 20:57",
                EndDate: "2022.02.15 10:45",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.15 10:09",
                BuyPoints: 1818,
                SalePoints: 1298,
                MaxRisk: -1.050000000000068,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1010.81,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 9.869999999999999,
                StartDate: "2022.02.15 10:45",
                EndDate: "2022.02.16 21:06",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.16 16:30",
                BuyPoints: 856,
                SalePoints: 1936,
                MaxRisk: -30.61000000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1014.57,
                SafeAreayPoint: 80,
                Aversion: 100,
                LostTreads: -74.59999999999999,
            },
            {
                Profit: 2.94,
                StartDate: "2022.02.16 21:06",
                EndDate: "2022.02.17 10:27",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.17 07:46",
                BuyPoints: 973,
                SalePoints: 1573,
                MaxRisk: -2.610000000000014,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1024.44,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 4.48,
                StartDate: "2022.02.17 10:27",
                EndDate: "2022.02.17 20:32",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.17 15:20",
                BuyPoints: 816,
                SalePoints: 1696,
                MaxRisk: -13.13,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1027.38,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 1.03,
                StartDate: "2022.02.17 20:32",
                EndDate: "2022.02.18 03:59",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.17 20:32",
                BuyPoints: 1540,
                SalePoints: 1060,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1031.86,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.88,
                StartDate: "2022.02.18 03:59",
                EndDate: "2022.02.18 15:34",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.18 03:59",
                BuyPoints: 911,
                SalePoints: 1391,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1032.89,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.32,
                StartDate: "2022.02.18 15:34",
                EndDate: "2022.02.21 06:39",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.21 02:55",
                BuyPoints: 1330,
                SalePoints: 810,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1033.77,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.75,
                StartDate: "2022.02.21 06:39",
                EndDate: "2022.02.21 20:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.21 06:39",
                BuyPoints: 778,
                SalePoints: 1258,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1035.09,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.27,
                StartDate: "2022.02.21 20:00",
                EndDate: "2022.02.22 01:02",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.21 20:51",
                BuyPoints: 840,
                SalePoints: 1320,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1035.84,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.8100000000000001,
                StartDate: "2022.02.22 01:02",
                EndDate: "2022.02.22 10:33",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.22 01:02",
                BuyPoints: 1321,
                SalePoints: 841,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1036.11,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.49,
                StartDate: "2022.02.22 10:33",
                EndDate: "2022.02.24 04:26",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.23 16:59",
                BuyPoints: 781,
                SalePoints: 1861,
                MaxRisk: -30.57000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1036.92,
                SafeAreayPoint: 80,
                Aversion: 100,
                LostTreads: -74.59999999999999,
            },
            {
                Profit: 0.36,
                StartDate: "2022.02.24 04:26",
                EndDate: "2022.02.24 05:10",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 04:50",
                BuyPoints: 885,
                SalePoints: 1365,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1040.41,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.8,
                StartDate: "2022.02.24 05:10",
                EndDate: "2022.02.24 06:12",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:08",
                BuyPoints: 1106,
                SalePoints: 1586,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1040.77,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.33,
                StartDate: "2022.02.24 06:12",
                EndDate: "2022.02.24 06:50",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.24 06:23",
                BuyPoints: 1333,
                SalePoints: 813,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1041.57,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.89,
                StartDate: "2022.02.24 06:50",
                EndDate: "2022.02.24 07:44",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:50",
                BuyPoints: 919,
                SalePoints: 1399,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1042.9,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.64,
                StartDate: "2022.02.24 07:44",
                EndDate: "2022.02.24 12:14",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 11:39",
                BuyPoints: 1027,
                SalePoints: 1507,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1043.79,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.17,
                StartDate: "2022.02.24 12:14",
                EndDate: "2022.02.24 12:25",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 12:17",
                BuyPoints: 792,
                SalePoints: 1272,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1044.43,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.9,
                StartDate: "2022.02.24 12:25",
                EndDate: "2022.02.24 14:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 12:25",
                BuyPoints: 1413,
                SalePoints: 933,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1044.599999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 23.72,
                StartDate: "2022.02.24 14:00",
                EndDate: "2022.02.24 16:33",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.24 16:11",
                BuyPoints: 2154,
                SalePoints: 1274,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1045.5,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 0.62,
                StartDate: "2022.02.24 16:33",
                EndDate: "2022.02.24 17:17",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 16:41",
                BuyPoints: 1494,
                SalePoints: 1014,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1069.22,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.33,
                StartDate: "2022.02.24 17:17",
                EndDate: "2022.02.24 17:20",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:18",
                BuyPoints: 1349,
                SalePoints: 869,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1069.84,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.74,
                StartDate: "2022.02.24 17:20",
                EndDate: "2022.02.24 17:24",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:20",
                BuyPoints: 770,
                SalePoints: 1250,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1070.17,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 14.44,
                StartDate: "2022.02.24 17:24",
                EndDate: "2022.02.24 21:09",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.24 18:20",
                BuyPoints: 2057,
                SalePoints: 1337,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1070.91,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 0.23,
                StartDate: "2022.02.24 21:09",
                EndDate: "2022.02.24 21:26",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:14",
                BuyPoints: 1302,
                SalePoints: 822,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1085.349999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.72,
                StartDate: "2022.02.24 21:26",
                EndDate: "2022.02.24 21:37",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:27",
                BuyPoints: 1546,
                SalePoints: 1066,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1085.579999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.85,
                StartDate: "2022.02.24 21:37",
                EndDate: "2022.02.24 21:44",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:37",
                BuyPoints: 883,
                SalePoints: 1363,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1086.299999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.42,
                StartDate: "2022.02.24 21:44",
                EndDate: "2022.02.24 23:24",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 22:27",
                BuyPoints: 913,
                SalePoints: 1393,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1087.149999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.02.24 23:24",
                EndDate: "2022.02.25 03:02",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 01:01",
                BuyPoints: 987,
                SalePoints: 1467,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1087.569999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.82,
                StartDate: "2022.02.25 03:02",
                EndDate: "2022.02.25 03:40",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 03:08",
                BuyPoints: 1430,
                SalePoints: 910,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1088.129999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.8,
                StartDate: "2022.02.25 03:40",
                EndDate: "2022.02.25 05:50",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 03:40",
                BuyPoints: 833,
                SalePoints: 1313,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1089.949999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.68,
                StartDate: "2022.02.25 05:50",
                EndDate: "2022.02.25 13:24",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 09:24",
                BuyPoints: 1402,
                SalePoints: 882,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1090.749999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.17,
                StartDate: "2022.02.25 13:24",
                EndDate: "2022.02.25 13:44",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 13:28",
                BuyPoints: 1272,
                SalePoints: 792,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1092.429999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.75,
                StartDate: "2022.02.25 13:44",
                EndDate: "2022.02.25 14:11",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 13:44",
                BuyPoints: 776,
                SalePoints: 1256,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1092.599999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.19,
                StartDate: "2022.02.25 14:11",
                EndDate: "2022.02.25 15:13",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 14:50",
                BuyPoints: 1504,
                SalePoints: 984,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1093.349999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 3.22,
                StartDate: "2022.02.25 15:13",
                EndDate: "2022.02.28 10:38",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.25 22:03",
                BuyPoints: 803,
                SalePoints: 1523,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1095.539999999999,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 0.38,
                StartDate: "2022.02.28 10:38",
                EndDate: "2022.02.28 15:45",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 12:54",
                BuyPoints: 896,
                SalePoints: 1376,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1098.759999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.77,
                StartDate: "2022.02.28 15:45",
                EndDate: "2022.02.28 17:53",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 15:45",
                BuyPoints: 1284,
                SalePoints: 804,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1099.139999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.19,
                StartDate: "2022.02.28 17:53",
                EndDate: "2022.02.28 18:52",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 18:19",
                BuyPoints: 1278,
                SalePoints: 798,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1099.909999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.82,
                StartDate: "2022.02.28 18:52",
                EndDate: "2022.02.28 21:26",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 18:52",
                BuyPoints: 853,
                SalePoints: 1333,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1100.099999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.16,
                StartDate: "2022.02.28 21:26",
                EndDate: "2022.03.01 11:32",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.01 07:53",
                BuyPoints: 795,
                SalePoints: 1395,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1100.919999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 9.44,
                StartDate: "2022.03.01 11:32",
                EndDate: "2022.03.01 18:43",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.01 16:38",
                BuyPoints: 934,
                SalePoints: 1814,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1102.079999999999,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 0.18,
                StartDate: "2022.03.01 18:43",
                EndDate: "2022.03.01 22:51",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 20:08",
                BuyPoints: 795,
                SalePoints: 1275,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1111.519999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 2.16,
                StartDate: "2022.03.01 22:51",
                EndDate: "2022.03.02 03:03",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.01 23:14",
                BuyPoints: 1497,
                SalePoints: 977,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1111.699999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 1.07,
                StartDate: "2022.03.02 03:03",
                EndDate: "2022.03.02 09:49",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 03:03",
                BuyPoints: 1096,
                SalePoints: 1576,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1113.859999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.02 09:49",
                EndDate: "2022.03.02 10:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 09:49",
                BuyPoints: 1308,
                SalePoints: 828,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1114.929999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.93,
                StartDate: "2022.03.02 10:25",
                EndDate: "2022.03.02 12:26",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.02 12:00",
                BuyPoints: 1672,
                SalePoints: 1072,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1115.729999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 1.35,
                StartDate: "2022.03.02 12:26",
                EndDate: "2022.03.02 13:07",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.02 12:26",
                BuyPoints: 816,
                SalePoints: 1336,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1119.659999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 6.31,
                StartDate: "2022.03.02 13:07",
                EndDate: "2022.03.02 18:10",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.02 17:31",
                BuyPoints: 1670,
                SalePoints: 950,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1121.009999999999,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 0.8100000000000001,
                StartDate: "2022.03.02 18:10",
                EndDate: "2022.03.02 21:55",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 18:10",
                BuyPoints: 838,
                SalePoints: 1318,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1127.319999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 27.2,
                StartDate: "2022.03.02 21:55",
                EndDate: "2022.03.04 02:37",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.03 20:31",
                BuyPoints: 971,
                SalePoints: 2291,
                MaxRisk: -74.00999999999999,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1128.129999999999,
                SafeAreayPoint: 70,
                Aversion: 120,
                LostTreads: -166.87,
            },
            {
                Profit: 1.67,
                StartDate: "2022.03.04 02:37",
                EndDate: "2022.03.04 03:50",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.04 02:50",
                BuyPoints: 1399,
                SalePoints: 879,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1155.329999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 1.6,
                StartDate: "2022.03.04 03:50",
                EndDate: "2022.03.04 12:04",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.04 04:19",
                BuyPoints: 866,
                SalePoints: 1386,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1156.999999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 2.49,
                StartDate: "2022.03.04 12:04",
                EndDate: "2022.03.04 18:11",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.04 17:09",
                BuyPoints: 928,
                SalePoints: 1528,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1158.599999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 4.66,
                StartDate: "2022.03.04 18:11",
                EndDate: "2022.03.07 01:02",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.04 20:34",
                BuyPoints: 1205,
                SalePoints: 1805,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1161.089999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.83,
            },
            {
                Profit: 0.16,
                StartDate: "2022.03.07 01:02",
                EndDate: "2022.03.07 03:01",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:05",
                BuyPoints: 785,
                SalePoints: 1265,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1165.749999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 8.119999999999999,
                StartDate: "2022.03.07 03:01",
                EndDate: "2022.03.07 08:07",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.07 04:30",
                BuyPoints: 1756,
                SalePoints: 1036,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1165.909999999999,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 0.91,
                StartDate: "2022.03.07 08:07",
                EndDate: "2022.03.07 09:42",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 08:07",
                BuyPoints: 938,
                SalePoints: 1418,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1174.029999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.15,
                StartDate: "2022.03.07 09:42",
                EndDate: "2022.03.07 12:40",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 09:58",
                BuyPoints: 778,
                SalePoints: 1258,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1174.939999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.85,
                StartDate: "2022.03.07 12:40",
                EndDate: "2022.03.07 13:32",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 12:40",
                BuyPoints: 1359,
                SalePoints: 879,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1175.089999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 7.31,
                StartDate: "2022.03.07 13:32",
                EndDate: "2022.03.07 15:20",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.07 14:02",
                BuyPoints: 2010,
                SalePoints: 1410,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1175.939999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 8.68,
                StartDate: "2022.03.07 15:20",
                EndDate: "2022.03.07 16:48",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.07 16:28",
                BuyPoints: 1063,
                SalePoints: 1783,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1183.249999999998,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 1.23,
                StartDate: "2022.03.07 16:48",
                EndDate: "2022.03.07 18:22",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.07 17:51",
                BuyPoints: 1313,
                SalePoints: 793,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1191.929999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.07 18:22",
                EndDate: "2022.03.07 19:56",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 18:22",
                BuyPoints: 827,
                SalePoints: 1307,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1193.159999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.24,
                StartDate: "2022.03.07 19:56",
                EndDate: "2022.03.07 22:56",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 20:18",
                BuyPoints: 825,
                SalePoints: 1305,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1193.959999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.96,
                StartDate: "2022.03.07 22:56",
                EndDate: "2022.03.08 03:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 22:56",
                BuyPoints: 1469,
                SalePoints: 989,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1194.199999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.63,
                StartDate: "2022.03.08 03:11",
                EndDate: "2022.03.08 08:20",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 04:12",
                BuyPoints: 1072,
                SalePoints: 1592,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1195.159999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.51,
                StartDate: "2022.03.08 08:20",
                EndDate: "2022.03.08 08:55",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 08:33",
                BuyPoints: 962,
                SalePoints: 1442,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1197.789999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 7.23,
                StartDate: "2022.03.08 08:55",
                EndDate: "2022.03.08 10:21",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 10:13",
                BuyPoints: 1714,
                SalePoints: 994,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1198.299999999998,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 0.84,
                StartDate: "2022.03.08 10:21",
                EndDate: "2022.03.08 10:24",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 10:21",
                BuyPoints: 873,
                SalePoints: 1353,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1205.529999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.01,
                StartDate: "2022.03.08 10:24",
                EndDate: "2022.03.08 10:35",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 10:24",
                BuyPoints: 1516,
                SalePoints: 1036,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1206.369999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.95,
                StartDate: "2022.03.08 10:35",
                EndDate: "2022.03.08 11:14",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 10:35",
                BuyPoints: 984,
                SalePoints: 1464,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1207.379999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 10.74,
                StartDate: "2022.03.08 11:14",
                EndDate: "2022.03.08 16:39",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.08 15:34",
                BuyPoints: 965,
                SalePoints: 1845,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1208.329999999998,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 0.29,
                StartDate: "2022.03.08 16:39",
                EndDate: "2022.03.08 16:53",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 16:40",
                BuyPoints: 852,
                SalePoints: 1332,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1219.069999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.3,
                StartDate: "2022.03.08 16:53",
                EndDate: "2022.03.08 17:07",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:05",
                BuyPoints: 857,
                SalePoints: 1337,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1219.359999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.29,
                StartDate: "2022.03.08 17:07",
                EndDate: "2022.03.08 17:10",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 17:08",
                BuyPoints: 1324,
                SalePoints: 804,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1219.659999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.82,
                StartDate: "2022.03.08 17:10",
                EndDate: "2022.03.08 17:26",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:10",
                BuyPoints: 850,
                SalePoints: 1330,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1220.949999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.08 17:26",
                EndDate: "2022.03.08 17:46",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:32",
                BuyPoints: 1035,
                SalePoints: 1515,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1221.769999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.08 17:46",
                EndDate: "2022.03.08 17:49",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:46",
                BuyPoints: 1261,
                SalePoints: 781,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1222.429999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.31,
                StartDate: "2022.03.08 17:49",
                EndDate: "2022.03.08 17:58",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:50",
                BuyPoints: 1341,
                SalePoints: 861,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1223.179999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.73,
                StartDate: "2022.03.08 17:58",
                EndDate: "2022.03.08 18:07",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:58",
                BuyPoints: 759,
                SalePoints: 1239,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1223.489999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.93,
                StartDate: "2022.03.08 18:07",
                EndDate: "2022.03.08 18:57",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 18:55",
                BuyPoints: 872,
                SalePoints: 1472,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1224.219999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 1.03,
                StartDate: "2022.03.08 18:57",
                EndDate: "2022.03.08 19:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 18:57",
                BuyPoints: 1536,
                SalePoints: 1056,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1226.149999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.84,
                StartDate: "2022.03.08 19:11",
                EndDate: "2022.03.08 19:14",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:12",
                BuyPoints: 1606,
                SalePoints: 1126,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1227.179999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 4.17,
                StartDate: "2022.03.08 19:14",
                EndDate: "2022.03.08 19:33",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 19:28",
                BuyPoints: 1696,
                SalePoints: 1096,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1228.019999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.78,
                StartDate: "2022.03.08 19:33",
                EndDate: "2022.03.08 19:41",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:33",
                BuyPoints: 811,
                SalePoints: 1291,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1232.189999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.9399999999999999,
                StartDate: "2022.03.08 19:41",
                EndDate: "2022.03.08 19:47",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:41",
                BuyPoints: 1450,
                SalePoints: 970,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1232.969999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.08 19:47",
                EndDate: "2022.03.08 20:23",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:47",
                BuyPoints: 829,
                SalePoints: 1309,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1233.909999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.76,
                StartDate: "2022.03.08 20:23",
                EndDate: "2022.03.08 20:31",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:24",
                BuyPoints: 1084,
                SalePoints: 1564,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1234.709999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.79,
                StartDate: "2022.03.08 20:31",
                EndDate: "2022.03.08 21:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:31",
                BuyPoints: 1299,
                SalePoints: 819,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1235.469999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.23,
                StartDate: "2022.03.08 21:06",
                EndDate: "2022.03.08 22:32",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 22:12",
                BuyPoints: 792,
                SalePoints: 1312,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1236.259999999997,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 1.4,
                StartDate: "2022.03.08 22:32",
                EndDate: "2022.03.09 02:30",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 01:19",
                BuyPoints: 1346,
                SalePoints: 826,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1237.489999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 1.61,
                StartDate: "2022.03.09 02:30",
                EndDate: "2022.03.09 04:30",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 02:39",
                BuyPoints: 867,
                SalePoints: 1387,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1238.889999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 1.97,
                StartDate: "2022.03.09 04:30",
                EndDate: "2022.03.09 09:32",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 07:51",
                BuyPoints: 1459,
                SalePoints: 939,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1240.499999999997,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.82,
                StartDate: "2022.03.09 09:32",
                EndDate: "2022.03.09 10:40",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 09:32",
                BuyPoints: 848,
                SalePoints: 1328,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1242.469999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.16,
                StartDate: "2022.03.09 10:40",
                EndDate: "2022.03.09 11:04",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 10:40",
                BuyPoints: 1670,
                SalePoints: 1190,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1243.289999999997,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.41,
                StartDate: "2022.03.09 11:04",
                EndDate: "2022.03.09 11:43",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 11:18",
                BuyPoints: 1420,
                SalePoints: 820,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1244.449999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.15,
                StartDate: "2022.03.09 11:43",
                EndDate: "2022.03.09 12:06",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 11:51",
                BuyPoints: 1261,
                SalePoints: 781,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1245.859999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.27,
                StartDate: "2022.03.09 12:06",
                EndDate: "2022.03.09 12:33",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 12:11",
                BuyPoints: 799,
                SalePoints: 1319,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1246.009999999997,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.09 12:33",
                EndDate: "2022.03.09 13:09",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 12:33",
                BuyPoints: 1376,
                SalePoints: 896,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1247.279999999997,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.09 13:09",
                EndDate: "2022.03.09 14:23",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 13:09",
                BuyPoints: 776,
                SalePoints: 1256,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1248.149999999997,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.81,
                StartDate: "2022.03.09 14:23",
                EndDate: "2022.03.09 15:01",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 14:52",
                BuyPoints: 1628,
                SalePoints: 1108,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1248.899999999997,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 2.01,
                StartDate: "2022.03.09 15:01",
                EndDate: "2022.03.09 15:26",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 15:20",
                BuyPoints: 1480,
                SalePoints: 880,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1251.709999999997,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 2.03,
                StartDate: "2022.03.09 15:26",
                EndDate: "2022.03.09 16:09",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 16:06",
                BuyPoints: 1482,
                SalePoints: 882,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1253.719999999997,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.09 16:09",
                EndDate: "2022.03.09 16:13",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:09",
                BuyPoints: 781,
                SalePoints: 1261,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1255.749999999997,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 10.68,
                StartDate: "2022.03.09 16:13",
                EndDate: "2022.03.09 18:13",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.09 16:52",
                BuyPoints: 963,
                SalePoints: 1843,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1256.499999999997,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.17,
            },
            {
                Profit: 0.96,
                StartDate: "2022.03.09 18:13",
                EndDate: "2022.03.09 19:22",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 18:13",
                BuyPoints: 1466,
                SalePoints: 986,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1267.179999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 16.28,
                StartDate: "2022.03.09 19:22",
                EndDate: "2022.03.10 03:05",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.10 02:28",
                BuyPoints: 1977,
                SalePoints: 1097,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1268.139999999998,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 0.88,
                StartDate: "2022.03.10 03:05",
                EndDate: "2022.03.10 07:52",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 03:05",
                BuyPoints: 910,
                SalePoints: 1390,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1284.419999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 10.28,
                StartDate: "2022.03.10 07:52",
                EndDate: "2022.03.10 12:13",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.10 12:08",
                BuyPoints: 954,
                SalePoints: 1834,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1285.299999999998,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 21.77,
                StartDate: "2022.03.10 12:13",
                EndDate: "2022.03.10 17:17",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 16:58",
                BuyPoints: 2076,
                SalePoints: 996,
                MaxRisk: -30.56999999999994,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1295.579999999998,
                SafeAreayPoint: 80,
                Aversion: 100,
                LostTreads: -74.59999999999999,
            },
            {
                Profit: 1.42,
                StartDate: "2022.03.10 17:17",
                EndDate: "2022.03.10 17:27",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 17:19",
                BuyPoints: 830,
                SalePoints: 1350,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1317.349999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 13.19,
                StartDate: "2022.03.10 17:27",
                EndDate: "2022.03.11 07:35",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.11 02:50",
                BuyPoints: 1975,
                SalePoints: 895,
                MaxRisk: -30.61000000000013,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1318.769999999998,
                SafeAreayPoint: 80,
                Aversion: 100,
                LostTreads: -74.59999999999999,
            },
            {
                Profit: 0.83,
                StartDate: "2022.03.11 07:35",
                EndDate: "2022.03.11 10:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 07:35",
                BuyPoints: 859,
                SalePoints: 1339,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1331.959999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.95,
                StartDate: "2022.03.11 10:00",
                EndDate: "2022.03.11 13:24",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 10:00",
                BuyPoints: 1458,
                SalePoints: 978,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1332.789999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 7.55,
                StartDate: "2022.03.11 13:24",
                EndDate: "2022.03.11 14:15",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.11 14:05",
                BuyPoints: 1769,
                SalePoints: 889,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1333.739999999999,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.11 14:15",
                EndDate: "2022.03.11 15:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 14:15",
                BuyPoints: 901,
                SalePoints: 1381,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1341.289999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.98,
                StartDate: "2022.03.11 15:00",
                EndDate: "2022.03.11 15:36",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 15:00",
                BuyPoints: 1493,
                SalePoints: 1013,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1342.159999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.85,
                StartDate: "2022.03.11 15:36",
                EndDate: "2022.03.11 16:40",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 16:03",
                BuyPoints: 1116,
                SalePoints: 1636,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1343.139999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 5.64,
                StartDate: "2022.03.11 16:40",
                EndDate: "2022.03.14 11:05",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.14 01:27",
                BuyPoints: 1638,
                SalePoints: 918,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1345.989999999999,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 5.7,
                StartDate: "2022.03.14 11:05",
                EndDate: "2022.03.14 15:55",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.14 15:13",
                BuyPoints: 1725,
                SalePoints: 845,
                MaxRisk: -13.15000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1351.629999999999,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 0.91,
                StartDate: "2022.03.14 15:55",
                EndDate: "2022.03.14 16:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 15:55",
                BuyPoints: 935,
                SalePoints: 1415,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1357.329999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.76,
                StartDate: "2022.03.14 16:00",
                EndDate: "2022.03.14 21:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 16:00",
                BuyPoints: 1267,
                SalePoints: 787,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1358.239999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.58,
                StartDate: "2022.03.14 21:00",
                EndDate: "2022.03.15 07:00",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.15 02:53",
                BuyPoints: 1637,
                SalePoints: 1037,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1358.999999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.27,
                StartDate: "2022.03.15 07:00",
                EndDate: "2022.03.15 14:00",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 08:19",
                BuyPoints: 1319,
                SalePoints: 839,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1362.579999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 2.13,
                StartDate: "2022.03.15 14:00",
                EndDate: "2022.03.15 17:33",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.15 17:05",
                BuyPoints: 1492,
                SalePoints: 892,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1362.849999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.8100000000000001,
                StartDate: "2022.03.15 17:33",
                EndDate: "2022.03.15 18:18",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 17:33",
                BuyPoints: 841,
                SalePoints: 1321,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1364.979999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.14,
                StartDate: "2022.03.15 18:18",
                EndDate: "2022.03.15 19:52",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 18:54",
                BuyPoints: 777,
                SalePoints: 1257,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1365.789999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.84,
                StartDate: "2022.03.15 19:52",
                EndDate: "2022.03.15 21:47",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 19:52",
                BuyPoints: 1354,
                SalePoints: 874,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1365.929999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 9.98,
                StartDate: "2022.03.15 21:47",
                EndDate: "2022.03.16 17:15",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.16 17:11",
                BuyPoints: 1827,
                SalePoints: 947,
                MaxRisk: -13.13000000000011,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1366.769999999999,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 0.9,
                StartDate: "2022.03.16 17:15",
                EndDate: "2022.03.16 17:41",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:15",
                BuyPoints: 927,
                SalePoints: 1407,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1376.749999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.88,
                StartDate: "2022.03.16 17:41",
                EndDate: "2022.03.16 21:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:41",
                BuyPoints: 1389,
                SalePoints: 909,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1377.649999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.16 21:00",
                EndDate: "2022.03.16 21:01",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 21:00",
                BuyPoints: 827,
                SalePoints: 1307,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1378.529999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.28,
                StartDate: "2022.03.16 21:01",
                EndDate: "2022.03.16 21:17",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 21:01",
                BuyPoints: 1788,
                SalePoints: 1308,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1379.329999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.88,
                StartDate: "2022.03.16 21:17",
                EndDate: "2022.03.16 21:45",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.16 21:21",
                BuyPoints: 923,
                SalePoints: 1443,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1380.609999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.33,
                StartDate: "2022.03.16 21:45",
                EndDate: "2022.03.16 22:19",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 21:47",
                BuyPoints: 872,
                SalePoints: 1352,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1382.489999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.82,
                StartDate: "2022.03.16 22:19",
                EndDate: "2022.03.17 06:22",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.17 01:01",
                BuyPoints: 861,
                SalePoints: 1461,
                MaxRisk: -2.519999999999982,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1382.819999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 35.88,
                StartDate: "2022.03.17 06:22",
                EndDate: "2022.03.18 16:24",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.18 05:19",
                BuyPoints: 2242,
                SalePoints: 1162,
                MaxRisk: -30.61000000000013,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1384.639999999999,
                SafeAreayPoint: 80,
                Aversion: 100,
                LostTreads: -74.59999999999999,
            },
            {
                Profit: 1.16,
                StartDate: "2022.03.18 16:24",
                EndDate: "2022.03.18 16:33",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.18 16:24",
                BuyPoints: 1192,
                SalePoints: 1672,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1420.519999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.9,
                StartDate: "2022.03.18 16:33",
                EndDate: "2022.03.18 21:52",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.18 16:33",
                BuyPoints: 1414,
                SalePoints: 934,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1421.679999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 11.31,
                StartDate: "2022.03.18 21:52",
                EndDate: "2022.03.21 17:34",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.21 16:40",
                BuyPoints: 873,
                SalePoints: 1953,
                MaxRisk: -30.56999999999994,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1422.579999999999,
                SafeAreayPoint: 80,
                Aversion: 100,
                LostTreads: -74.59999999999999,
            },
            {
                Profit: 0.77,
                StartDate: "2022.03.21 17:34",
                EndDate: "2022.03.21 20:16",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 17:34",
                BuyPoints: 1279,
                SalePoints: 799,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1433.889999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.09,
                StartDate: "2022.03.21 20:16",
                EndDate: "2022.03.21 21:57",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 20:16",
                BuyPoints: 1123,
                SalePoints: 1603,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1434.659999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.21 21:57",
                EndDate: "2022.03.22 03:39",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 21:57",
                BuyPoints: 1255,
                SalePoints: 775,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1435.749999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.84,
                StartDate: "2022.03.22 03:39",
                EndDate: "2022.03.22 17:04",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.22 11:11",
                BuyPoints: 1603,
                SalePoints: 1123,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1436.499999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 5.07,
                StartDate: "2022.03.22 17:04",
                EndDate: "2022.03.23 12:05",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.22 18:42",
                BuyPoints: 891,
                SalePoints: 1611,
                MaxRisk: -5.589999999999918,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1437.339999999999,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 4.61,
                StartDate: "2022.03.23 12:05",
                EndDate: "2022.03.23 22:27",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.23 19:24",
                BuyPoints: 819,
                SalePoints: 1699,
                MaxRisk: -13.13000000000011,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1442.409999999999,
                SafeAreayPoint: 90,
                Aversion: 80,
                LostTreads: -34.19,
            },
            {
                Profit: 1.27,
                StartDate: "2022.03.23 22:27",
                EndDate: "2022.03.24 14:49",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.24 13:22",
                BuyPoints: 806,
                SalePoints: 1406,
                MaxRisk: -2.600000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1447.019999999999,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.8100000000000001,
                StartDate: "2022.03.24 14:49",
                EndDate: "2022.03.24 15:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.24 14:49",
                BuyPoints: 1315,
                SalePoints: 835,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1448.289999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.92,
                StartDate: "2022.03.24 15:25",
                EndDate: "2022.03.24 16:35",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.24 15:25",
                BuyPoints: 948,
                SalePoints: 1428,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1449.099999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.96,
                StartDate: "2022.03.24 16:35",
                EndDate: "2022.03.25 16:34",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.25 09:41",
                BuyPoints: 1558,
                SalePoints: 838,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1450.019999999999,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.25 16:34",
                EndDate: "2022.03.25 18:07",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.25 16:34",
                BuyPoints: 834,
                SalePoints: 1314,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1453.979999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.42,
                StartDate: "2022.03.25 18:07",
                EndDate: "2022.03.28 05:00",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.25 19:34",
                BuyPoints: 1350,
                SalePoints: 830,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1454.779999999999,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.27,
                StartDate: "2022.03.28 05:00",
                EndDate: "2022.03.28 10:30",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 08:01",
                BuyPoints: 1318,
                SalePoints: 838,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1456.199999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 5.16,
                StartDate: "2022.03.28 10:30",
                EndDate: "2022.03.28 15:20",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.28 14:19",
                BuyPoints: 895,
                SalePoints: 1615,
                MaxRisk: -5.589999999999918,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1456.469999999999,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.28 15:20",
                EndDate: "2022.03.28 16:21",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 15:20",
                BuyPoints: 1377,
                SalePoints: 897,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1461.629999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.2,
                StartDate: "2022.03.28 16:21",
                EndDate: "2022.03.28 22:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 21:52",
                BuyPoints: 1287,
                SalePoints: 807,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1462.499999999999,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 0.68,
                StartDate: "2022.03.28 22:33",
                EndDate: "2022.03.29 14:47",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 11:29",
                BuyPoints: 1523,
                SalePoints: 1043,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1462.699999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.95,
                StartDate: "2022.03.29 14:47",
                EndDate: "2022.03.29 15:34",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 15:20",
                BuyPoints: 1474,
                SalePoints: 874,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1463.379999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.86,
                StartDate: "2022.03.29 15:34",
                EndDate: "2022.03.29 16:49",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 15:34",
                BuyPoints: 889,
                SalePoints: 1369,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1465.329999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.03,
                StartDate: "2022.03.29 16:49",
                EndDate: "2022.03.29 17:12",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 16:52",
                BuyPoints: 720,
                SalePoints: 1200,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1466.189999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.25,
                StartDate: "2022.03.29 17:12",
                EndDate: "2022.03.30 07:51",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 20:37",
                BuyPoints: 804,
                SalePoints: 1404,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1466.219999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.74,
                StartDate: "2022.03.30 07:51",
                EndDate: "2022.03.30 12:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 07:51",
                BuyPoints: 1253,
                SalePoints: 773,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1467.469999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.26,
                StartDate: "2022.03.30 12:31",
                EndDate: "2022.03.30 15:16",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 12:31",
                BuyPoints: 1286,
                SalePoints: 1766,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1468.209999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.97,
                StartDate: "2022.03.30 15:16",
                EndDate: "2022.03.31 07:28",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.30 20:50",
                BuyPoints: 1460,
                SalePoints: 940,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1469.469999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 0.76,
                StartDate: "2022.03.31 07:28",
                EndDate: "2022.03.31 10:31",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 07:28",
                BuyPoints: 794,
                SalePoints: 1274,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1471.439999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.46,
                StartDate: "2022.03.31 10:31",
                EndDate: "2022.03.31 18:52",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 15:26",
                BuyPoints: 933,
                SalePoints: 1413,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1472.199999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 2.23,
                StartDate: "2022.03.31 18:52",
                EndDate: "2022.04.01 03:36",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.31 21:09",
                BuyPoints: 1512,
                SalePoints: 992,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1472.659999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 1.48,
                StartDate: "2022.04.01 03:36",
                EndDate: "2022.04.01 17:33",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.01 15:53",
                BuyPoints: 1427,
                SalePoints: 827,
                MaxRisk: -2.600000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1474.889999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 0.77,
                StartDate: "2022.04.01 17:33",
                EndDate: "2022.04.01 18:19",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 17:33",
                BuyPoints: 802,
                SalePoints: 1282,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1476.369999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.84,
                StartDate: "2022.04.01 18:19",
                EndDate: "2022.04.01 20:34",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 18:19",
                BuyPoints: 1350,
                SalePoints: 870,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1477.139999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.8,
                StartDate: "2022.04.01 20:34",
                EndDate: "2022.04.04 10:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 20:34",
                BuyPoints: 826,
                SalePoints: 1306,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1477.979999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: -76.01000000000001,
                StartDate: "2022.04.04 10:00",
                EndDate: "2022.04.11 01:02",
                EndLot: 5.12,
                LastTread: "Buy",
                BuyLot: 6.82,
                SaleLot: 3.41,
                LotDifference: 3.41,
                LastLotSartedDate: "2022.04.07 17:48",
                BuyPoints: 880,
                SalePoints: 2800,
                MaxRisk: -433.01,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1478.779999999998,
                SafeAreayPoint: 50,
                Aversion: 160,
                LostTreads: -838.09,
            },
            {
                Profit: 0.37,
                StartDate: "2022.04.11 01:02",
                EndDate: "2022.04.11 15:08",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 11:20",
                BuyPoints: 888,
                SalePoints: 1368,
                MaxRisk: -0.5099999999999909,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1402.769999999998,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: -1.41,
            },
            {
                Profit: 1.12,
                StartDate: "2022.04.11 15:08",
                EndDate: "2022.04.11 16:52",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.11 16:00",
                BuyPoints: 1289,
                SalePoints: 769,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1403.139999999998,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 10.49,
                StartDate: "2022.04.11 16:52",
                EndDate: "2022.04.12 15:33",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.11 22:59",
                BuyPoints: 1149,
                SalePoints: 1869,
                MaxRisk: -5.589999999999918,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1404.259999999998,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.17,
            },
            {
                Profit: 44.62,
                StartDate: "2022.04.12 15:33",
                EndDate: "2022.04.18 02:52",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.14 20:02",
                BuyPoints: 1074,
                SalePoints: 2394,
                MaxRisk: -74.00999999999999,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1414.749999999997,
                SafeAreayPoint: 70,
                Aversion: 120,
                LostTreads: -166.96,
            },
            {
                Profit: 1.71,
                StartDate: "2022.04.18 02:52",
                EndDate: "2022.04.18 21:06",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.18 17:37",
                BuyPoints: 1408,
                SalePoints: 888,
                MaxRisk: -1.049999999999955,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1459.369999999997,
                SafeAreayPoint: 120,
                Aversion: 20,
                LostTreads: -2.8,
            },
            {
                Profit: 1.35,
                StartDate: "2022.04.18 21:06",
                EndDate: "2022.04.19 16:01",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.19 15:43",
                BuyPoints: 1414,
                SalePoints: 814,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1461.079999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 6.09,
                StartDate: "2022.04.19 16:01",
                EndDate: "2022.04.19 22:20",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.19 17:15",
                BuyPoints: 1888,
                SalePoints: 1288,
                MaxRisk: -2.610000000000127,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1462.429999999998,
                SafeAreayPoint: 110,
                Aversion: 40,
                LostTreads: -7.23,
            },
            {
                Profit: 7.75,
                StartDate: "2022.04.19 22:20",
                EndDate: "2022.04.20 23:02",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.20 11:50",
                BuyPoints: 1019,
                SalePoints: 1739,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1468.519999999997,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: -15.18,
            },
            {
                Profit: 0.9,
                StartDate: "2022.04.20 23:02",
                EndDate: "2022.04.21 11:15",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.20 23:02",
                BuyPoints: 1411,
                SalePoints: 931,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1476.269999999997,
                SafeAreayPoint: 130,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: -1,
                StartDate: "2022.04.21 11:15",
                EndDate: "2022.04.22 07:43",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.21 20:33",
                BuyPoints: 330,
                SalePoints: 1050,
                MaxRisk: -5.579999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1477.169999999997,
                SafeAreayPoint: 100,
                Aversion: 60,
                LostTreads: 0,
            },
        ],

        End: {
            Balance: 1476.199999999998,
            MaxmimLotsRiched: 5.12,
            Deals: 186,
            Eqity: 1476.169999999998,
            Profit: -0.03,
            Ask: 1952.51,
            StartPoint: 0,
            AllLost: 2,
            AllLostProfit: -77.01000000000001,
            AllWin: 183,
            AllWinProfit: 553.2099999999998,
            EndDate: "2022.04.22 07:43",
            Spread: 30,
        },
        Reason: 0,
    },

    {
        Name: "_3",
        Start: { StartDate: "2022.02.10 02:00", Balance: 1000, Gap: 333, _GoalLine: 777, SafeAreayPoint: 133, Aversion: 0, Bomo: 9, Spread: 30 },
        Treads: [
            {},
            {
                Profit: 4.53,
                StartDate: "2022.02.10 02:00",
                EndDate: "2022.02.10 19:03",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.10 16:45",
                BuyPoints: 757,
                SalePoints: 1228,
                MaxRisk: -4.149999999999977,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 999.97,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.03,
            },
            {
                Profit: 0.71,
                StartDate: "2022.02.10 19:03",
                EndDate: "2022.02.10 19:52",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 19:03",
                BuyPoints: 1100,
                SalePoints: 737,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1004.5,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.14,
                StartDate: "2022.02.10 19:52",
                EndDate: "2022.02.11 09:11",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 21:50",
                BuyPoints: 1016,
                SalePoints: 653,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1005.21,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.65,
                StartDate: "2022.02.11 09:11",
                EndDate: "2022.02.11 14:32",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 09:11",
                BuyPoints: 680,
                SalePoints: 1043,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1005.35,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.01,
                StartDate: "2022.02.11 14:32",
                EndDate: "2022.02.11 20:32",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.11 17:15",
                BuyPoints: 968,
                SalePoints: 1385,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1006,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.9399999999999999,
                StartDate: "2022.02.11 20:32",
                EndDate: "2022.02.11 20:46",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 20:35",
                BuyPoints: 1062,
                SalePoints: 1425,
                MaxRisk: -0.3899999999999864,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1010.01,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.18,
            },
            {
                Profit: 21.01,
                StartDate: "2022.02.11 20:46",
                EndDate: "2022.02.14 20:55",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.02.14 15:49",
                BuyPoints: 748,
                SalePoints: 1489,
                MaxRisk: -47.20000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1010.95,
                SafeAreayPoint: 109,
                Aversion: 54,
                LostTreads: -119.2,
            },
            {
                Profit: 13.25,
                StartDate: "2022.02.14 20:55",
                EndDate: "2022.02.15 10:45",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.15 10:09",
                BuyPoints: 1642,
                SalePoints: 1171,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1031.96,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 2.26,
                StartDate: "2022.02.15 10:45",
                EndDate: "2022.02.15 14:22",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.15 12:06",
                BuyPoints: 1210,
                SalePoints: 793,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1045.21,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.65,
                StartDate: "2022.02.15 14:22",
                EndDate: "2022.02.15 16:09",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.15 14:22",
                BuyPoints: 677,
                SalePoints: 1040,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1047.47,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.96,
                StartDate: "2022.02.15 16:09",
                EndDate: "2022.02.16 18:38",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.16 15:48",
                BuyPoints: 673,
                SalePoints: 1216,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1048.12,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.14,
                StartDate: "2022.02.16 18:38",
                EndDate: "2022.02.17 06:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.16 19:25",
                BuyPoints: 654,
                SalePoints: 1017,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1052.08,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.12,
                StartDate: "2022.02.17 06:17",
                EndDate: "2022.02.17 10:27",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.17 10:26",
                BuyPoints: 645,
                SalePoints: 1008,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1052.22,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 9.789999999999999,
                StartDate: "2022.02.17 10:27",
                EndDate: "2022.02.17 18:46",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.02.17 15:05",
                BuyPoints: 682,
                SalePoints: 1423,
                MaxRisk: -47.20000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1052.339999999999,
                SafeAreayPoint: 109,
                Aversion: 54,
                LostTreads: -119.2,
            },
            {
                Profit: 20.42,
                StartDate: "2022.02.17 18:46",
                EndDate: "2022.02.18 12:31",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.18 03:33",
                BuyPoints: 1463,
                SalePoints: 830,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1062.13,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.02.18 12:31",
                EndDate: "2022.02.18 15:10",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.18 12:31",
                BuyPoints: 715,
                SalePoints: 1078,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1082.55,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.57,
                StartDate: "2022.02.18 15:10",
                EndDate: "2022.02.21 02:37",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.18 23:20",
                BuyPoints: 824,
                SalePoints: 1241,
                MaxRisk: -1.980000000000018,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1083.24,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.91,
                StartDate: "2022.02.21 02:37",
                EndDate: "2022.02.21 02:55",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.21 02:37",
                BuyPoints: 1300,
                SalePoints: 937,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1085.81,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 18.13,
                StartDate: "2022.02.21 02:55",
                EndDate: "2022.02.22 01:01",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.21 20:00",
                BuyPoints: 939,
                SalePoints: 1572,
                MaxRisk: -20.63000000000011,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1086.72,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -66.94,
            },
            {
                Profit: 35.97,
                StartDate: "2022.02.22 01:01",
                EndDate: "2022.02.22 11:00",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.22 09:57",
                BuyPoints: 1646,
                SalePoints: 1013,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1104.85,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 4.01,
                StartDate: "2022.02.22 11:00",
                EndDate: "2022.02.22 15:34",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.22 14:22",
                BuyPoints: 731,
                SalePoints: 1202,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1140.82,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.63,
                StartDate: "2022.02.22 15:34",
                EndDate: "2022.02.22 17:23",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.22 15:34",
                BuyPoints: 1021,
                SalePoints: 658,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1144.83,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 8.380000000000001,
                StartDate: "2022.02.22 17:23",
                EndDate: "2022.02.23 17:06",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.23 15:20",
                BuyPoints: 688,
                SalePoints: 1321,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1145.46,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.35,
            },
            {
                Profit: 3.67,
                StartDate: "2022.02.23 17:06",
                EndDate: "2022.02.24 04:52",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.23 22:55",
                BuyPoints: 934,
                SalePoints: 1351,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1153.84,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: -0.05,
                StartDate: "2022.02.24 04:52",
                EndDate: "2022.02.24 05:21",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 05:01",
                BuyPoints: 558,
                SalePoints: 921,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1157.51,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.99,
                StartDate: "2022.02.24 05:21",
                EndDate: "2022.02.24 06:12",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:08",
                BuyPoints: 1081,
                SalePoints: 1444,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1157.46,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.77,
                StartDate: "2022.02.24 06:12",
                EndDate: "2022.02.24 06:50",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.24 06:23",
                BuyPoints: 1193,
                SalePoints: 812,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1158.45,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.67,
                StartDate: "2022.02.24 06:50",
                EndDate: "2022.02.24 07:33",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:50",
                BuyPoints: 700,
                SalePoints: 1063,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1160.22,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 13.78,
                StartDate: "2022.02.24 07:33",
                EndDate: "2022.02.24 12:05",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.24 11:24",
                BuyPoints: 907,
                SalePoints: 1450,
                MaxRisk: -9.329999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1160.89,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.44,
                StartDate: "2022.02.24 12:05",
                EndDate: "2022.02.24 12:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 12:13",
                BuyPoints: 804,
                SalePoints: 1167,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1174.67,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 15.23,
                StartDate: "2022.02.24 12:17",
                EndDate: "2022.02.24 14:22",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.24 13:52",
                BuyPoints: 1402,
                SalePoints: 769,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1175.11,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 3.18,
                StartDate: "2022.02.24 14:22",
                EndDate: "2022.02.24 15:03",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.24 14:34",
                BuyPoints: 692,
                SalePoints: 1163,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1190.34,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.76,
                StartDate: "2022.02.24 15:03",
                EndDate: "2022.02.24 15:48",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 15:03",
                BuyPoints: 1153,
                SalePoints: 790,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1193.52,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.97,
                StartDate: "2022.02.24 15:48",
                EndDate: "2022.02.24 16:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 16:17",
                BuyPoints: 1435,
                SalePoints: 1072,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1194.28,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.42,
                StartDate: "2022.02.24 16:33",
                EndDate: "2022.02.24 16:54",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 16:38",
                BuyPoints: 1160,
                SalePoints: 797,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1195.25,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.71,
                StartDate: "2022.02.24 16:54",
                EndDate: "2022.02.24 17:08",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 16:54",
                BuyPoints: 737,
                SalePoints: 1100,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1195.669999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.74,
                StartDate: "2022.02.24 17:08",
                EndDate: "2022.02.24 17:16",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:08",
                BuyPoints: 1132,
                SalePoints: 769,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1196.379999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.3,
                StartDate: "2022.02.24 17:16",
                EndDate: "2022.02.24 17:18",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:17",
                BuyPoints: 1097,
                SalePoints: 734,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1197.119999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.1,
                StartDate: "2022.02.24 17:18",
                EndDate: "2022.02.24 17:44",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.24 17:22",
                BuyPoints: 677,
                SalePoints: 1058,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1197.419999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.92,
                StartDate: "2022.02.24 17:44",
                EndDate: "2022.02.24 19:07",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:44",
                BuyPoints: 1315,
                SalePoints: 952,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1198.52,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.73,
                StartDate: "2022.02.24 19:07",
                EndDate: "2022.02.24 20:32",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 19:07",
                BuyPoints: 762,
                SalePoints: 1125,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1199.44,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.63,
                StartDate: "2022.02.24 20:32",
                EndDate: "2022.02.24 20:51",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 20:32",
                BuyPoints: 1023,
                SalePoints: 660,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1200.17,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.28,
                StartDate: "2022.02.24 20:51",
                EndDate: "2022.02.24 21:09",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:07",
                BuyPoints: 1086,
                SalePoints: 723,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1200.8,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.57,
                StartDate: "2022.02.24 21:09",
                EndDate: "2022.02.24 21:20",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:14",
                BuyPoints: 1231,
                SalePoints: 868,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1201.08,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.25,
                StartDate: "2022.02.24 21:20",
                EndDate: "2022.02.24 21:35",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:27",
                BuyPoints: 1073,
                SalePoints: 710,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1201.65,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 7.48,
                StartDate: "2022.02.24 21:35",
                EndDate: "2022.02.24 22:29",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.24 22:14",
                BuyPoints: 897,
                SalePoints: 1368,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1201.9,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.06,
                StartDate: "2022.02.24 22:29",
                EndDate: "2022.02.24 23:28",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 22:59",
                BuyPoints: 615,
                SalePoints: 978,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1209.379999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 2.85,
                StartDate: "2022.02.24 23:28",
                EndDate: "2022.02.25 03:02",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.25 02:24",
                BuyPoints: 852,
                SalePoints: 1269,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1209.439999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 2.3,
                StartDate: "2022.02.25 03:02",
                EndDate: "2022.02.25 03:40",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 03:08",
                BuyPoints: 1298,
                SalePoints: 917,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1212.289999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.7,
                StartDate: "2022.02.25 03:40",
                EndDate: "2022.02.25 05:20",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 03:40",
                BuyPoints: 731,
                SalePoints: 1094,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1214.589999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.72,
                StartDate: "2022.02.25 05:20",
                EndDate: "2022.02.25 13:24",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 09:44",
                BuyPoints: 1181,
                SalePoints: 800,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1215.29,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.12,
                StartDate: "2022.02.25 13:24",
                EndDate: "2022.02.25 13:42",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 13:28",
                BuyPoints: 1006,
                SalePoints: 643,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1217.01,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.09,
                StartDate: "2022.02.25 13:42",
                EndDate: "2022.02.25 14:25",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 13:46",
                BuyPoints: 675,
                SalePoints: 1056,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1217.129999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1.17,
                StartDate: "2022.02.25 14:25",
                EndDate: "2022.02.25 14:57",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 14:42",
                BuyPoints: 1072,
                SalePoints: 691,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1218.22,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 9.210000000000001,
                StartDate: "2022.02.25 14:57",
                EndDate: "2022.02.25 17:08",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.25 15:12",
                BuyPoints: 1341,
                SalePoints: 798,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1219.39,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.72,
                StartDate: "2022.02.25 17:08",
                EndDate: "2022.02.25 22:38",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 17:08",
                BuyPoints: 753,
                SalePoints: 1116,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1228.6,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.48,
                StartDate: "2022.02.25 22:38",
                EndDate: "2022.02.28 12:35",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 09:54",
                BuyPoints: 823,
                SalePoints: 1186,
                MaxRisk: -0.03999999999996362,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1229.32,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.36,
                StartDate: "2022.02.28 12:35",
                EndDate: "2022.02.28 15:45",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 12:54",
                BuyPoints: 767,
                SalePoints: 1130,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1229.8,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.15,
                StartDate: "2022.02.28 15:45",
                EndDate: "2022.02.28 17:48",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.28 16:06",
                BuyPoints: 1067,
                SalePoints: 686,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1230.16,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.25,
                StartDate: "2022.02.28 17:48",
                EndDate: "2022.02.28 18:50",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 18:09",
                BuyPoints: 1075,
                SalePoints: 712,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1231.31,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.8,
                StartDate: "2022.02.28 18:50",
                EndDate: "2022.02.28 22:02",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.28 19:54",
                BuyPoints: 817,
                SalePoints: 1198,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1231.56,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 2.05,
                StartDate: "2022.02.28 22:02",
                EndDate: "2022.03.01 11:42",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.01 08:54",
                BuyPoints: 773,
                SalePoints: 1190,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1233.36,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.88,
            },
            {
                Profit: 5.85,
                StartDate: "2022.03.01 11:42",
                EndDate: "2022.03.01 17:48",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.01 16:32",
                BuyPoints: 718,
                SalePoints: 1261,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1235.409999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.15,
                StartDate: "2022.03.01 17:48",
                EndDate: "2022.03.01 20:23",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 18:43",
                BuyPoints: 659,
                SalePoints: 1022,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1241.26,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 12.08,
                StartDate: "2022.03.01 20:23",
                EndDate: "2022.03.02 12:26",
                EndLot: 2.56,
                LastTread: "Sale",
                BuyLot: 1.7,
                SaleLot: 3.41,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.03.02 10:24",
                BuyPoints: 1561,
                SalePoints: 694,
                MaxRisk: -108.1700000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1241.409999999999,
                SafeAreayPoint: 105,
                Aversion: 63,
                LostTreads: -255.89,
            },
            {
                Profit: 0.98,
                StartDate: "2022.03.02 12:26",
                EndDate: "2022.03.02 12:26",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 12:26",
                BuyPoints: 1436,
                SalePoints: 1073,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1253.49,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.25,
                StartDate: "2022.03.02 12:26",
                EndDate: "2022.03.02 12:27",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 12:26",
                BuyPoints: 1275,
                SalePoints: 1638,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1254.47,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 43.46,
                StartDate: "2022.03.02 12:27",
                EndDate: "2022.03.02 18:13",
                EndLot: 2.56,
                LastTread: "Sale",
                BuyLot: 1.7,
                SaleLot: 3.41,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.03.02 17:31",
                BuyPoints: 1653,
                SalePoints: 786,
                MaxRisk: -108.1700000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1255.72,
                SafeAreayPoint: 105,
                Aversion: 63,
                LostTreads: -255.89,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.02 18:13",
                EndDate: "2022.03.02 18:29",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 18:13",
                BuyPoints: 662,
                SalePoints: 1025,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1299.18,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.02 18:29",
                EndDate: "2022.03.02 19:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 18:29",
                BuyPoints: 1144,
                SalePoints: 781,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1299.81,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.02 19:11",
                EndDate: "2022.03.02 21:07",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 19:11",
                BuyPoints: 681,
                SalePoints: 1044,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1300.56,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.27,
                StartDate: "2022.03.02 21:07",
                EndDate: "2022.03.03 10:44",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.03 07:46",
                BuyPoints: 695,
                SalePoints: 1112,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1301.21,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.86,
                StartDate: "2022.03.03 10:44",
                EndDate: "2022.03.03 11:30",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.03 10:44",
                BuyPoints: 1255,
                SalePoints: 892,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1302.48,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.9,
                StartDate: "2022.03.03 11:30",
                EndDate: "2022.03.03 12:31",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.03 11:30",
                BuyPoints: 927,
                SalePoints: 1290,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1303.34,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.95,
                StartDate: "2022.03.03 12:31",
                EndDate: "2022.03.03 15:59",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.03 13:54",
                BuyPoints: 1227,
                SalePoints: 846,
                MaxRisk: -0.8099999999999454,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1304.24,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.03 15:59",
                EndDate: "2022.03.03 20:19",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.03 15:59",
                BuyPoints: 676,
                SalePoints: 1039,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1306.19,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.97,
                StartDate: "2022.03.03 20:19",
                EndDate: "2022.03.04 02:31",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.03 22:02",
                BuyPoints: 665,
                SalePoints: 1082,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1306.84,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 2.68,
                StartDate: "2022.03.04 02:31",
                EndDate: "2022.03.04 03:52",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.04 03:03",
                BuyPoints: 1374,
                SalePoints: 993,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1307.81,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1.2,
                StartDate: "2022.03.04 03:52",
                EndDate: "2022.03.04 05:29",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.04 03:54",
                BuyPoints: 697,
                SalePoints: 1078,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1310.49,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 17.69,
                StartDate: "2022.03.04 05:29",
                EndDate: "2022.03.04 16:34",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.04 15:19",
                BuyPoints: 1000,
                SalePoints: 1543,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1311.69,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 9.949999999999999,
                StartDate: "2022.03.04 16:34",
                EndDate: "2022.03.04 17:02",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.04 16:51",
                BuyPoints: 1485,
                SalePoints: 1014,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1329.380000000001,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 1.03,
                StartDate: "2022.03.04 17:02",
                EndDate: "2022.03.04 17:15",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 17:02",
                BuyPoints: 1056,
                SalePoints: 1419,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1339.33,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.25,
                StartDate: "2022.03.04 17:15",
                EndDate: "2022.03.04 20:51",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 17:46",
                BuyPoints: 712,
                SalePoints: 1075,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1340.36,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 3.16,
                StartDate: "2022.03.04 20:51",
                EndDate: "2022.03.07 01:02",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.04 22:12",
                BuyPoints: 883,
                SalePoints: 1300,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1340.61,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.07 01:02",
                EndDate: "2022.03.07 01:13",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:04",
                BuyPoints: 870,
                SalePoints: 1233,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1343.77,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 10.4,
                StartDate: "2022.03.07 01:13",
                EndDate: "2022.03.07 08:07",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.07 04:30",
                BuyPoints: 1506,
                SalePoints: 1035,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1344.34,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.01,
            },
            {
                Profit: 0.88,
                StartDate: "2022.03.07 08:07",
                EndDate: "2022.03.07 09:42",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 08:07",
                BuyPoints: 914,
                SalePoints: 1277,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1354.74,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.44,
                StartDate: "2022.03.07 09:42",
                EndDate: "2022.03.07 11:48",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 09:53",
                BuyPoints: 806,
                SalePoints: 1169,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1355.62,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.07 11:48",
                EndDate: "2022.03.07 13:32",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 11:48",
                BuyPoints: 1147,
                SalePoints: 784,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1356.06,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 9.800000000000001,
                StartDate: "2022.03.07 13:32",
                EndDate: "2022.03.07 15:20",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.07 14:00",
                BuyPoints: 1964,
                SalePoints: 1547,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1356.81,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.23,
                StartDate: "2022.03.07 15:20",
                EndDate: "2022.03.07 15:42",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:32",
                BuyPoints: 1063,
                SalePoints: 700,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1366.61,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.03.07 15:42",
                EndDate: "2022.03.07 15:48",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:42",
                BuyPoints: 722,
                SalePoints: 1085,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1366.84,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.13,
                StartDate: "2022.03.07 15:48",
                EndDate: "2022.03.07 15:56",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:52",
                BuyPoints: 651,
                SalePoints: 1014,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1367.53,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.77,
                StartDate: "2022.03.07 15:56",
                EndDate: "2022.03.07 16:09",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:56",
                BuyPoints: 1166,
                SalePoints: 803,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1367.66,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.63,
                StartDate: "2022.03.07 16:09",
                EndDate: "2022.03.07 16:44",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.07 16:25",
                BuyPoints: 983,
                SalePoints: 1364,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1368.43,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 8.279999999999999,
                StartDate: "2022.03.07 16:44",
                EndDate: "2022.03.07 20:34",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.07 19:55",
                BuyPoints: 776,
                SalePoints: 1319,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1371.06,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 20.24,
                StartDate: "2022.03.07 20:34",
                EndDate: "2022.03.08 03:26",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.08 02:33",
                BuyPoints: 1461,
                SalePoints: 828,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1379.34,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.08 03:26",
                EndDate: "2022.03.08 07:38",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 03:26",
                BuyPoints: 736,
                SalePoints: 1099,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1399.58,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.08 07:38",
                EndDate: "2022.03.08 08:33",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 08:01",
                BuyPoints: 875,
                SalePoints: 1238,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1400.29,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.08 08:33",
                EndDate: "2022.03.08 08:57",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 08:36",
                BuyPoints: 898,
                SalePoints: 1261,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1400.87,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 2.85,
                StartDate: "2022.03.08 08:57",
                EndDate: "2022.03.08 10:15",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 09:49",
                BuyPoints: 1147,
                SalePoints: 676,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1401.5,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 4.94,
                StartDate: "2022.03.08 10:15",
                EndDate: "2022.03.08 15:46",
                EndLot: 2.56,
                LastTread: "Buy",
                BuyLot: 3.41,
                SaleLot: 1.7,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.03.08 15:11",
                BuyPoints: 673,
                SalePoints: 1540,
                MaxRisk: -108.1700000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1404.35,
                SafeAreayPoint: 105,
                Aversion: 63,
                LostTreads: -255.89,
            },
            {
                Profit: 0.5,
                StartDate: "2022.03.08 15:46",
                EndDate: "2022.03.08 16:40",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 16:38",
                BuyPoints: 833,
                SalePoints: 1196,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1409.29,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.19,
                StartDate: "2022.03.08 16:40",
                EndDate: "2022.03.08 17:01",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 16:49",
                BuyPoints: 679,
                SalePoints: 1042,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1409.79,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.28,
                StartDate: "2022.03.08 17:01",
                EndDate: "2022.03.08 17:06",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:05",
                BuyPoints: 726,
                SalePoints: 1089,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1409.98,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.18,
                StartDate: "2022.03.08 17:06",
                EndDate: "2022.03.08 17:08",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:07",
                BuyPoints: 677,
                SalePoints: 1040,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1410.26,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.86,
                StartDate: "2022.03.08 17:08",
                EndDate: "2022.03.08 17:09",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:08",
                BuyPoints: 1249,
                SalePoints: 886,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1410.44,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.15,
                StartDate: "2022.03.08 17:09",
                EndDate: "2022.03.08 17:14",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:09",
                BuyPoints: 1021,
                SalePoints: 658,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1411.299999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.68,
                StartDate: "2022.03.08 17:14",
                EndDate: "2022.03.08 17:18",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:14",
                BuyPoints: 711,
                SalePoints: 1074,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1411.449999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.19,
                StartDate: "2022.03.08 17:18",
                EndDate: "2022.03.08 17:34",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:26",
                BuyPoints: 682,
                SalePoints: 1045,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1412.129999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 3.38,
                StartDate: "2022.03.08 17:34",
                EndDate: "2022.03.08 17:52",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 17:48",
                BuyPoints: 1514,
                SalePoints: 1133,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1412.319999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.77,
                StartDate: "2022.03.08 17:52",
                EndDate: "2022.03.08 17:56",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:52",
                BuyPoints: 803,
                SalePoints: 1166,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1415.699999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.93,
                StartDate: "2022.03.08 17:56",
                EndDate: "2022.03.08 17:58",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:56",
                BuyPoints: 1324,
                SalePoints: 961,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1416.469999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.45,
                StartDate: "2022.03.08 17:58",
                EndDate: "2022.03.08 18:07",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 18:01",
                BuyPoints: 747,
                SalePoints: 1128,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1417.399999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1.89,
                StartDate: "2022.03.08 18:07",
                EndDate: "2022.03.08 18:56",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 18:55",
                BuyPoints: 756,
                SalePoints: 1173,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1418.849999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 1.62,
                StartDate: "2022.03.08 18:56",
                EndDate: "2022.03.08 19:11",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 18:58",
                BuyPoints: 1163,
                SalePoints: 782,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1420.739999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.31,
                StartDate: "2022.03.08 19:11",
                EndDate: "2022.03.08 19:13",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:12",
                BuyPoints: 1104,
                SalePoints: 741,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1422.359999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 2.37,
                StartDate: "2022.03.08 19:13",
                EndDate: "2022.03.08 19:17",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 19:16",
                BuyPoints: 1222,
                SalePoints: 805,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1422.669999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 2.37,
                StartDate: "2022.03.08 19:17",
                EndDate: "2022.03.08 19:22",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:20",
                BuyPoints: 931,
                SalePoints: 1312,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1425.039999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.08 19:22",
                EndDate: "2022.03.08 19:28",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:22",
                BuyPoints: 1002,
                SalePoints: 639,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1427.409999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.08 19:28",
                EndDate: "2022.03.08 19:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:31",
                BuyPoints: 1220,
                SalePoints: 857,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1428.019999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.62,
                StartDate: "2022.03.08 19:33",
                EndDate: "2022.03.08 19:41",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:38",
                BuyPoints: 780,
                SalePoints: 1161,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1428.559999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.9399999999999999,
                StartDate: "2022.03.08 19:41",
                EndDate: "2022.03.08 19:47",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:41",
                BuyPoints: 1331,
                SalePoints: 968,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1430.179999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.81,
                StartDate: "2022.03.08 19:47",
                EndDate: "2022.03.08 20:23",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:50",
                BuyPoints: 818,
                SalePoints: 1199,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1431.119999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1.22,
                StartDate: "2022.03.08 20:23",
                EndDate: "2022.03.08 20:31",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:24",
                BuyPoints: 1197,
                SalePoints: 1560,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1432.929999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1,
                StartDate: "2022.03.08 20:31",
                EndDate: "2022.03.08 20:54",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 20:44",
                BuyPoints: 1038,
                SalePoints: 657,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1434.149999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 7.74,
                StartDate: "2022.03.08 20:54",
                EndDate: "2022.03.08 21:45",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.08 21:23",
                BuyPoints: 1306,
                SalePoints: 763,
                MaxRisk: -9.329999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1435.149999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.08 21:45",
                EndDate: "2022.03.08 22:00",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 21:45",
                BuyPoints: 830,
                SalePoints: 1193,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1442.889999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.17,
                StartDate: "2022.03.08 22:00",
                EndDate: "2022.03.08 22:32",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 22:12",
                BuyPoints: 672,
                SalePoints: 1035,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1443.689999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.79,
                StartDate: "2022.03.08 22:32",
                EndDate: "2022.03.09 02:30",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 01:19",
                BuyPoints: 1196,
                SalePoints: 815,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1443.859999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 5.91,
                StartDate: "2022.03.09 02:30",
                EndDate: "2022.03.09 04:30",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.09 03:08",
                BuyPoints: 822,
                SalePoints: 1293,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1445.649999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 2.41,
                StartDate: "2022.03.09 04:30",
                EndDate: "2022.03.09 09:32",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 07:51",
                BuyPoints: 1320,
                SalePoints: 939,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1451.559999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.09 09:32",
                EndDate: "2022.03.09 10:06",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 09:32",
                BuyPoints: 662,
                SalePoints: 1025,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1453.969999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.98,
                StartDate: "2022.03.09 10:06",
                EndDate: "2022.03.09 11:04",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 10:06",
                BuyPoints: 1373,
                SalePoints: 1010,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1454.599999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.71,
                StartDate: "2022.03.09 11:04",
                EndDate: "2022.03.09 11:40",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 11:17",
                BuyPoints: 1255,
                SalePoints: 838,
                MaxRisk: -1.980000000000018,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1455.579999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.09 11:40",
                EndDate: "2022.03.09 12:04",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 11:51",
                BuyPoints: 1235,
                SalePoints: 872,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1458.289999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 6.17,
                StartDate: "2022.03.09 12:04",
                EndDate: "2022.03.09 14:42",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.09 13:10",
                BuyPoints: 834,
                SalePoints: 1305,
                MaxRisk: -4.150000000000091,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1458.859999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.76,
                StartDate: "2022.03.09 14:42",
                EndDate: "2022.03.09 14:57",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 14:42",
                BuyPoints: 1155,
                SalePoints: 792,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1465.029999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.16,
                StartDate: "2022.03.09 14:57",
                EndDate: "2022.03.09 15:02",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:00",
                BuyPoints: 1029,
                SalePoints: 666,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1465.789999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.21,
                StartDate: "2022.03.09 15:02",
                EndDate: "2022.03.09 15:26",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:25",
                BuyPoints: 1054,
                SalePoints: 691,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1465.949999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.76,
                StartDate: "2022.03.09 15:26",
                EndDate: "2022.03.09 16:08",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:52",
                BuyPoints: 1328,
                SalePoints: 965,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1466.159999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.09 16:08",
                EndDate: "2022.03.09 16:13",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:08",
                BuyPoints: 661,
                SalePoints: 1024,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1466.919999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.26,
                StartDate: "2022.03.09 16:13",
                EndDate: "2022.03.09 16:18",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:14",
                BuyPoints: 714,
                SalePoints: 1077,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1467.549999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.09 16:18",
                EndDate: "2022.03.09 16:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:18",
                BuyPoints: 1037,
                SalePoints: 674,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1467.809999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 20.03,
                StartDate: "2022.03.09 16:31",
                EndDate: "2022.03.09 18:09",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.09 16:52",
                BuyPoints: 825,
                SalePoints: 1458,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1468.449999999998,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.35,
            },
            {
                Profit: 2.47,
                StartDate: "2022.03.09 18:09",
                EndDate: "2022.03.09 19:22",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.09 19:10",
                BuyPoints: 1129,
                SalePoints: 658,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1488.479999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.14,
                StartDate: "2022.03.09 19:22",
                EndDate: "2022.03.09 21:08",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 19:33",
                BuyPoints: 1017,
                SalePoints: 654,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1490.949999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.72,
                StartDate: "2022.03.09 21:08",
                EndDate: "2022.03.09 21:50",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 21:08",
                BuyPoints: 752,
                SalePoints: 1115,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1491.089999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.02,
                StartDate: "2022.03.09 21:50",
                EndDate: "2022.03.10 03:00",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.10 02:02",
                BuyPoints: 1203,
                SalePoints: 732,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1491.809999999998,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 1.07,
                StartDate: "2022.03.10 03:00",
                EndDate: "2022.03.10 08:36",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 07:33",
                BuyPoints: 671,
                SalePoints: 1052,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1495.829999999998,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.10 08:36",
                EndDate: "2022.03.10 09:01",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 08:36",
                BuyPoints: 1039,
                SalePoints: 676,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1496.899999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 42.43,
                StartDate: "2022.03.10 09:01",
                EndDate: "2022.03.10 12:12",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 11:28",
                BuyPoints: 1089,
                SalePoints: 1722,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1497.549999999999,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 9.210000000000001,
                StartDate: "2022.03.10 12:12",
                EndDate: "2022.03.10 13:09",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.10 12:36",
                BuyPoints: 798,
                SalePoints: 1341,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1539.979999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 4.15,
                StartDate: "2022.03.10 13:09",
                EndDate: "2022.03.10 15:51",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 14:42",
                BuyPoints: 1667,
                SalePoints: 1286,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1549.189999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1.07,
                StartDate: "2022.03.10 15:51",
                EndDate: "2022.03.10 16:17",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 15:51",
                BuyPoints: 1097,
                SalePoints: 1460,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1553.339999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.10 16:17",
                EndDate: "2022.03.10 17:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 16:17",
                BuyPoints: 1107,
                SalePoints: 744,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1554.409999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.76,
                StartDate: "2022.03.10 17:06",
                EndDate: "2022.03.10 17:18",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 17:10",
                BuyPoints: 1330,
                SalePoints: 967,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1555.119999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.73,
                StartDate: "2022.03.10 17:18",
                EndDate: "2022.03.10 17:22",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 17:18",
                BuyPoints: 755,
                SalePoints: 1118,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1555.879999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 11.26,
                StartDate: "2022.03.10 17:22",
                EndDate: "2022.03.10 19:45",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.10 18:40",
                BuyPoints: 847,
                SalePoints: 1390,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1556.609999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.10 19:45",
                EndDate: "2022.03.10 20:19",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 19:45",
                BuyPoints: 1104,
                SalePoints: 741,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1567.869999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.06,
                StartDate: "2022.03.10 20:19",
                EndDate: "2022.03.11 07:16",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.11 05:07",
                BuyPoints: 1190,
                SalePoints: 773,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1568.579999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 1.11,
                StartDate: "2022.03.11 07:16",
                EndDate: "2022.03.11 10:00",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 07:44",
                BuyPoints: 679,
                SalePoints: 1060,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1570.639999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 2.57,
                StartDate: "2022.03.11 10:00",
                EndDate: "2022.03.11 13:24",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 11:08",
                BuyPoints: 1352,
                SalePoints: 971,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1571.749999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1.11,
                StartDate: "2022.03.11 13:24",
                EndDate: "2022.03.11 13:43",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.11 13:38",
                BuyPoints: 1095,
                SalePoints: 678,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1574.319999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.73,
                StartDate: "2022.03.11 13:43",
                EndDate: "2022.03.11 13:52",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 13:43",
                BuyPoints: 759,
                SalePoints: 1122,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1575.429999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.77,
                StartDate: "2022.03.11 13:52",
                EndDate: "2022.03.11 14:15",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 14:01",
                BuyPoints: 1592,
                SalePoints: 1211,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1576.159999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 5.79,
                StartDate: "2022.03.11 14:15",
                EndDate: "2022.03.11 15:00",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.11 14:50",
                BuyPoints: 816,
                SalePoints: 1287,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1579.929999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.99,
                StartDate: "2022.03.11 15:00",
                EndDate: "2022.03.11 15:36",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 15:00",
                BuyPoints: 1381,
                SalePoints: 1018,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1585.719999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.29,
                StartDate: "2022.03.11 15:36",
                EndDate: "2022.03.11 16:40",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 16:03",
                BuyPoints: 1115,
                SalePoints: 1496,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1586.709999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 6.27,
                StartDate: "2022.03.11 16:40",
                EndDate: "2022.03.11 18:42",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.11 17:58",
                BuyPoints: 728,
                SalePoints: 1271,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1589.999999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.79,
                StartDate: "2022.03.11 18:42",
                EndDate: "2022.03.14 01:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 18:42",
                BuyPoints: 1181,
                SalePoints: 818,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1596.269999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.23,
                StartDate: "2022.03.14 01:06",
                EndDate: "2022.03.14 10:09",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 01:26",
                BuyPoints: 1062,
                SalePoints: 699,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1597.059999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.73,
                StartDate: "2022.03.14 10:09",
                EndDate: "2022.03.14 13:24",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 11:02",
                BuyPoints: 1313,
                SalePoints: 950,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1597.289999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.67,
                StartDate: "2022.03.14 13:24",
                EndDate: "2022.03.14 13:47",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 13:24",
                BuyPoints: 704,
                SalePoints: 1067,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1598.019999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 8.300000000000001,
                StartDate: "2022.03.14 13:47",
                EndDate: "2022.03.14 15:46",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.14 14:57",
                BuyPoints: 1320,
                SalePoints: 687,
                MaxRisk: -20.63000000000011,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1598.689999999999,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.35,
            },
            {
                Profit: 6.81,
                StartDate: "2022.03.14 15:46",
                EndDate: "2022.03.15 03:28",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.14 22:56",
                BuyPoints: 1284,
                SalePoints: 741,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1606.989999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.49,
                StartDate: "2022.03.15 03:28",
                EndDate: "2022.03.15 08:25",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 06:22",
                BuyPoints: 1192,
                SalePoints: 829,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1613.799999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 33.76,
                StartDate: "2022.03.15 08:25",
                EndDate: "2022.03.15 16:10",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.15 13:56",
                BuyPoints: 1564,
                SalePoints: 823,
                MaxRisk: -47.20000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1614.289999999999,
                SafeAreayPoint: 109,
                Aversion: 54,
                LostTreads: -119.2,
            },
            {
                Profit: 1.21,
                StartDate: "2022.03.15 16:10",
                EndDate: "2022.03.15 16:41",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.15 16:22",
                BuyPoints: 699,
                SalePoints: 1080,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1648.049999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.15 16:41",
                EndDate: "2022.03.15 17:07",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 16:41",
                BuyPoints: 1018,
                SalePoints: 655,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1649.259999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.18,
                StartDate: "2022.03.15 17:07",
                EndDate: "2022.03.15 18:55",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.15 18:16",
                BuyPoints: 691,
                SalePoints: 1162,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1649.889999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.01,
            },
            {
                Profit: 1.33,
                StartDate: "2022.03.15 18:55",
                EndDate: "2022.03.15 22:33",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.15 20:48",
                BuyPoints: 1105,
                SalePoints: 724,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1653.069999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 7.54,
                StartDate: "2022.03.15 22:33",
                EndDate: "2022.03.16 14:50",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.16 11:46",
                BuyPoints: 679,
                SalePoints: 1312,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1654.4,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.43000000000001,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.03.16 14:50",
                EndDate: "2022.03.16 15:23",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 14:50",
                BuyPoints: 1079,
                SalePoints: 716,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1661.94,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.84,
                StartDate: "2022.03.16 15:23",
                EndDate: "2022.03.16 17:15",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:11",
                BuyPoints: 1366,
                SalePoints: 1003,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1662.63,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 0.85,
                StartDate: "2022.03.16 17:15",
                EndDate: "2022.03.16 17:41",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:15",
                BuyPoints: 881,
                SalePoints: 1244,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1663.47,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.21,
                StartDate: "2022.03.16 17:41",
                EndDate: "2022.03.16 21:00",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.16 17:44",
                BuyPoints: 1279,
                SalePoints: 898,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1664.319999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.16 21:00",
                EndDate: "2022.03.16 21:01",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 21:00",
                BuyPoints: 825,
                SalePoints: 1188,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1666.53,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.16 21:01",
                EndDate: "2022.03.16 21:16",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 21:01",
                BuyPoints: 1101,
                SalePoints: 738,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1667.33,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.49,
                StartDate: "2022.03.16 21:16",
                EndDate: "2022.03.16 21:48",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.16 21:33",
                BuyPoints: 659,
                SalePoints: 1130,
                MaxRisk: -4.150000000000091,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1668.04,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 2.57,
                StartDate: "2022.03.16 21:48",
                EndDate: "2022.03.16 22:42",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.16 22:10",
                BuyPoints: 824,
                SalePoints: 1241,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1670.53,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 2.3,
                StartDate: "2022.03.16 22:42",
                EndDate: "2022.03.17 06:22",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.17 04:02",
                BuyPoints: 797,
                SalePoints: 1214,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1673.099999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 3.41,
                StartDate: "2022.03.17 06:22",
                EndDate: "2022.03.17 17:55",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.17 15:33",
                BuyPoints: 660,
                SalePoints: 1203,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1675.399999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.17 17:55",
                EndDate: "2022.03.17 21:12",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.17 17:55",
                BuyPoints: 1196,
                SalePoints: 833,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1678.809999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.06,
                StartDate: "2022.03.17 21:12",
                EndDate: "2022.03.18 06:35",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.18 05:16",
                BuyPoints: 1191,
                SalePoints: 774,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1679.609999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.88,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.18 06:35",
                EndDate: "2022.03.18 10:09",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.18 06:35",
                BuyPoints: 678,
                SalePoints: 1041,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1681.669999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 12.72,
                StartDate: "2022.03.18 10:09",
                EndDate: "2022.03.18 16:24",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.18 16:01",
                BuyPoints: 1617,
                SalePoints: 1146,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1682.319999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.18 16:24",
                EndDate: "2022.03.18 16:33",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.18 16:24",
                BuyPoints: 679,
                SalePoints: 1042,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1695.039999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.46,
                StartDate: "2022.03.18 16:33",
                EndDate: "2022.03.18 22:02",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.18 19:35",
                BuyPoints: 1299,
                SalePoints: 666,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1695.689999999999,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 3.75,
                StartDate: "2022.03.18 22:02",
                EndDate: "2022.03.21 16:50",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.21 16:29",
                BuyPoints: 719,
                SalePoints: 1190,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1702.149999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 11.02,
                StartDate: "2022.03.21 16:50",
                EndDate: "2022.03.22 16:46",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.22 11:10",
                BuyPoints: 1352,
                SalePoints: 719,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1705.899999999999,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.35,
            },
            {
                Profit: 6.95,
                StartDate: "2022.03.22 16:46",
                EndDate: "2022.03.23 12:44",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.23 04:03",
                BuyPoints: 871,
                SalePoints: 1342,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1716.919999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 13.19,
                StartDate: "2022.03.23 12:44",
                EndDate: "2022.03.23 22:36",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.23 19:46",
                BuyPoints: 702,
                SalePoints: 1443,
                MaxRisk: -47.20000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1723.869999999999,
                SafeAreayPoint: 109,
                Aversion: 54,
                LostTreads: -119.2,
            },
            {
                Profit: 2.44,
                StartDate: "2022.03.23 22:36",
                EndDate: "2022.03.24 14:49",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.24 13:23",
                BuyPoints: 811,
                SalePoints: 1228,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1737.059999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.79,
                StartDate: "2022.03.24 14:49",
                EndDate: "2022.03.24 15:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.24 14:49",
                BuyPoints: 1180,
                SalePoints: 817,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1739.499999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.12,
                StartDate: "2022.03.24 15:25",
                EndDate: "2022.03.24 16:30",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.24 15:30",
                BuyPoints: 680,
                SalePoints: 1061,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1740.289999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1,
                StartDate: "2022.03.24 16:30",
                EndDate: "2022.03.24 17:35",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.24 17:18",
                BuyPoints: 667,
                SalePoints: 1084,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1741.409999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.68,
                StartDate: "2022.03.24 17:35",
                EndDate: "2022.03.25 09:44",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.24 17:35",
                BuyPoints: 1076,
                SalePoints: 713,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1742.409999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 5.26,
                StartDate: "2022.03.25 09:44",
                EndDate: "2022.03.25 16:36",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.25 15:37",
                BuyPoints: 1247,
                SalePoints: 704,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1743.089999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.25 16:36",
                EndDate: "2022.03.25 17:13",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.25 16:36",
                BuyPoints: 735,
                SalePoints: 1098,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1748.349999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.54,
                StartDate: "2022.03.25 17:13",
                EndDate: "2022.03.28 06:56",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.28 04:13",
                BuyPoints: 1323,
                SalePoints: 852,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1749.059999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.2,
                StartDate: "2022.03.28 06:56",
                EndDate: "2022.03.28 10:30",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 08:30",
                BuyPoints: 1049,
                SalePoints: 686,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1755.599999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 7.74,
                StartDate: "2022.03.28 10:30",
                EndDate: "2022.03.28 15:16",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.28 15:12",
                BuyPoints: 681,
                SalePoints: 1314,
                MaxRisk: -20.63000000000011,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1755.799999999999,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 2.43,
                StartDate: "2022.03.28 15:16",
                EndDate: "2022.03.28 16:21",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.28 15:55",
                BuyPoints: 1127,
                SalePoints: 656,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1763.539999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 1.29,
                StartDate: "2022.03.28 16:21",
                EndDate: "2022.03.28 17:20",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.28 16:35",
                BuyPoints: 715,
                SalePoints: 1096,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1765.969999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.8,
                StartDate: "2022.03.28 17:20",
                EndDate: "2022.03.28 21:29",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 17:20",
                BuyPoints: 1190,
                SalePoints: 827,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1767.259999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.45,
                StartDate: "2022.03.28 21:29",
                EndDate: "2022.03.28 22:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 21:52",
                BuyPoints: 1172,
                SalePoints: 809,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1768.059999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.02,
                StartDate: "2022.03.28 22:33",
                EndDate: "2022.03.29 04:52",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.28 23:05",
                BuyPoints: 662,
                SalePoints: 1043,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1768.509999999999,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.74,
                StartDate: "2022.03.29 04:52",
                EndDate: "2022.03.29 10:34",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 04:52",
                BuyPoints: 1135,
                SalePoints: 772,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1769.529999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.24,
                StartDate: "2022.03.29 10:34",
                EndDate: "2022.03.29 14:46",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 11:29",
                BuyPoints: 1069,
                SalePoints: 706,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1770.269999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 10.97,
                StartDate: "2022.03.29 14:46",
                EndDate: "2022.03.29 15:24",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.29 15:08",
                BuyPoints: 1383,
                SalePoints: 840,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1770.509999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 5.94,
                StartDate: "2022.03.29 15:24",
                EndDate: "2022.03.29 16:52",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.29 16:09",
                BuyPoints: 823,
                SalePoints: 1294,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1781.479999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 1.45,
                StartDate: "2022.03.29 16:52",
                EndDate: "2022.03.29 19:39",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 17:01",
                BuyPoints: 712,
                SalePoints: 1129,
                MaxRisk: -1.980000000000018,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1787.419999999998,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 1.12,
                StartDate: "2022.03.29 19:39",
                EndDate: "2022.03.30 09:24",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.30 03:55",
                BuyPoints: 679,
                SalePoints: 1096,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1788.869999999998,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.03.30 09:24",
                EndDate: "2022.03.30 12:05",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 09:24",
                BuyPoints: 1086,
                SalePoints: 723,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1789.989999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.11,
                StartDate: "2022.03.30 12:05",
                EndDate: "2022.03.30 15:16",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 12:05",
                BuyPoints: 1141,
                SalePoints: 1504,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1790.679999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.30 15:16",
                EndDate: "2022.03.30 16:21",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 15:16",
                BuyPoints: 1101,
                SalePoints: 738,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1791.789999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.03.30 16:21",
                EndDate: "2022.03.30 16:40",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 16:21",
                BuyPoints: 721,
                SalePoints: 1084,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1792.499999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 18.97,
                StartDate: "2022.03.30 16:40",
                EndDate: "2022.03.31 07:28",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.31 03:13",
                BuyPoints: 1446,
                SalePoints: 813,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1793.189999999998,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.31 07:28",
                EndDate: "2022.03.31 10:11",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 07:28",
                BuyPoints: 655,
                SalePoints: 1018,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1812.159999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.79,
                StartDate: "2022.03.31 10:11",
                EndDate: "2022.03.31 16:34",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.31 15:20",
                BuyPoints: 669,
                SalePoints: 1212,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1812.789999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 3.07,
                StartDate: "2022.03.31 16:34",
                EndDate: "2022.04.01 10:02",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.31 22:35",
                BuyPoints: 1158,
                SalePoints: 687,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1816.579999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 8.6,
                StartDate: "2022.04.01 10:02",
                EndDate: "2022.04.01 17:34",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.01 17:23",
                BuyPoints: 1416,
                SalePoints: 675,
                MaxRisk: -47.20000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1819.649999999998,
                SafeAreayPoint: 109,
                Aversion: 54,
                LostTreads: -119.2,
            },
            {
                Profit: 0.8,
                StartDate: "2022.04.01 17:34",
                EndDate: "2022.04.01 18:19",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 17:34",
                BuyPoints: 826,
                SalePoints: 1189,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1828.249999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.68,
                StartDate: "2022.04.01 18:19",
                EndDate: "2022.04.01 20:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 18:19",
                BuyPoints: 1077,
                SalePoints: 714,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1829.049999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.6,
                StartDate: "2022.04.01 20:25",
                EndDate: "2022.04.04 10:05",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.04 09:21",
                BuyPoints: 712,
                SalePoints: 1183,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1829.729999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 7.69,
                StartDate: "2022.04.04 10:05",
                EndDate: "2022.04.05 16:45",
                EndLot: 5.12,
                LastTread: "Buy",
                BuyLot: 6.82,
                SaleLot: 3.41,
                LotDifference: 3.41,
                LastLotSartedDate: "2022.04.05 15:46",
                BuyPoints: 717,
                SalePoints: 1728,
                MaxRisk: -250.76,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1833.329999999998,
                SafeAreayPoint: 101,
                Aversion: 72,
                LostTreads: -554.1700000000001,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.04.05 16:45",
                EndDate: "2022.04.05 17:13",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.05 16:45",
                BuyPoints: 1085,
                SalePoints: 722,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1841.019999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.14,
                StartDate: "2022.04.05 17:13",
                EndDate: "2022.04.05 18:59",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.05 17:17",
                BuyPoints: 1517,
                SalePoints: 1154,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1841.709999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 5.91,
                StartDate: "2022.04.05 18:59",
                EndDate: "2022.04.06 12:08",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.06 10:33",
                BuyPoints: 821,
                SalePoints: 1292,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1842.849999999998,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.01,
            },
            {
                Profit: 1.07,
                StartDate: "2022.04.06 12:08",
                EndDate: "2022.04.06 14:10",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.06 12:22",
                BuyPoints: 1053,
                SalePoints: 672,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1848.759999999998,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 1.47,
                StartDate: "2022.04.06 14:10",
                EndDate: "2022.04.06 16:36",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.06 14:43",
                BuyPoints: 750,
                SalePoints: 1131,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1849.829999999998,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.76,
                StartDate: "2022.04.06 16:36",
                EndDate: "2022.04.06 20:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 16:36",
                BuyPoints: 1157,
                SalePoints: 794,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1851.299999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.38,
                StartDate: "2022.04.06 20:25",
                EndDate: "2022.04.07 14:36",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.06 21:32",
                BuyPoints: 701,
                SalePoints: 1172,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1852.059999999998,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 10.55,
                StartDate: "2022.04.07 14:36",
                EndDate: "2022.04.08 17:02",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.08 11:08",
                BuyPoints: 830,
                SalePoints: 1373,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1855.439999999998,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 8.6,
                StartDate: "2022.04.08 17:02",
                EndDate: "2022.04.11 11:21",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.11 10:58",
                BuyPoints: 675,
                SalePoints: 1416,
                MaxRisk: -47.20000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1865.989999999998,
                SafeAreayPoint: 109,
                Aversion: 54,
                LostTreads: -119.2,
            },
            {
                Profit: 1.2,
                StartDate: "2022.04.11 11:21",
                EndDate: "2022.04.11 15:34",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.11 14:15",
                BuyPoints: 687,
                SalePoints: 1104,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1874.589999999998,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 2.24,
                StartDate: "2022.04.11 15:34",
                EndDate: "2022.04.11 16:50",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.11 15:41",
                BuyPoints: 1286,
                SalePoints: 905,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1875.789999999998,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.39,
                StartDate: "2022.04.11 16:50",
                EndDate: "2022.04.11 19:52",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 16:52",
                BuyPoints: 1143,
                SalePoints: 780,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1878.029999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 1.13,
                StartDate: "2022.04.11 19:52",
                EndDate: "2022.04.11 22:20",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.11 20:26",
                BuyPoints: 683,
                SalePoints: 1064,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1878.419999999998,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 20.38,
                StartDate: "2022.04.11 22:20",
                EndDate: "2022.04.12 15:33",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.12 13:06",
                BuyPoints: 1064,
                SalePoints: 1607,
                MaxRisk: -9.350000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1879.549999999999,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 1.05,
                StartDate: "2022.04.12 15:33",
                EndDate: "2022.04.12 17:54",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.12 17:38",
                BuyPoints: 672,
                SalePoints: 1089,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1899.929999999999,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 0.8,
                StartDate: "2022.04.12 17:54",
                EndDate: "2022.04.12 21:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 17:54",
                BuyPoints: 1188,
                SalePoints: 825,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1900.979999999999,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.32,
                StartDate: "2022.04.12 21:06",
                EndDate: "2022.04.13 12:47",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.13 04:55",
                BuyPoints: 841,
                SalePoints: 1312,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1901.779999999999,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 4.23,
                StartDate: "2022.04.13 12:47",
                EndDate: "2022.04.14 12:07",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.13 21:29",
                BuyPoints: 1213,
                SalePoints: 742,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1908.099999999998,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.71,
                StartDate: "2022.04.14 12:07",
                EndDate: "2022.04.14 14:42",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.14 12:07",
                BuyPoints: 743,
                SalePoints: 1106,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1912.329999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.76,
                StartDate: "2022.04.14 14:42",
                EndDate: "2022.04.14 16:47",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.14 14:42",
                BuyPoints: 1151,
                SalePoints: 788,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1913.039999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.67,
                StartDate: "2022.04.14 16:47",
                EndDate: "2022.04.18 01:51",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.14 19:12",
                BuyPoints: 858,
                SalePoints: 1329,
                MaxRisk: -4.160000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1913.799999999998,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 0.25,
                StartDate: "2022.04.18 01:51",
                EndDate: "2022.04.18 09:57",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.18 02:33",
                BuyPoints: 708,
                SalePoints: 1071,
                MaxRisk: -0.3900000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1920.469999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: -1.17,
            },
            {
                Profit: 10.29,
                StartDate: "2022.04.18 09:57",
                EndDate: "2022.04.18 20:37",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.04.18 17:36",
                BuyPoints: 1344,
                SalePoints: 711,
                MaxRisk: -20.66000000000008,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1920.719999999998,
                SafeAreayPoint: 113,
                Aversion: 45,
                LostTreads: -55.39,
            },
            {
                Profit: 34.44,
                StartDate: "2022.04.18 20:37",
                EndDate: "2022.04.19 15:47",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.19 15:36",
                BuyPoints: 1568,
                SalePoints: 827,
                MaxRisk: -47.20000000000005,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1931.009999999998,
                SafeAreayPoint: 109,
                Aversion: 54,
                LostTreads: -119.2,
            },
            {
                Profit: 2.92,
                StartDate: "2022.04.19 15:47",
                EndDate: "2022.04.19 17:38",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.19 16:57",
                BuyPoints: 1277,
                SalePoints: 860,
                MaxRisk: -1.990000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1965.449999999998,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: -5.87,
            },
            {
                Profit: 11.01,
                StartDate: "2022.04.19 17:38",
                EndDate: "2022.04.20 07:05",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.19 22:12",
                BuyPoints: 1384,
                SalePoints: 841,
                MaxRisk: -9.329999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1968.369999999998,
                SafeAreayPoint: 117,
                Aversion: 36,
                LostTreads: -26.29,
            },
            {
                Profit: 0.62,
                StartDate: "2022.04.20 07:05",
                EndDate: "2022.04.20 12:37",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.20 07:05",
                BuyPoints: 645,
                SalePoints: 1008,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1979.379999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.85,
                StartDate: "2022.04.20 12:37",
                EndDate: "2022.04.21 14:18",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.21 11:05",
                BuyPoints: 1147,
                SalePoints: 676,
                MaxRisk: -4.150000000000091,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1979.999999999998,
                SafeAreayPoint: 121,
                Aversion: 27,
                LostTreads: -12.02,
            },
            {
                Profit: 1.12,
                StartDate: "2022.04.21 14:18",
                EndDate: "2022.04.21 15:55",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.21 15:07",
                BuyPoints: 680,
                SalePoints: 1061,
                MaxRisk: -0.8199999999999363,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1982.849999999998,
                SafeAreayPoint: 129,
                Aversion: 9,
                LostTreads: -2.3,
            },
            {
                Profit: 0.67,
                StartDate: "2022.04.21 15:55",
                EndDate: "2022.04.21 18:41",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.21 15:55",
                BuyPoints: 1062,
                SalePoints: 699,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1983.969999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.61,
                StartDate: "2022.04.21 18:41",
                EndDate: "2022.04.21 20:35",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.21 18:41",
                BuyPoints: 644,
                SalePoints: 1007,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1984.639999999998,
                SafeAreayPoint: 133,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: -1.67,
                StartDate: "2022.04.21 20:35",
                EndDate: "2022.04.22 07:25",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.22 06:45",
                BuyPoints: 22,
                SalePoints: 439,
                MaxRisk: -1.980000000000018,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1985.249999999998,
                SafeAreayPoint: 125,
                Aversion: 18,
                LostTreads: 0,
            },
        ],

        End: {
            Balance: 1983.609999999998,
            MaxmimLotsRiched: 5.12,
            Deals: 270,
            Eqity: 1983.579999999998,
            Profit: -0.03,
            Ask: 1951.58,
            StartPoint: 0,
            AllLost: 2,
            AllLostProfit: -1.72,
            AllWin: 267,
            AllWinProfit: 985.3300000000002,
            EndDate: "2022.04.22 07:25",
            Spread: 30,
        },
        Reason: 0,
    },

    {
        Name: "_4",
        Start: { StartDate: "2022.02.10 02:00", Balance: 1000, Gap: 300, _GoalLine: 660, SafeAreayPoint: 90, Aversion: 0, Bomo: 9, Spread: 30 },
        Treads: [
            {},
            {
                Profit: 0.11,
                StartDate: "2022.02.10 02:00",
                EndDate: "2022.02.10 15:30",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 15:30",
                BuyPoints: 900,
                SalePoints: 570,
                MaxRisk: -0.3600000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 999.97,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.03,
            },
            {
                Profit: 0.61,
                StartDate: "2022.02.10 15:30",
                EndDate: "2022.02.10 15:40",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 15:30",
                BuyPoints: 643,
                SalePoints: 973,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1000.08,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.12,
                StartDate: "2022.02.10 15:40",
                EndDate: "2022.02.10 17:43",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.10 16:46",
                BuyPoints: 604,
                SalePoints: 988,
                MaxRisk: -1.830000000000041,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1000.69,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.57,
                StartDate: "2022.02.10 17:43",
                EndDate: "2022.02.10 19:51",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 17:43",
                BuyPoints: 934,
                SalePoints: 604,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1001.81,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.24,
                StartDate: "2022.02.10 19:51",
                EndDate: "2022.02.11 06:33",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.10 21:44",
                BuyPoints: 960,
                SalePoints: 630,
                MaxRisk: -0.3600000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1002.38,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.2,
                StartDate: "2022.02.11 06:33",
                EndDate: "2022.02.11 14:31",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.11 09:29",
                BuyPoints: 637,
                SalePoints: 985,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1002.62,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.12,
                StartDate: "2022.02.11 14:31",
                EndDate: "2022.02.11 19:11",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 17:15",
                BuyPoints: 572,
                SalePoints: 902,
                MaxRisk: -0.3600000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1003.82,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.19,
                StartDate: "2022.02.11 19:11",
                EndDate: "2022.02.11 20:37",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 20:32",
                BuyPoints: 609,
                SalePoints: 939,
                MaxRisk: -0.3600000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1003.94,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.03,
            },
            {
                Profit: 0.18,
                StartDate: "2022.02.11 20:37",
                EndDate: "2022.02.11 20:46",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.11 20:46",
                BuyPoints: 607,
                SalePoints: 937,
                MaxRisk: -0.3600000000000136,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1004.13,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.03,
            },
            {
                Profit: 22.71,
                StartDate: "2022.02.11 20:46",
                EndDate: "2022.02.14 17:40",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.02.14 15:49",
                BuyPoints: 683,
                SalePoints: 1391,
                MaxRisk: -44.39999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1004.31,
                SafeAreayPoint: 64,
                Aversion: 54,
                LostTreads: -106.45,
            },
            {
                Profit: 0.61,
                StartDate: "2022.02.14 17:40",
                EndDate: "2022.02.14 18:20",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.14 17:40",
                BuyPoints: 968,
                SalePoints: 638,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1027.02,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.26,
                StartDate: "2022.02.14 18:20",
                EndDate: "2022.02.14 20:55",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.14 19:53",
                BuyPoints: 671,
                SalePoints: 1109,
                MaxRisk: -3.830000000000041,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1027.63,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 4.12,
                StartDate: "2022.02.14 20:55",
                EndDate: "2022.02.15 10:45",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.15 10:09",
                BuyPoints: 1103,
                SalePoints: 665,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1031.89,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.45,
                StartDate: "2022.02.15 10:45",
                EndDate: "2022.02.15 11:27",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.15 10:45",
                BuyPoints: 1064,
                SalePoints: 734,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1036.010000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 27.71,
                StartDate: "2022.02.15 11:27",
                EndDate: "2022.02.16 16:35",
                EndLot: 2.56,
                LastTread: "Buy",
                BuyLot: 3.41,
                SaleLot: 1.7,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.02.15 20:15",
                BuyPoints: 665,
                SalePoints: 1499,
                MaxRisk: -102.5600000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1036.460000000001,
                SafeAreayPoint: 60,
                Aversion: 63,
                LostTreads: -230.39,
            },
            {
                Profit: 0.16,
                StartDate: "2022.02.16 16:35",
                EndDate: "2022.02.16 22:40",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.16 18:35",
                BuyPoints: 589,
                SalePoints: 919,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1064.170000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 6.08,
                StartDate: "2022.02.16 22:40",
                EndDate: "2022.02.17 10:26",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.02.17 08:02",
                BuyPoints: 649,
                SalePoints: 1159,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1064.330000000001,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.16,
            },
            {
                Profit: 1.25,
                StartDate: "2022.02.17 10:26",
                EndDate: "2022.02.17 11:28",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.17 10:36",
                BuyPoints: 617,
                SalePoints: 1001,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1070.410000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.02.17 11:28",
                EndDate: "2022.02.17 12:13",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.17 11:28",
                BuyPoints: 1045,
                SalePoints: 715,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1071.660000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.62,
                StartDate: "2022.02.17 12:13",
                EndDate: "2022.02.17 15:26",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.17 12:13",
                BuyPoints: 650,
                SalePoints: 980,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1072.350000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.67,
                StartDate: "2022.02.17 15:26",
                EndDate: "2022.02.18 02:36",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.17 16:47",
                BuyPoints: 659,
                SalePoints: 1043,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1072.970000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.59,
                StartDate: "2022.02.18 02:36",
                EndDate: "2022.02.18 03:36",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.18 02:36",
                BuyPoints: 948,
                SalePoints: 618,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1074.640000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 38.79,
                StartDate: "2022.02.18 03:36",
                EndDate: "2022.02.21 01:01",
                EndLot: 2.56,
                LastTread: "Buy",
                BuyLot: 3.41,
                SaleLot: 1.7,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.02.18 19:30",
                BuyPoints: 697,
                SalePoints: 1531,
                MaxRisk: -102.5600000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1075.230000000001,
                SafeAreayPoint: 60,
                Aversion: 63,
                LostTreads: -230.22,
            },
            {
                Profit: 1.86,
                StartDate: "2022.02.21 01:01",
                EndDate: "2022.02.21 02:55",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.21 02:51",
                BuyPoints: 1116,
                SalePoints: 768,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1114.020000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 11.2,
                StartDate: "2022.02.21 02:55",
                EndDate: "2022.02.21 21:01",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.21 20:00",
                BuyPoints: 647,
                SalePoints: 1247,
                MaxRisk: -19.24000000000001,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1115.880000000001,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 6.75,
                StartDate: "2022.02.21 21:01",
                EndDate: "2022.02.22 10:59",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.22 10:29",
                BuyPoints: 1228,
                SalePoints: 790,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1127.080000000001,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 6.28,
                StartDate: "2022.02.22 10:59",
                EndDate: "2022.02.22 15:32",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.22 14:22",
                BuyPoints: 768,
                SalePoints: 1206,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1133.830000000001,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.65,
                StartDate: "2022.02.22 15:32",
                EndDate: "2022.02.22 17:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.22 15:32",
                BuyPoints: 1013,
                SalePoints: 683,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1140.110000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.12,
                StartDate: "2022.02.22 17:11",
                EndDate: "2022.02.22 20:14",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.22 17:57",
                BuyPoints: 620,
                SalePoints: 968,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1140.760000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.54,
                StartDate: "2022.02.22 20:14",
                EndDate: "2022.02.22 22:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.22 20:14",
                BuyPoints: 899,
                SalePoints: 569,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1141.880000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 5.46,
                StartDate: "2022.02.22 22:11",
                EndDate: "2022.02.23 17:00",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.23 15:20",
                BuyPoints: 729,
                SalePoints: 1167,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1142.420000000001,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 2.32,
                StartDate: "2022.02.23 17:00",
                EndDate: "2022.02.24 04:49",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.23 22:55",
                BuyPoints: 724,
                SalePoints: 1108,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1147.880000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.26,
                StartDate: "2022.02.24 04:49",
                EndDate: "2022.02.24 05:09",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 04:51",
                BuyPoints: 638,
                SalePoints: 968,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1150.200000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 2.79,
                StartDate: "2022.02.24 05:09",
                EndDate: "2022.02.24 06:11",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.24 05:34",
                BuyPoints: 771,
                SalePoints: 1155,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1150.460000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.34,
                StartDate: "2022.02.24 06:11",
                EndDate: "2022.02.24 06:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:12",
                BuyPoints: 678,
                SalePoints: 1008,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1153.250000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.87,
                StartDate: "2022.02.24 06:17",
                EndDate: "2022.02.24 06:23",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:17",
                BuyPoints: 1230,
                SalePoints: 900,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1153.590000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.61,
                StartDate: "2022.02.24 06:23",
                EndDate: "2022.02.24 06:27",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:23",
                BuyPoints: 642,
                SalePoints: 972,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1154.460000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.59,
                StartDate: "2022.02.24 06:27",
                EndDate: "2022.02.24 06:35",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 06:27",
                BuyPoints: 953,
                SalePoints: 623,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1155.070000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.53,
                StartDate: "2022.02.24 06:35",
                EndDate: "2022.02.24 11:28",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.24 08:21",
                BuyPoints: 592,
                SalePoints: 1192,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1155.660000000001,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.18,
                StartDate: "2022.02.24 11:28",
                EndDate: "2022.02.24 12:05",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 11:47",
                BuyPoints: 598,
                SalePoints: 928,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1162.190000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.02.24 12:05",
                EndDate: "2022.02.24 12:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 12:13",
                BuyPoints: 853,
                SalePoints: 1183,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1162.370000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 17.05,
                StartDate: "2022.02.24 12:17",
                EndDate: "2022.02.24 14:17",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.24 13:48",
                BuyPoints: 1316,
                SalePoints: 716,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1163.060000000001,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 1.22,
                StartDate: "2022.02.24 14:17",
                EndDate: "2022.02.24 15:03",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.24 14:35",
                BuyPoints: 641,
                SalePoints: 989,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1180.110000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.65,
                StartDate: "2022.02.24 15:03",
                EndDate: "2022.02.24 15:46",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 15:03",
                BuyPoints: 1007,
                SalePoints: 677,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1181.330000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 7.5,
                StartDate: "2022.02.24 15:46",
                EndDate: "2022.02.24 16:33",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.24 16:11",
                BuyPoints: 1626,
                SalePoints: 1242,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1181.980000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 3.11,
                StartDate: "2022.02.24 16:33",
                EndDate: "2022.02.24 16:54",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.24 16:39",
                BuyPoints: 1187,
                SalePoints: 803,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1189.480000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.62,
                StartDate: "2022.02.24 16:54",
                EndDate: "2022.02.24 17:02",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 16:54",
                BuyPoints: 647,
                SalePoints: 977,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1192.590000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.64,
                StartDate: "2022.02.24 17:02",
                EndDate: "2022.02.24 17:16",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:02",
                BuyPoints: 1001,
                SalePoints: 671,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1193.210000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.51,
                StartDate: "2022.02.24 17:16",
                EndDate: "2022.02.24 17:18",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:17",
                BuyPoints: 1097,
                SalePoints: 767,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1193.850000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.83,
                StartDate: "2022.02.24 17:18",
                EndDate: "2022.02.24 17:44",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.24 17:22",
                BuyPoints: 764,
                SalePoints: 1112,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1194.360000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.62,
                StartDate: "2022.02.24 17:44",
                EndDate: "2022.02.24 18:22",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 17:44",
                BuyPoints: 983,
                SalePoints: 653,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1196.190000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 5.28,
                StartDate: "2022.02.24 18:22",
                EndDate: "2022.02.24 21:09",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.24 20:50",
                BuyPoints: 1405,
                SalePoints: 1021,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1196.810000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.13,
            },
            {
                Profit: 0.77,
                StartDate: "2022.02.24 21:09",
                EndDate: "2022.02.24 21:20",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:14",
                BuyPoints: 1223,
                SalePoints: 893,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1202.090000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.29,
                StartDate: "2022.02.24 21:20",
                EndDate: "2022.02.24 21:27",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 21:27",
                BuyPoints: 984,
                SalePoints: 654,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1202.860000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 5.02,
                StartDate: "2022.02.24 21:27",
                EndDate: "2022.02.24 22:27",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.02.24 22:14",
                BuyPoints: 708,
                SalePoints: 1146,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1203.150000000001,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.14,
                StartDate: "2022.02.24 22:27",
                EndDate: "2022.02.24 23:22",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.24 22:44",
                BuyPoints: 582,
                SalePoints: 912,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1208.170000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.93,
                StartDate: "2022.02.24 23:22",
                EndDate: "2022.02.25 01:20",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.25 01:01",
                BuyPoints: 686,
                SalePoints: 1070,
                MaxRisk: -1.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1208.310000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.13,
            },
            {
                Profit: 0.55,
                StartDate: "2022.02.25 01:20",
                EndDate: "2022.02.25 01:41",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 01:20",
                BuyPoints: 913,
                SalePoints: 583,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1210.240000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1,
                StartDate: "2022.02.25 01:41",
                EndDate: "2022.02.25 03:01",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 01:41",
                BuyPoints: 1025,
                SalePoints: 1355,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1210.790000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.41,
                StartDate: "2022.02.25 03:01",
                EndDate: "2022.02.25 03:25",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 03:08",
                BuyPoints: 1026,
                SalePoints: 678,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1211.790000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.95,
                StartDate: "2022.02.25 03:25",
                EndDate: "2022.02.25 05:50",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 03:45",
                BuyPoints: 587,
                SalePoints: 935,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1213.200000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 1.18,
                StartDate: "2022.02.25 05:50",
                EndDate: "2022.02.25 10:16",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 09:23",
                BuyPoints: 981,
                SalePoints: 633,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1214.150000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.6,
                StartDate: "2022.02.25 10:16",
                EndDate: "2022.02.25 13:40",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 13:23",
                BuyPoints: 1138,
                SalePoints: 808,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1215.330000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.02,
                StartDate: "2022.02.25 13:40",
                EndDate: "2022.02.25 14:31",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.25 13:47",
                BuyPoints: 602,
                SalePoints: 950,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1215.930000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.67,
                StartDate: "2022.02.25 14:31",
                EndDate: "2022.02.25 14:53",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.25 14:31",
                BuyPoints: 1032,
                SalePoints: 702,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1216.950000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.22,
                StartDate: "2022.02.25 14:53",
                EndDate: "2022.02.25 15:52",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.25 15:07",
                BuyPoints: 998,
                SalePoints: 614,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1217.620000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 22.47,
                StartDate: "2022.02.25 15:52",
                EndDate: "2022.02.28 09:54",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.02.25 20:07",
                BuyPoints: 912,
                SalePoints: 1512,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1218.840000000001,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -60.31,
            },
            {
                Profit: 1.11,
                StartDate: "2022.02.28 09:54",
                EndDate: "2022.02.28 12:54",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.28 11:39",
                BuyPoints: 605,
                SalePoints: 989,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1241.310000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.13,
            },
            {
                Profit: 0.22,
                StartDate: "2022.02.28 12:54",
                EndDate: "2022.02.28 15:58",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 14:59",
                BuyPoints: 620,
                SalePoints: 950,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1242.420000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.78,
                StartDate: "2022.02.28 15:58",
                EndDate: "2022.02.28 16:33",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 15:58",
                BuyPoints: 1142,
                SalePoints: 812,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1242.640000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.02.28 16:33",
                EndDate: "2022.02.28 16:41",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 16:33",
                BuyPoints: 580,
                SalePoints: 910,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1243.420000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.67,
                StartDate: "2022.02.28 16:41",
                EndDate: "2022.02.28 17:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 16:41",
                BuyPoints: 1025,
                SalePoints: 695,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1243.970000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.67,
                StartDate: "2022.02.28 17:00",
                EndDate: "2022.02.28 17:11",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 17:00",
                BuyPoints: 703,
                SalePoints: 1033,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1244.640000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.64,
                StartDate: "2022.02.28 17:11",
                EndDate: "2022.02.28 17:45",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.02.28 17:11",
                BuyPoints: 1000,
                SalePoints: 670,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1245.310000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.29,
                StartDate: "2022.02.28 17:45",
                EndDate: "2022.02.28 18:41",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.02.28 18:07",
                BuyPoints: 1005,
                SalePoints: 621,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1245.950000000002,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 1.48,
                StartDate: "2022.02.28 18:41",
                EndDate: "2022.02.28 22:00",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.02.28 19:58",
                BuyPoints: 694,
                SalePoints: 1042,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1247.240000000002,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 1.89,
                StartDate: "2022.02.28 22:00",
                EndDate: "2022.03.01 11:32",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.01 08:54",
                BuyPoints: 681,
                SalePoints: 1065,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1248.720000000002,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.16,
                StartDate: "2022.03.01 11:32",
                EndDate: "2022.03.01 13:05",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 12:28",
                BuyPoints: 589,
                SalePoints: 919,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1250.610000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.01 13:05",
                EndDate: "2022.03.01 14:55",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 13:05",
                BuyPoints: 1010,
                SalePoints: 680,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1250.770000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 11.91,
                StartDate: "2022.03.01 14:55",
                EndDate: "2022.03.01 16:56",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.01 16:02",
                BuyPoints: 656,
                SalePoints: 1256,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1251.420000000002,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.09,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.01 16:56",
                EndDate: "2022.03.01 17:17",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 16:56",
                BuyPoints: 921,
                SalePoints: 591,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1263.330000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.01 17:17",
                EndDate: "2022.03.01 17:46",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 17:17",
                BuyPoints: 738,
                SalePoints: 1068,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1263.890000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.27,
                StartDate: "2022.03.01 17:46",
                EndDate: "2022.03.01 20:18",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 18:35",
                BuyPoints: 644,
                SalePoints: 974,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1264.600000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 10.93,
                StartDate: "2022.03.01 20:18",
                EndDate: "2022.03.01 23:02",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.01 22:00",
                BuyPoints: 764,
                SalePoints: 1274,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1264.870000000002,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.82,
                StartDate: "2022.03.01 23:02",
                EndDate: "2022.03.01 23:36",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.01 23:02",
                BuyPoints: 1181,
                SalePoints: 851,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1275.800000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.12,
                StartDate: "2022.03.01 23:36",
                EndDate: "2022.03.02 03:21",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 02:42",
                BuyPoints: 900,
                SalePoints: 570,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1276.620000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.75,
                StartDate: "2022.03.02 03:21",
                EndDate: "2022.03.02 04:09",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 03:21",
                BuyPoints: 777,
                SalePoints: 1107,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1276.740000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.68,
                StartDate: "2022.03.02 04:09",
                EndDate: "2022.03.02 08:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 04:09",
                BuyPoints: 1036,
                SalePoints: 706,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1277.490000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.02 08:06",
                EndDate: "2022.03.02 08:50",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 08:06",
                BuyPoints: 674,
                SalePoints: 1004,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1278.170000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.22,
                StartDate: "2022.03.02 08:50",
                EndDate: "2022.03.02 11:31",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.02 10:18",
                BuyPoints: 1108,
                SalePoints: 670,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1278.810000000002,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.53,
                StartDate: "2022.03.02 11:31",
                EndDate: "2022.03.02 12:26",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 12:26",
                BuyPoints: 1104,
                SalePoints: 774,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1283.030000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.54,
                StartDate: "2022.03.02 12:26",
                EndDate: "2022.03.02 12:27",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.02 12:26",
                BuyPoints: 705,
                SalePoints: 1053,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0.02999999999997272,
                StartedProfit: 0.03,
                StartEqity: 1283.560000000002,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.68,
                StartDate: "2022.03.02 12:27",
                EndDate: "2022.03.02 12:29",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 12:27",
                BuyPoints: 1041,
                SalePoints: 711,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1285.100000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.02 12:29",
                EndDate: "2022.03.02 13:02",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 12:29",
                BuyPoints: 656,
                SalePoints: 986,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1285.780000000002,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 27.03,
                StartDate: "2022.03.02 13:02",
                EndDate: "2022.03.02 18:10",
                EndLot: 2.56,
                LastTread: "Sale",
                BuyLot: 1.7,
                SaleLot: 3.41,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.03.02 17:31",
                BuyPoints: 1497,
                SalePoints: 663,
                MaxRisk: -102.5599999999999,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1286.410000000003,
                SafeAreayPoint: 60,
                Aversion: 63,
                LostTreads: -230.39,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.02 18:10",
                EndDate: "2022.03.02 18:28",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 18:10",
                BuyPoints: 572,
                SalePoints: 902,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1313.440000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.02 18:28",
                EndDate: "2022.03.02 18:34",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.02 18:28",
                BuyPoints: 932,
                SalePoints: 602,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1313.980000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.41,
                StartDate: "2022.03.02 18:34",
                EndDate: "2022.03.02 21:21",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.02 19:16",
                BuyPoints: 679,
                SalePoints: 1027,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1314.550000000003,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 39.37,
                StartDate: "2022.03.02 21:21",
                EndDate: "2022.03.03 13:32",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.03 12:00",
                BuyPoints: 781,
                SalePoints: 1489,
                MaxRisk: -44.40000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1315.960000000003,
                SafeAreayPoint: 64,
                Aversion: 54,
                LostTreads: -106.45,
            },
            {
                Profit: 0.58,
                StartDate: "2022.03.03 13:32",
                EndDate: "2022.03.03 14:37",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.03 13:32",
                BuyPoints: 936,
                SalePoints: 606,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1355.330000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 12.8,
                StartDate: "2022.03.03 14:37",
                EndDate: "2022.03.03 22:18",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.03 20:05",
                BuyPoints: 666,
                SalePoints: 1266,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1355.910000000003,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.33,
                StartDate: "2022.03.03 22:18",
                EndDate: "2022.03.04 02:44",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 02:24",
                BuyPoints: 677,
                SalePoints: 1007,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1368.710000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.67,
                StartDate: "2022.03.04 02:44",
                EndDate: "2022.03.04 03:03",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 02:44",
                BuyPoints: 1032,
                SalePoints: 702,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1369.040000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.43,
                StartDate: "2022.03.04 03:03",
                EndDate: "2022.03.04 03:52",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 03:24",
                BuyPoints: 1056,
                SalePoints: 726,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1369.710000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.04 03:52",
                EndDate: "2022.03.04 04:53",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 03:52",
                BuyPoints: 645,
                SalePoints: 975,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1370.140000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.16,
                StartDate: "2022.03.04 04:53",
                EndDate: "2022.03.04 13:04",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 11:07",
                BuyPoints: 592,
                SalePoints: 922,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1370.760000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.59,
                StartDate: "2022.03.04 13:04",
                EndDate: "2022.03.04 15:06",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 13:04",
                BuyPoints: 954,
                SalePoints: 624,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1370.920000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.04 15:06",
                EndDate: "2022.03.04 16:15",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 15:06",
                BuyPoints: 588,
                SalePoints: 918,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1371.510000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.42,
                StartDate: "2022.03.04 16:15",
                EndDate: "2022.03.04 16:38",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 16:29",
                BuyPoints: 722,
                SalePoints: 1052,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1372.070000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.04 16:38",
                EndDate: "2022.03.04 16:59",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 16:38",
                BuyPoints: 972,
                SalePoints: 642,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1372.490000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.11,
                StartDate: "2022.03.04 16:59",
                EndDate: "2022.03.04 17:47",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.04 17:10",
                BuyPoints: 618,
                SalePoints: 966,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1373.100000000003,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.46,
                StartDate: "2022.03.04 17:47",
                EndDate: "2022.03.04 21:41",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.04 18:09",
                BuyPoints: 740,
                SalePoints: 1070,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1374.210000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.14,
                StartDate: "2022.03.04 21:41",
                EndDate: "2022.03.07 01:01",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:01",
                BuyPoints: 578,
                SalePoints: 908,
                MaxRisk: 0,
                MaxProfit: 0.06999999999993634,
                StartedProfit: 0.07000000000000001,
                StartEqity: 1374.670000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.85,
                StartDate: "2022.03.07 01:01",
                EndDate: "2022.03.07 01:13",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:04",
                BuyPoints: 934,
                SalePoints: 1264,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1374.810000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.6,
                StartDate: "2022.03.07 01:13",
                EndDate: "2022.03.07 01:19",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:13",
                BuyPoints: 957,
                SalePoints: 627,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1375.660000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.07 01:19",
                EndDate: "2022.03.07 01:31",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 01:19",
                BuyPoints: 573,
                SalePoints: 903,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1376.260000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 4.17,
                StartDate: "2022.03.07 01:31",
                EndDate: "2022.03.07 03:44",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.07 03:38",
                BuyPoints: 603,
                SalePoints: 1113,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1376.800000000003,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.68,
                StartDate: "2022.03.07 03:44",
                EndDate: "2022.03.07 04:09",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 03:44",
                BuyPoints: 1042,
                SalePoints: 712,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1380.970000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.13,
                StartDate: "2022.03.07 04:09",
                EndDate: "2022.03.07 08:00",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 04:30",
                BuyPoints: 906,
                SalePoints: 576,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1381.650000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 3.85,
                StartDate: "2022.03.07 08:00",
                EndDate: "2022.03.07 09:54",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.07 09:30",
                BuyPoints: 652,
                SalePoints: 1090,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1381.780000000003,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 0.19,
                StartDate: "2022.03.07 09:54",
                EndDate: "2022.03.07 11:48",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 10:14",
                BuyPoints: 606,
                SalePoints: 936,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1385.630000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.84,
                StartDate: "2022.03.07 11:48",
                EndDate: "2022.03.07 13:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 11:48",
                BuyPoints: 1199,
                SalePoints: 869,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1385.820000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.47,
                StartDate: "2022.03.07 13:31",
                EndDate: "2022.03.07 15:13",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.07 14:00",
                BuyPoints: 1023,
                SalePoints: 639,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1386.660000000003,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.19,
                StartDate: "2022.03.07 15:13",
                EndDate: "2022.03.07 15:20",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:19",
                BuyPoints: 933,
                SalePoints: 603,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1388.130000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.46,
                StartDate: "2022.03.07 15:20",
                EndDate: "2022.03.07 15:41",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:32",
                BuyPoints: 1071,
                SalePoints: 741,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1388.320000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.07 15:41",
                EndDate: "2022.03.07 15:47",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:41",
                BuyPoints: 668,
                SalePoints: 998,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1388.780000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.63,
                StartDate: "2022.03.07 15:47",
                EndDate: "2022.03.07 15:56",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:52",
                BuyPoints: 823,
                SalePoints: 1153,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1389.420000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.07 15:56",
                EndDate: "2022.03.07 16:07",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 15:56",
                BuyPoints: 1002,
                SalePoints: 672,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1390.050000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 19.45,
                StartDate: "2022.03.07 16:07",
                EndDate: "2022.03.07 16:43",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.07 16:29",
                BuyPoints: 744,
                SalePoints: 1344,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1390.690000000003,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 2.73,
                StartDate: "2022.03.07 16:43",
                EndDate: "2022.03.07 18:22",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.07 17:56",
                BuyPoints: 1037,
                SalePoints: 599,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1410.140000000004,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.07 18:22",
                EndDate: "2022.03.07 19:02",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.07 18:22",
                BuyPoints: 642,
                SalePoints: 972,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1412.870000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.89,
                StartDate: "2022.03.07 19:02",
                EndDate: "2022.03.07 20:28",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.07 19:55",
                BuyPoints: 581,
                SalePoints: 965,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1413.480000000003,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 9.720000000000001,
                StartDate: "2022.03.07 20:28",
                EndDate: "2022.03.08 03:26",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.08 02:54",
                BuyPoints: 1230,
                SalePoints: 630,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1414.370000000003,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.09,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.08 03:26",
                EndDate: "2022.03.08 07:05",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 03:26",
                BuyPoints: 667,
                SalePoints: 997,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1424.090000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.11,
                StartDate: "2022.03.08 07:05",
                EndDate: "2022.03.08 08:33",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 07:59",
                BuyPoints: 1064,
                SalePoints: 1394,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1424.730000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.85,
                StartDate: "2022.03.08 08:33",
                EndDate: "2022.03.08 08:55",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 08:41",
                BuyPoints: 577,
                SalePoints: 961,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1425.840000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 6.31,
                StartDate: "2022.03.08 08:55",
                EndDate: "2022.03.08 10:17",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 10:13",
                BuyPoints: 1207,
                SalePoints: 769,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1426.690000000004,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 0.9,
                StartDate: "2022.03.08 10:17",
                EndDate: "2022.03.08 10:24",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 10:22",
                BuyPoints: 576,
                SalePoints: 924,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1433.000000000004,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.08 10:24",
                EndDate: "2022.03.08 10:32",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 10:24",
                BuyPoints: 979,
                SalePoints: 649,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1433.900000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.92,
                StartDate: "2022.03.08 10:32",
                EndDate: "2022.03.08 11:13",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 10:41",
                BuyPoints: 581,
                SalePoints: 929,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1434.520000000003,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.68,
                StartDate: "2022.03.08 11:13",
                EndDate: "2022.03.08 12:18",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 11:13",
                BuyPoints: 1039,
                SalePoints: 709,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1435.440000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.08 12:18",
                EndDate: "2022.03.08 13:37",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 12:18",
                BuyPoints: 594,
                SalePoints: 924,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1436.120000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.54,
                StartDate: "2022.03.08 13:37",
                EndDate: "2022.03.08 15:48",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.08 15:31",
                BuyPoints: 588,
                SalePoints: 1098,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1436.680000000003,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.08 15:48",
                EndDate: "2022.03.08 16:40",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 16:38",
                BuyPoints: 792,
                SalePoints: 1122,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1440.220000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.24,
                StartDate: "2022.03.08 16:40",
                EndDate: "2022.03.08 16:53",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 16:49",
                BuyPoints: 628,
                SalePoints: 958,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1440.780000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.67,
                StartDate: "2022.03.08 16:53",
                EndDate: "2022.03.08 17:06",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:05",
                BuyPoints: 843,
                SalePoints: 1173,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1441.020000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.26,
                StartDate: "2022.03.08 17:06",
                EndDate: "2022.03.08 17:07",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:07",
                BuyPoints: 638,
                SalePoints: 968,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1441.690000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.08 17:07",
                EndDate: "2022.03.08 17:09",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:07",
                BuyPoints: 1230,
                SalePoints: 900,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1441.950000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.14,
                StartDate: "2022.03.08 17:09",
                EndDate: "2022.03.08 17:13",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:09",
                BuyPoints: 909,
                SalePoints: 579,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1442.820000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.08 17:13",
                EndDate: "2022.03.08 17:18",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:13",
                BuyPoints: 685,
                SalePoints: 1015,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1442.960000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.08 17:18",
                EndDate: "2022.03.08 17:20",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:18",
                BuyPoints: 931,
                SalePoints: 601,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1443.620000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.74,
                StartDate: "2022.03.08 17:20",
                EndDate: "2022.03.08 17:24",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 17:20",
                BuyPoints: 766,
                SalePoints: 1096,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1444.190000000003,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.07,
                StartDate: "2022.03.08 17:24",
                EndDate: "2022.03.08 17:37",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 17:31",
                BuyPoints: 699,
                SalePoints: 1083,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1444.930000000003,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 5.53,
                StartDate: "2022.03.08 17:37",
                EndDate: "2022.03.08 17:50",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 17:47",
                BuyPoints: 1170,
                SalePoints: 732,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1447.000000000003,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 1.62,
                StartDate: "2022.03.08 17:50",
                EndDate: "2022.03.08 18:00",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 17:58",
                BuyPoints: 1039,
                SalePoints: 655,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1452.530000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.91,
                StartDate: "2022.03.08 18:00",
                EndDate: "2022.03.08 18:06",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 18:00",
                BuyPoints: 937,
                SalePoints: 1267,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1454.150000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3,
                StartDate: "2022.03.08 18:06",
                EndDate: "2022.03.08 18:55",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 18:55",
                BuyPoints: 792,
                SalePoints: 1176,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1455.060000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 4.73,
                StartDate: "2022.03.08 18:55",
                EndDate: "2022.03.08 19:11",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 19:02",
                BuyPoints: 1132,
                SalePoints: 694,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1458.060000000004,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.51,
                StartDate: "2022.03.08 19:11",
                EndDate: "2022.03.08 19:13",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:12",
                BuyPoints: 1094,
                SalePoints: 764,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1462.790000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 3.47,
                StartDate: "2022.03.08 19:13",
                EndDate: "2022.03.08 19:17",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 19:17",
                BuyPoints: 1223,
                SalePoints: 839,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1463.300000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 1.77,
                StartDate: "2022.03.08 19:17",
                EndDate: "2022.03.08 19:22",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:20",
                BuyPoints: 750,
                SalePoints: 1098,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1466.770000000004,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 2.88,
                StartDate: "2022.03.08 19:22",
                EndDate: "2022.03.08 19:31",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:22",
                BuyPoints: 1322,
                SalePoints: 974,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1468.540000000004,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.89,
                StartDate: "2022.03.08 19:31",
                EndDate: "2022.03.08 19:37",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 19:36",
                BuyPoints: 965,
                SalePoints: 581,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1471.420000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.59,
                StartDate: "2022.03.08 19:37",
                EndDate: "2022.03.08 19:38",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:37",
                BuyPoints: 617,
                SalePoints: 947,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1472.310000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.03,
                StartDate: "2022.03.08 19:38",
                EndDate: "2022.03.08 19:48",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 19:44",
                BuyPoints: 951,
                SalePoints: 603,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1472.900000000004,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.08 19:48",
                EndDate: "2022.03.08 19:51",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:48",
                BuyPoints: 600,
                SalePoints: 930,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1473.930000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.6,
                StartDate: "2022.03.08 19:51",
                EndDate: "2022.03.08 20:03",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 19:51",
                BuyPoints: 957,
                SalePoints: 627,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1474.500000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.08 20:03",
                EndDate: "2022.03.08 20:14",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:03",
                BuyPoints: 638,
                SalePoints: 968,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1475.100000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.42,
                StartDate: "2022.03.08 20:14",
                EndDate: "2022.03.08 20:31",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:22",
                BuyPoints: 718,
                SalePoints: 1048,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1475.710000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.18,
                StartDate: "2022.03.08 20:31",
                EndDate: "2022.03.08 20:41",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 20:31",
                BuyPoints: 599,
                SalePoints: 929,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1476.130000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.52,
                StartDate: "2022.03.08 20:41",
                EndDate: "2022.03.08 20:47",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.08 20:43",
                BuyPoints: 1050,
                SalePoints: 702,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1476.310000000004,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 2.09,
                StartDate: "2022.03.08 20:47",
                EndDate: "2022.03.08 21:43",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.08 21:18",
                BuyPoints: 1085,
                SalePoints: 701,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1477.830000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 3.15,
                StartDate: "2022.03.08 21:43",
                EndDate: "2022.03.08 21:59",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.08 21:51",
                BuyPoints: 619,
                SalePoints: 1057,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1479.920000000004,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.18,
                StartDate: "2022.03.08 21:59",
                EndDate: "2022.03.08 22:29",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.08 22:12",
                BuyPoints: 602,
                SalePoints: 932,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1483.070000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 2.07,
                StartDate: "2022.03.08 22:29",
                EndDate: "2022.03.09 02:30",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 01:21",
                BuyPoints: 1158,
                SalePoints: 810,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1483.250000000004,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 7.21,
                StartDate: "2022.03.09 02:30",
                EndDate: "2022.03.09 04:21",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.09 03:14",
                BuyPoints: 600,
                SalePoints: 1200,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1485.320000000004,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.92,
                StartDate: "2022.03.09 04:21",
                EndDate: "2022.03.09 05:45",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 04:42",
                BuyPoints: 585,
                SalePoints: 969,
                MaxRisk: -1.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1492.530000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.09 05:45",
                EndDate: "2022.03.09 07:51",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 05:45",
                BuyPoints: 969,
                SalePoints: 639,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1493.450000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.65,
                StartDate: "2022.03.09 07:51",
                EndDate: "2022.03.09 09:32",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 09:03",
                BuyPoints: 1141,
                SalePoints: 757,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1494.060000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.09 09:32",
                EndDate: "2022.03.09 09:59",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 09:32",
                BuyPoints: 579,
                SalePoints: 909,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1496.710000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.56,
                StartDate: "2022.03.09 09:59",
                EndDate: "2022.03.09 11:02",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.09 10:52",
                BuyPoints: 1029,
                SalePoints: 591,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1497.260000000004,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.53,
            },
            {
                Profit: 0.44,
                StartDate: "2022.03.09 11:02",
                EndDate: "2022.03.09 11:23",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 11:03",
                BuyPoints: 1061,
                SalePoints: 731,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1499.820000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 8.24,
                StartDate: "2022.03.09 11:23",
                EndDate: "2022.03.09 11:52",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.09 11:43",
                BuyPoints: 1210,
                SalePoints: 700,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1500.260000000004,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.09 11:52",
                EndDate: "2022.03.09 12:08",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 12:02",
                BuyPoints: 1170,
                SalePoints: 840,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1508.500000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.78,
                StartDate: "2022.03.09 12:08",
                EndDate: "2022.03.09 12:16",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 12:08",
                BuyPoints: 810,
                SalePoints: 1140,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1509.160000000004,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 12.56,
                StartDate: "2022.03.09 12:16",
                EndDate: "2022.03.09 15:01",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.09 14:55",
                BuyPoints: 1263,
                SalePoints: 663,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1509.940000000004,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 5.47,
                StartDate: "2022.03.09 15:01",
                EndDate: "2022.03.09 15:26",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.09 15:20",
                BuyPoints: 1424,
                SalePoints: 1040,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1522.500000000004,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.09 15:26",
                EndDate: "2022.03.09 15:27",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:26",
                BuyPoints: 579,
                SalePoints: 909,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1527.970000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.09 15:27",
                EndDate: "2022.03.09 15:51",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 15:27",
                BuyPoints: 1014,
                SalePoints: 684,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1528.520000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 12.94,
                StartDate: "2022.03.09 15:51",
                EndDate: "2022.03.09 16:08",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.09 16:06",
                BuyPoints: 1322,
                SalePoints: 812,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1529.170000000005,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.73,
                StartDate: "2022.03.09 16:08",
                EndDate: "2022.03.09 16:13",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:08",
                BuyPoints: 763,
                SalePoints: 1093,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1542.110000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.26,
                StartDate: "2022.03.09 16:13",
                EndDate: "2022.03.09 16:17",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:14",
                BuyPoints: 641,
                SalePoints: 971,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1542.840000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.09 16:17",
                EndDate: "2022.03.09 16:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 16:17",
                BuyPoints: 983,
                SalePoints: 653,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1543.100000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 9.25,
                StartDate: "2022.03.09 16:31",
                EndDate: "2022.03.09 17:17",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.09 16:52",
                BuyPoints: 624,
                SalePoints: 1224,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1543.720000000005,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.95,
                StartDate: "2022.03.09 17:17",
                EndDate: "2022.03.09 19:25",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.09 19:11",
                BuyPoints: 934,
                SalePoints: 586,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1552.970000000005,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.18,
                StartDate: "2022.03.09 19:25",
                EndDate: "2022.03.09 21:08",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 19:34",
                BuyPoints: 932,
                SalePoints: 602,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1553.920000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.8100000000000001,
                StartDate: "2022.03.09 21:08",
                EndDate: "2022.03.09 21:50",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.09 21:08",
                BuyPoints: 836,
                SalePoints: 1166,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1554.100000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 19.49,
                StartDate: "2022.03.09 21:50",
                EndDate: "2022.03.10 03:00",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 02:10",
                BuyPoints: 1345,
                SalePoints: 745,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1554.910000000005,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.09,
            },
            {
                Profit: 1.85,
                StartDate: "2022.03.10 03:00",
                EndDate: "2022.03.10 08:33",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 07:32",
                BuyPoints: 767,
                SalePoints: 1115,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1574.400000000005,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.73,
                StartDate: "2022.03.10 08:33",
                EndDate: "2022.03.10 09:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 08:33",
                BuyPoints: 1092,
                SalePoints: 762,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1576.250000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.28,
                StartDate: "2022.03.10 09:00",
                EndDate: "2022.03.10 10:43",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.10 10:03",
                BuyPoints: 625,
                SalePoints: 1063,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1576.980000000005,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.88,
                StartDate: "2022.03.10 10:43",
                EndDate: "2022.03.10 11:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 10:43",
                BuyPoints: 1243,
                SalePoints: 913,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1580.260000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.77,
                StartDate: "2022.03.10 11:00",
                EndDate: "2022.03.10 11:55",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 11:00",
                BuyPoints: 797,
                SalePoints: 1127,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1581.140000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.92,
                StartDate: "2022.03.10 11:55",
                EndDate: "2022.03.10 12:13",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 12:10",
                BuyPoints: 972,
                SalePoints: 1302,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1581.910000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.10 12:13",
                EndDate: "2022.03.10 12:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 12:13",
                BuyPoints: 924,
                SalePoints: 594,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1582.830000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.72,
                StartDate: "2022.03.10 12:25",
                EndDate: "2022.03.10 13:02",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 12:25",
                BuyPoints: 753,
                SalePoints: 1083,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1583.390000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 18,
                StartDate: "2022.03.10 13:02",
                EndDate: "2022.03.10 15:51",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 15:50",
                BuyPoints: 1327,
                SalePoints: 727,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1584.110000000005,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.10 15:51",
                EndDate: "2022.03.10 15:55",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 15:51",
                BuyPoints: 598,
                SalePoints: 928,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1602.110000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.28,
                StartDate: "2022.03.10 15:55",
                EndDate: "2022.03.10 17:11",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 16:57",
                BuyPoints: 1001,
                SalePoints: 653,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1602.680000000005,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.91,
                StartDate: "2022.03.10 17:11",
                EndDate: "2022.03.10 18:03",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.10 17:21",
                BuyPoints: 578,
                SalePoints: 926,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1603.960000000005,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.10 18:03",
                EndDate: "2022.03.10 18:35",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 18:03",
                BuyPoints: 919,
                SalePoints: 589,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1604.870000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.10 18:35",
                EndDate: "2022.03.10 19:04",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.10 18:35",
                BuyPoints: 584,
                SalePoints: 914,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1605.430000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 16.73,
                StartDate: "2022.03.10 19:04",
                EndDate: "2022.03.11 03:12",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.10 22:55",
                BuyPoints: 1312,
                SalePoints: 712,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1605.980000000005,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.64,
                StartDate: "2022.03.11 03:12",
                EndDate: "2022.03.11 04:38",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 03:12",
                BuyPoints: 668,
                SalePoints: 998,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1622.710000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.11 04:38",
                EndDate: "2022.03.11 06:24",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 04:38",
                BuyPoints: 920,
                SalePoints: 590,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1623.350000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.39,
                StartDate: "2022.03.11 06:24",
                EndDate: "2022.03.11 13:25",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.11 13:23",
                BuyPoints: 1215,
                SalePoints: 831,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1623.910000000005,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.54,
                StartDate: "2022.03.11 13:25",
                EndDate: "2022.03.11 13:27",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 13:25",
                BuyPoints: 570,
                SalePoints: 900,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1627.300000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.11 13:27",
                EndDate: "2022.03.11 13:40",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 13:27",
                BuyPoints: 920,
                SalePoints: 590,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1627.840000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.32,
                StartDate: "2022.03.11 13:40",
                EndDate: "2022.03.11 13:52",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 13:48",
                BuyPoints: 662,
                SalePoints: 1010,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1628.400000000005,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.11 13:52",
                EndDate: "2022.03.11 14:04",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 13:52",
                BuyPoints: 911,
                SalePoints: 581,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1629.720000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.29,
                StartDate: "2022.03.11 14:04",
                EndDate: "2022.03.11 14:14",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 14:05",
                BuyPoints: 986,
                SalePoints: 656,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1630.270000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 13.16,
                StartDate: "2022.03.11 14:14",
                EndDate: "2022.03.11 15:24",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.11 14:55",
                BuyPoints: 670,
                SalePoints: 1270,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1630.560000000005,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.7,
                StartDate: "2022.03.11 15:24",
                EndDate: "2022.03.11 15:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 15:24",
                BuyPoints: 1058,
                SalePoints: 728,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1643.720000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.31,
                StartDate: "2022.03.11 15:31",
                EndDate: "2022.03.11 15:46",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 15:33",
                BuyPoints: 995,
                SalePoints: 665,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1644.420000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.11 15:46",
                EndDate: "2022.03.11 16:07",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 15:46",
                BuyPoints: 903,
                SalePoints: 1233,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1644.730000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.14,
                StartDate: "2022.03.11 16:07",
                EndDate: "2022.03.11 17:00",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 16:36",
                BuyPoints: 579,
                SalePoints: 909,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1645.600000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.26,
                StartDate: "2022.03.11 17:00",
                EndDate: "2022.03.11 17:28",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.11 17:22",
                BuyPoints: 996,
                SalePoints: 648,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1645.740000000005,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.11 17:28",
                EndDate: "2022.03.11 17:35",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 17:28",
                BuyPoints: 593,
                SalePoints: 923,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1647.000000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.11 17:35",
                EndDate: "2022.03.11 18:42",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 17:58",
                BuyPoints: 792,
                SalePoints: 1122,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1647.560000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.6,
                StartDate: "2022.03.11 18:42",
                EndDate: "2022.03.11 20:21",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.11 18:42",
                BuyPoints: 956,
                SalePoints: 626,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1648.120000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.12,
                StartDate: "2022.03.11 20:21",
                EndDate: "2022.03.14 02:04",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 01:02",
                BuyPoints: 899,
                SalePoints: 569,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1648.720000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.92,
                StartDate: "2022.03.14 02:04",
                EndDate: "2022.03.14 11:37",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.14 10:45",
                BuyPoints: 1069,
                SalePoints: 685,
                MaxRisk: -1.819999999999936,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1648.840000000005,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 25.94,
                StartDate: "2022.03.14 11:37",
                EndDate: "2022.03.14 15:55",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.14 15:45",
                BuyPoints: 1410,
                SalePoints: 702,
                MaxRisk: -44.40000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1650.760000000005,
                SafeAreayPoint: 64,
                Aversion: 54,
                LostTreads: -106.45,
            },
            {
                Profit: 0.71,
                StartDate: "2022.03.14 15:55",
                EndDate: "2022.03.14 15:58",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.14 15:55",
                BuyPoints: 742,
                SalePoints: 1072,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1676.700000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.1,
                StartDate: "2022.03.14 15:58",
                EndDate: "2022.03.14 22:59",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.14 19:31",
                BuyPoints: 1055,
                SalePoints: 617,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1677.410000000005,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 1,
                StartDate: "2022.03.14 22:59",
                EndDate: "2022.03.15 03:46",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.15 02:52",
                BuyPoints: 976,
                SalePoints: 592,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1680.510000000005,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.52,
                StartDate: "2022.03.15 03:46",
                EndDate: "2022.03.15 08:24",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 06:54",
                BuyPoints: 1102,
                SalePoints: 772,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1681.510000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 52.29,
                StartDate: "2022.03.15 08:24",
                EndDate: "2022.03.15 16:10",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.03.15 13:56",
                BuyPoints: 1565,
                SalePoints: 857,
                MaxRisk: -44.40000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1682.030000000006,
                SafeAreayPoint: 64,
                Aversion: 54,
                LostTreads: -106.45,
            },
            {
                Profit: 1.32,
                StartDate: "2022.03.15 16:10",
                EndDate: "2022.03.15 16:39",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.15 16:22",
                BuyPoints: 662,
                SalePoints: 1010,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1734.320000000005,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.59,
                StartDate: "2022.03.15 16:39",
                EndDate: "2022.03.15 17:05",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 16:39",
                BuyPoints: 946,
                SalePoints: 616,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1735.640000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.19,
                StartDate: "2022.03.15 17:05",
                EndDate: "2022.03.15 17:34",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 17:12",
                BuyPoints: 934,
                SalePoints: 604,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1736.230000000005,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.67,
                StartDate: "2022.03.15 17:34",
                EndDate: "2022.03.15 17:49",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.15 17:34",
                BuyPoints: 704,
                SalePoints: 1034,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1736.420000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.07,
                StartDate: "2022.03.15 17:49",
                EndDate: "2022.03.15 18:55",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.15 18:22",
                BuyPoints: 700,
                SalePoints: 1084,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1737.090000000006,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 1.92,
                StartDate: "2022.03.15 18:55",
                EndDate: "2022.03.15 22:31",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.15 20:44",
                BuyPoints: 1128,
                SalePoints: 780,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1739.160000000006,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 1.1,
                StartDate: "2022.03.15 22:31",
                EndDate: "2022.03.16 04:18",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.15 23:51",
                BuyPoints: 616,
                SalePoints: 964,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1741.080000000006,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.16 04:18",
                EndDate: "2022.03.16 07:11",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 04:18",
                BuyPoints: 1015,
                SalePoints: 685,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1742.180000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 13.58,
                StartDate: "2022.03.16 07:11",
                EndDate: "2022.03.16 14:42",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.16 11:42",
                BuyPoints: 675,
                SalePoints: 1275,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1742.840000000006,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.16 14:42",
                EndDate: "2022.03.16 15:23",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 14:42",
                BuyPoints: 1007,
                SalePoints: 677,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1756.420000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.05,
                StartDate: "2022.03.16 15:23",
                EndDate: "2022.03.16 17:15",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:11",
                BuyPoints: 1367,
                SalePoints: 1037,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1757.070000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.16 17:15",
                EndDate: "2022.03.16 17:26",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 17:15",
                BuyPoints: 598,
                SalePoints: 928,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1758.120000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 40.06,
                StartDate: "2022.03.16 17:26",
                EndDate: "2022.03.16 21:17",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.16 21:13",
                BuyPoints: 1587,
                SalePoints: 987,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1758.690000000006,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.09,
            },
            {
                Profit: 1.71,
                StartDate: "2022.03.16 21:17",
                EndDate: "2022.03.16 21:40",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.16 21:21",
                BuyPoints: 738,
                SalePoints: 1086,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1798.750000000006,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.39,
                StartDate: "2022.03.16 21:40",
                EndDate: "2022.03.16 21:52",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 21:45",
                BuyPoints: 703,
                SalePoints: 1033,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1800.460000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.14,
                StartDate: "2022.03.16 21:52",
                EndDate: "2022.03.16 22:51",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.16 22:18",
                BuyPoints: 579,
                SalePoints: 909,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1800.850000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.02,
                StartDate: "2022.03.16 22:51",
                EndDate: "2022.03.17 06:13",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.17 04:26",
                BuyPoints: 594,
                SalePoints: 978,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1800.990000000006,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 1.62,
                StartDate: "2022.03.17 06:13",
                EndDate: "2022.03.17 13:01",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.17 12:34",
                BuyPoints: 654,
                SalePoints: 1038,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1802.010000000006,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.17 13:01",
                EndDate: "2022.03.17 15:21",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.17 13:01",
                BuyPoints: 1021,
                SalePoints: 691,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1803.630000000006,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.35,
                StartDate: "2022.03.17 15:21",
                EndDate: "2022.03.17 16:58",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.17 15:24",
                BuyPoints: 667,
                SalePoints: 1015,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1804.290000000006,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 3.1,
                StartDate: "2022.03.17 16:58",
                EndDate: "2022.03.17 21:12",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.17 19:03",
                BuyPoints: 1054,
                SalePoints: 616,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1805.640000000006,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 3.02,
                StartDate: "2022.03.17 21:12",
                EndDate: "2022.03.18 06:35",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.18 05:17",
                BuyPoints: 1178,
                SalePoints: 794,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1808.740000000007,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.66,
                StartDate: "2022.03.18 06:35",
                EndDate: "2022.03.18 06:51",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.18 06:35",
                BuyPoints: 692,
                SalePoints: 1022,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1811.760000000007,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.58,
                StartDate: "2022.03.18 06:51",
                EndDate: "2022.03.18 16:24",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.18 16:01",
                BuyPoints: 1029,
                SalePoints: 591,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1812.420000000007,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 1.32,
                StartDate: "2022.03.18 16:24",
                EndDate: "2022.03.18 16:33",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.18 16:29",
                BuyPoints: 660,
                SalePoints: 1008,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1815.000000000007,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 1.51,
                StartDate: "2022.03.18 16:33",
                EndDate: "2022.03.18 19:35",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.18 18:00",
                BuyPoints: 1047,
                SalePoints: 699,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1816.320000000007,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 8.130000000000001,
                StartDate: "2022.03.18 19:35",
                EndDate: "2022.03.21 17:15",
                EndLot: 0.64,
                LastTread: "Buy",
                BuyLot: 0.8500000000000001,
                SaleLot: 0.42,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.03.21 16:49",
                BuyPoints: 611,
                SalePoints: 1211,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1817.830000000007,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.21 17:15",
                EndDate: "2022.03.21 20:08",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 17:15",
                BuyPoints: 971,
                SalePoints: 641,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1825.960000000007,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.13,
                StartDate: "2022.03.21 20:08",
                EndDate: "2022.03.21 21:57",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 20:08",
                BuyPoints: 1163,
                SalePoints: 1493,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1826.570000000007,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.57,
                StartDate: "2022.03.21 21:57",
                EndDate: "2022.03.21 22:07",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.21 21:57",
                BuyPoints: 933,
                SalePoints: 603,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1827.700000000007,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.91,
                StartDate: "2022.03.21 22:07",
                EndDate: "2022.03.22 11:56",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.22 11:02",
                BuyPoints: 967,
                SalePoints: 583,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1828.270000000007,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 1.94,
                StartDate: "2022.03.22 11:56",
                EndDate: "2022.03.22 17:05",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.22 16:44",
                BuyPoints: 1070,
                SalePoints: 686,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1829.180000000007,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 3.52,
                StartDate: "2022.03.22 17:05",
                EndDate: "2022.03.22 19:22",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.22 18:39",
                BuyPoints: 637,
                SalePoints: 1075,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1831.120000000007,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 1.07,
                StartDate: "2022.03.22 19:22",
                EndDate: "2022.03.23 13:07",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.23 11:44",
                BuyPoints: 599,
                SalePoints: 983,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1834.640000000007,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 4.88,
                StartDate: "2022.03.23 13:07",
                EndDate: "2022.03.23 22:24",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.23 19:46",
                BuyPoints: 620,
                SalePoints: 1130,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1835.710000000008,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 9.92,
                StartDate: "2022.03.23 22:24",
                EndDate: "2022.03.24 14:40",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.24 13:19",
                BuyPoints: 740,
                SalePoints: 1250,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1840.590000000008,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 1.48,
                StartDate: "2022.03.24 14:40",
                EndDate: "2022.03.24 15:25",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.24 15:16",
                BuyPoints: 1042,
                SalePoints: 694,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1850.510000000008,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 1.45,
                StartDate: "2022.03.24 15:25",
                EndDate: "2022.03.24 16:27",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.24 15:30",
                BuyPoints: 686,
                SalePoints: 1034,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1851.990000000008,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 1.07,
                StartDate: "2022.03.24 16:27",
                EndDate: "2022.03.24 17:30",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.24 17:14",
                BuyPoints: 600,
                SalePoints: 984,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1853.440000000008,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 2.79,
                StartDate: "2022.03.24 17:30",
                EndDate: "2022.03.25 10:05",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.24 19:37",
                BuyPoints: 1040,
                SalePoints: 602,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1854.510000000008,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 4.08,
                StartDate: "2022.03.25 10:05",
                EndDate: "2022.03.25 16:34",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.25 16:11",
                BuyPoints: 1111,
                SalePoints: 601,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1857.300000000008,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.25 16:34",
                EndDate: "2022.03.25 17:06",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.25 16:34",
                BuyPoints: 585,
                SalePoints: 915,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1861.380000000008,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.12,
                StartDate: "2022.03.25 17:06",
                EndDate: "2022.03.25 18:31",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.25 18:03",
                BuyPoints: 568,
                SalePoints: 898,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1861.940000000008,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.74,
                StartDate: "2022.03.25 18:31",
                EndDate: "2022.03.25 20:33",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.25 18:31",
                BuyPoints: 1098,
                SalePoints: 768,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1862.060000000008,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.26,
                StartDate: "2022.03.25 20:33",
                EndDate: "2022.03.28 06:56",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 04:24",
                BuyPoints: 969,
                SalePoints: 639,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1862.800000000008,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.16,
                StartDate: "2022.03.28 06:56",
                EndDate: "2022.03.28 08:54",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 08:30",
                BuyPoints: 921,
                SalePoints: 591,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1863.060000000008,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.71,
                StartDate: "2022.03.28 08:54",
                EndDate: "2022.03.28 15:16",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.28 15:12",
                BuyPoints: 738,
                SalePoints: 1086,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1863.220000000008,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 5.44,
                StartDate: "2022.03.28 15:16",
                EndDate: "2022.03.28 16:21",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.03.28 15:54",
                BuyPoints: 1166,
                SalePoints: 728,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1864.930000000008,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 2.03,
                StartDate: "2022.03.28 16:21",
                EndDate: "2022.03.28 17:20",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.28 16:35",
                BuyPoints: 803,
                SalePoints: 1151,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1870.370000000009,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.28 17:20",
                EndDate: "2022.03.28 17:28",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 17:20",
                BuyPoints: 914,
                SalePoints: 584,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1872.400000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.91,
                StartDate: "2022.03.28 17:28",
                EndDate: "2022.03.28 22:05",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.28 21:06",
                BuyPoints: 1295,
                SalePoints: 965,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1872.950000000008,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 3.49,
                StartDate: "2022.03.28 22:05",
                EndDate: "2022.03.29 11:40",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 10:32",
                BuyPoints: 1225,
                SalePoints: 841,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1873.860000000009,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.15,
                StartDate: "2022.03.29 11:40",
                EndDate: "2022.03.29 14:50",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 14:46",
                BuyPoints: 914,
                SalePoints: 584,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1877.350000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.65,
                StartDate: "2022.03.29 14:50",
                EndDate: "2022.03.29 14:52",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 14:50",
                BuyPoints: 676,
                SalePoints: 1006,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1877.500000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.22,
                StartDate: "2022.03.29 14:52",
                EndDate: "2022.03.29 15:20",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.29 14:59",
                BuyPoints: 988,
                SalePoints: 640,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1878.150000000009,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.4,
                StartDate: "2022.03.29 15:20",
                EndDate: "2022.03.29 15:34",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 15:23",
                BuyPoints: 1039,
                SalePoints: 709,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1879.370000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.03.29 15:34",
                EndDate: "2022.03.29 16:18",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 15:34",
                BuyPoints: 587,
                SalePoints: 917,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1879.770000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.21,
                StartDate: "2022.03.29 16:18",
                EndDate: "2022.03.29 16:55",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.29 16:48",
                BuyPoints: 615,
                SalePoints: 945,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1880.330000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 1.39,
                StartDate: "2022.03.29 16:55",
                EndDate: "2022.03.29 19:39",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.29 18:00",
                BuyPoints: 631,
                SalePoints: 1015,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1880.540000000009,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 1.11,
                StartDate: "2022.03.29 19:39",
                EndDate: "2022.03.30 07:51",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.30 04:01",
                BuyPoints: 603,
                SalePoints: 987,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1881.930000000009,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.59,
                StartDate: "2022.03.30 07:51",
                EndDate: "2022.03.30 11:22",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 07:51",
                BuyPoints: 945,
                SalePoints: 615,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1883.040000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.87,
                StartDate: "2022.03.30 11:22",
                EndDate: "2022.03.30 15:13",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.03.30 13:00",
                BuyPoints: 571,
                SalePoints: 919,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1883.630000000009,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 1.05,
                StartDate: "2022.03.30 15:13",
                EndDate: "2022.03.30 17:35",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.30 16:34",
                BuyPoints: 597,
                SalePoints: 981,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1884.500000000009,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.62,
                StartDate: "2022.03.30 17:35",
                EndDate: "2022.03.31 03:43",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.30 17:35",
                BuyPoints: 978,
                SalePoints: 648,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1885.550000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.15,
                StartDate: "2022.03.31 03:43",
                EndDate: "2022.03.31 08:31",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 05:00",
                BuyPoints: 917,
                SalePoints: 587,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1886.170000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.55,
                StartDate: "2022.03.31 08:31",
                EndDate: "2022.03.31 09:43",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 08:31",
                BuyPoints: 577,
                SalePoints: 907,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1886.320000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.79,
                StartDate: "2022.03.31 09:43",
                EndDate: "2022.03.31 16:13",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.03.31 13:49",
                BuyPoints: 871,
                SalePoints: 1255,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1886.870000000009,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 7.53,
                StartDate: "2022.03.31 16:13",
                EndDate: "2022.03.31 20:29",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.03.31 17:26",
                BuyPoints: 683,
                SalePoints: 1193,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1890.660000000009,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.61,
                StartDate: "2022.03.31 20:29",
                EndDate: "2022.03.31 21:34",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 20:29",
                BuyPoints: 968,
                SalePoints: 638,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1898.190000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.19,
                StartDate: "2022.03.31 21:34",
                EndDate: "2022.04.01 03:30",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.03.31 22:34",
                BuyPoints: 934,
                SalePoints: 604,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1898.800000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 15.23,
                StartDate: "2022.04.01 03:30",
                EndDate: "2022.04.01 17:31",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.01 15:53",
                BuyPoints: 1347,
                SalePoints: 639,
                MaxRisk: -44.30999999999995,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1898.990000000009,
                SafeAreayPoint: 64,
                Aversion: 54,
                LostTreads: -106.45,
            },
            {
                Profit: 1.31,
                StartDate: "2022.04.01 17:31",
                EndDate: "2022.04.01 18:19",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.01 17:35",
                BuyPoints: 659,
                SalePoints: 1007,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1914.220000000009,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.59,
                StartDate: "2022.04.01 18:19",
                EndDate: "2022.04.01 20:01",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.01 18:19",
                BuyPoints: 949,
                SalePoints: 619,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1915.530000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.05,
                StartDate: "2022.04.01 20:01",
                EndDate: "2022.04.04 10:05",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.04 09:23",
                BuyPoints: 614,
                SalePoints: 1052,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1916.120000000009,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 17.24,
                StartDate: "2022.04.04 10:05",
                EndDate: "2022.04.05 16:33",
                EndLot: 5.12,
                LastTread: "Buy",
                BuyLot: 6.82,
                SaleLot: 3.41,
                LotDifference: 3.41,
                LastLotSartedDate: "2022.04.05 15:46",
                BuyPoints: 656,
                SalePoints: 1634,
                MaxRisk: -239.51,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1919.170000000009,
                SafeAreayPoint: 55,
                Aversion: 72,
                LostTreads: -503.02,
            },
            {
                Profit: 0.6899999999999999,
                StartDate: "2022.04.05 16:33",
                EndDate: "2022.04.05 17:12",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.05 16:33",
                BuyPoints: 1045,
                SalePoints: 715,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1936.410000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 6.76,
                StartDate: "2022.04.05 17:12",
                EndDate: "2022.04.05 18:59",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.05 17:21",
                BuyPoints: 1552,
                SalePoints: 1168,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1937.100000000009,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 3.46,
                StartDate: "2022.04.05 18:59",
                EndDate: "2022.04.06 12:03",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.06 10:33",
                BuyPoints: 633,
                SalePoints: 1071,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1943.860000000009,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: 1.36,
                StartDate: "2022.04.06 12:03",
                EndDate: "2022.04.06 14:11",
                EndLot: 0.04,
                LastTread: "Sale",
                BuyLot: 0.02,
                SaleLot: 0.05,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.06 13:30",
                BuyPoints: 1017,
                SalePoints: 669,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1947.320000000009,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.58,
                StartDate: "2022.04.06 14:11",
                EndDate: "2022.04.06 15:25",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 14:11",
                BuyPoints: 609,
                SalePoints: 939,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1948.68000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 9.25,
                StartDate: "2022.04.06 15:25",
                EndDate: "2022.04.06 21:11",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.04.06 19:17",
                BuyPoints: 1224,
                SalePoints: 624,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1949.26000000001,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 0.65,
                StartDate: "2022.04.06 21:11",
                EndDate: "2022.04.06 21:35",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 21:11",
                BuyPoints: 683,
                SalePoints: 1013,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1958.51000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.57,
                StartDate: "2022.04.06 21:35",
                EndDate: "2022.04.06 21:41",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 21:35",
                BuyPoints: 933,
                SalePoints: 603,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1959.16000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.06 21:41",
                EndDate: "2022.04.06 21:58",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.06 21:41",
                BuyPoints: 589,
                SalePoints: 919,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1959.73000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.83,
                StartDate: "2022.04.06 21:58",
                EndDate: "2022.04.07 17:36",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.07 14:19",
                BuyPoints: 595,
                SalePoints: 1105,
                MaxRisk: -8.620000000000118,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1960.29000000001,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.62,
                StartDate: "2022.04.07 17:36",
                EndDate: "2022.04.08 08:01",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.07 17:36",
                BuyPoints: 984,
                SalePoints: 654,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1964.12000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.08 08:01",
                EndDate: "2022.04.08 15:08",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.08 08:01",
                BuyPoints: 586,
                SalePoints: 916,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1964.74000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 5.17,
                StartDate: "2022.04.08 15:08",
                EndDate: "2022.04.08 17:09",
                EndLot: 0.32,
                LastTread: "Buy",
                BuyLot: 0.42,
                SaleLot: 0.21,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.08 16:33",
                BuyPoints: 627,
                SalePoints: 1137,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1965.30000000001,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 17.95,
                StartDate: "2022.04.08 17:09",
                EndDate: "2022.04.11 11:44",
                EndLot: 1.28,
                LastTread: "Buy",
                BuyLot: 1.7,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.11 11:17",
                BuyPoints: 655,
                SalePoints: 1363,
                MaxRisk: -44.40000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1970.47000000001,
                SafeAreayPoint: 64,
                Aversion: 54,
                LostTreads: -106.45,
            },
            {
                Profit: 0.77,
                StartDate: "2022.04.11 11:44",
                EndDate: "2022.04.11 15:39",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 14:39",
                BuyPoints: 893,
                SalePoints: 1223,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1988.42000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.11 15:39",
                EndDate: "2022.04.11 16:00",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 15:39",
                BuyPoints: 911,
                SalePoints: 581,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1989.19000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 5.47,
                StartDate: "2022.04.11 16:00",
                EndDate: "2022.04.11 16:55",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.11 16:47",
                BuyPoints: 1144,
                SalePoints: 634,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1989.74000000001,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 4.08,
                StartDate: "2022.04.11 16:55",
                EndDate: "2022.04.11 19:57",
                EndLot: 0.32,
                LastTread: "Sale",
                BuyLot: 0.21,
                SaleLot: 0.42,
                LotDifference: 0.21,
                LastLotSartedDate: "2022.04.11 19:19",
                BuyPoints: 1111,
                SalePoints: 601,
                MaxRisk: -8.660000000000082,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1995.21000000001,
                SafeAreayPoint: 73,
                Aversion: 36,
                LostTreads: -23.14,
            },
            {
                Profit: 0.59,
                StartDate: "2022.04.11 19:57",
                EndDate: "2022.04.11 21:24",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 19:57",
                BuyPoints: 623,
                SalePoints: 953,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1999.29000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.27,
                StartDate: "2022.04.11 21:24",
                EndDate: "2022.04.12 06:40",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.11 22:03",
                BuyPoints: 647,
                SalePoints: 977,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 1999.88000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.57,
                StartDate: "2022.04.12 06:40",
                EndDate: "2022.04.12 11:25",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 06:40",
                BuyPoints: 925,
                SalePoints: 595,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2000.15000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.76,
                StartDate: "2022.04.12 11:25",
                EndDate: "2022.04.12 15:14",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 11:25",
                BuyPoints: 785,
                SalePoints: 1115,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2000.72000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.45,
                StartDate: "2022.04.12 15:14",
                EndDate: "2022.04.12 15:54",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 15:33",
                BuyPoints: 737,
                SalePoints: 1067,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2001.48000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.6,
                StartDate: "2022.04.12 15:54",
                EndDate: "2022.04.12 16:49",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.12 15:54",
                BuyPoints: 962,
                SalePoints: 632,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2001.93000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.25,
                StartDate: "2022.04.12 16:49",
                EndDate: "2022.04.12 17:44",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.12 17:17",
                BuyPoints: 647,
                SalePoints: 995,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2002.53000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 3.71,
                StartDate: "2022.04.12 17:44",
                EndDate: "2022.04.13 03:29",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.12 20:10",
                BuyPoints: 1084,
                SalePoints: 646,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2003.78000000001,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.53,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.13 03:29",
                EndDate: "2022.04.13 05:22",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 03:29",
                BuyPoints: 593,
                SalePoints: 923,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2007.49000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.87,
                StartDate: "2022.04.13 05:22",
                EndDate: "2022.04.13 13:40",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.13 11:16",
                BuyPoints: 579,
                SalePoints: 963,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2008.05000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.59,
                StartDate: "2022.04.13 13:40",
                EndDate: "2022.04.13 15:26",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 13:40",
                BuyPoints: 952,
                SalePoints: 622,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2008.92000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.13 15:26",
                EndDate: "2022.04.13 16:34",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 15:26",
                BuyPoints: 591,
                SalePoints: 921,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2009.51000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.13 16:34",
                EndDate: "2022.04.14 08:26",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.13 16:34",
                BuyPoints: 924,
                SalePoints: 594,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2010.07000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.89,
                StartDate: "2022.04.14 08:26",
                EndDate: "2022.04.14 15:11",
                EndLot: 0.04,
                LastTread: "Buy",
                BuyLot: 0.05,
                SaleLot: 0.02,
                LotDifference: 0.03,
                LastLotSartedDate: "2022.04.14 13:53",
                BuyPoints: 575,
                SalePoints: 923,
                MaxRisk: -0.75,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2010.63000000001,
                SafeAreayPoint: 86,
                Aversion: 9,
                LostTreads: -2,
            },
            {
                Profit: 0.55,
                StartDate: "2022.04.14 15:11",
                EndDate: "2022.04.14 15:31",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.14 15:11",
                BuyPoints: 912,
                SalePoints: 582,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2011.52000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 3.44,
                StartDate: "2022.04.14 15:31",
                EndDate: "2022.04.14 16:59",
                EndLot: 0.08,
                LastTread: "Sale",
                BuyLot: 0.05,
                SaleLot: 0.1,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.14 16:45",
                BuyPoints: 1220,
                SalePoints: 836,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2012.07000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.62,
                StartDate: "2022.04.14 16:59",
                EndDate: "2022.04.14 17:22",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.14 16:59",
                BuyPoints: 647,
                SalePoints: 977,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2015.51000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.92,
                StartDate: "2022.04.14 17:22",
                EndDate: "2022.04.18 01:51",
                EndLot: 0.08,
                LastTread: "Buy",
                BuyLot: 0.1,
                SaleLot: 0.05,
                LotDifference: 0.05,
                LastLotSartedDate: "2022.04.14 19:35",
                BuyPoints: 785,
                SalePoints: 1169,
                MaxRisk: -1.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2016.13000000001,
                SafeAreayPoint: 82,
                Aversion: 18,
                LostTreads: -5.12,
            },
            {
                Profit: 0.27,
                StartDate: "2022.04.18 01:51",
                EndDate: "2022.04.18 09:32",
                EndLot: 0.02,
                LastTread: "Buy",
                BuyLot: 0.02,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.18 02:33",
                BuyPoints: 647,
                SalePoints: 977,
                MaxRisk: -0.3600000000001273,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2019.05000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 14.26,
                StartDate: "2022.04.18 09:32",
                EndDate: "2022.04.18 20:36",
                EndLot: 0.64,
                LastTread: "Sale",
                BuyLot: 0.42,
                SaleLot: 0.8500000000000001,
                LotDifference: 0.43,
                LastLotSartedDate: "2022.04.18 17:36",
                BuyPoints: 1283,
                SalePoints: 683,
                MaxRisk: -19.26999999999998,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2019.32000000001,
                SafeAreayPoint: 69,
                Aversion: 45,
                LostTreads: -49.05,
            },
            {
                Profit: 25.26,
                StartDate: "2022.04.18 20:36",
                EndDate: "2022.04.19 15:45",
                EndLot: 1.28,
                LastTread: "Sale",
                BuyLot: 0.8500000000000001,
                SaleLot: 1.7,
                LotDifference: 0.8500000000000001,
                LastLotSartedDate: "2022.04.19 15:36",
                BuyPoints: 1406,
                SalePoints: 698,
                MaxRisk: -44.40000000000009,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2033.58000000001,
                SafeAreayPoint: 64,
                Aversion: 54,
                LostTreads: -106.45,
            },
            {
                Profit: 0.2,
                StartDate: "2022.04.19 15:45",
                EndDate: "2022.04.19 16:04",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.19 15:56",
                BuyPoints: 939,
                SalePoints: 609,
                MaxRisk: -0.3599999999996726,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2058.84000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 0.6,
                StartDate: "2022.04.19 16:04",
                EndDate: "2022.04.19 16:38",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.19 16:04",
                BuyPoints: 626,
                SalePoints: 956,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2059.04000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.61,
                StartDate: "2022.04.19 16:38",
                EndDate: "2022.04.19 17:15",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.19 16:38",
                BuyPoints: 966,
                SalePoints: 636,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2059.640000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 1.03,
                StartDate: "2022.04.19 17:15",
                EndDate: "2022.04.19 22:20",
                EndLot: 0.02,
                LastTread: "Sale",
                BuyLot: 0.01,
                SaleLot: 0.02,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.19 17:38",
                BuyPoints: 1353,
                SalePoints: 1023,
                MaxRisk: -0.3599999999996726,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2060.25000000001,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: -1.02,
            },
            {
                Profit: 3.09,
                StartDate: "2022.04.19 22:20",
                EndDate: "2022.04.20 13:11",
                EndLot: 0.16,
                LastTread: "Buy",
                BuyLot: 0.21,
                SaleLot: 0.1,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.20 11:47",
                BuyPoints: 616,
                SalePoints: 1054,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2061.28000000001,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.52,
            },
            {
                Profit: 0.5600000000000001,
                StartDate: "2022.04.20 13:11",
                EndDate: "2022.04.20 15:35",
                EndLot: 0.01,
                LastTread: "Sale",
                BuyLot: 0,
                SaleLot: 0.01,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.20 13:11",
                BuyPoints: 924,
                SalePoints: 594,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2064.370000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 0.67,
                StartDate: "2022.04.20 15:35",
                EndDate: "2022.04.20 17:30",
                EndLot: 0.01,
                LastTread: "Buy",
                BuyLot: 0.01,
                SaleLot: 0,
                LotDifference: 0.01,
                LastLotSartedDate: "2022.04.20 15:35",
                BuyPoints: 696,
                SalePoints: 1026,
                MaxRisk: 0,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2064.930000000009,
                SafeAreayPoint: 90,
                Aversion: 0,
                LostTreads: 0,
            },
            {
                Profit: 2.76,
                StartDate: "2022.04.20 17:30",
                EndDate: "2022.04.21 11:26",
                EndLot: 0.16,
                LastTread: "Sale",
                BuyLot: 0.1,
                SaleLot: 0.21,
                LotDifference: 0.11,
                LastLotSartedDate: "2022.04.21 05:12",
                BuyPoints: 1038,
                SalePoints: 600,
                MaxRisk: -3.829999999999927,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2065.600000000009,
                SafeAreayPoint: 77,
                Aversion: 27,
                LostTreads: -10.51,
            },
            {
                Profit: -45.65,
                StartDate: "2022.04.21 11:26",
                EndDate: "2022.04.22 07:18",
                EndLot: 2.56,
                LastTread: "Buy",
                BuyLot: 3.41,
                SaleLot: 1.7,
                LotDifference: 1.71,
                LastLotSartedDate: "2022.04.21 20:33",
                BuyPoints: 240,
                SalePoints: 1074,
                MaxRisk: -102.5599999999997,
                MaxProfit: 0,
                StartedProfit: 0,
                StartEqity: 2068.360000000009,
                SafeAreayPoint: 60,
                Aversion: 63,
                LostTreads: 0,
            },
        ],

        End: {
            Balance: 2022.74000000001,
            MaxmimLotsRiched: 5.12,
            Deals: 362,
            Eqity: 2022.71000000001,
            Profit: -0.03,
            Ask: 1951.84,
            StartPoint: 0,
            AllLost: 1,
            AllLostProfit: -45.65,
            AllWin: 360,
            AllWinProfit: 1068.39,
            EndDate: "2022.04.22 07:18",
            Spread: 30,
        },
        Reason: 0,
    },
];
