import React, { useState } from "react";
import { TdHeader, Td } from "./TableItem";

const Tabel = ({ data, onColumnClick }) => {
    const [selected, setSelected] = useState(-1);
    console.log(data);
    return data ? (
        <table style={{ fontWeight: 500 }} className={`print-container w-full bg-blue-1 text-1`}>
            <tbody>
                <tr>
                    <TdHeader onClick={() => onColumnClick("")} title="i" />
                    {data.keys?.map((key, index) => (
                        <TdHeader onClick={() => onColumnClick("")} title={key} />
                    ))}
                </tr>
            </tbody>
            <tbody className={`${selected !== -1 ? "table-blur" : ""} `}>
                {data.items?.map((item, index) => (
                    <tr
                        className={`tr-${selected === index ? "active card-1 text-1" : index % 2 > 0 ? "1" : "2"}`}
                        key={index}
                        onClick={() => setSelected(selected === index ? -1 : index)}>
                        <Td title={index} />
                        {data.keys?.map((key, index) => (
                            <>
                                <Td title={item[key]} />
                            </>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <></>
    );
};

export default Tabel;
