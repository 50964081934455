import React from "react";
const CheckBox = ({ title, checked, setChecked, storge }) => {
    const toggleCheck = () => {
        setChecked(!checked);
        localStorage.setItem(storge, checked ? "0" : null);
    };
    return (
        <div className="cursor-pointer flex my-3 mx-1" onClick={toggleCheck}>
            <p onChange={toggleCheck} className={`shadow px-2 rounded-lg ${checked ? "bg-orange-1 text-white" : "card-2 text-1"}`}>
                {title}
            </p>
        </div>
    );
};

export default CheckBox;
