import React, { useEffect, useState } from "react";
import Button from "../../Components/Button";
import Label from "../../Components/Label";
import Round from "../../Components/Round";
import TextInput from "../../Components/TextInput";
import Formatter from "../../Utils/Formatter";

let total = 0;

const CalclateLotCard = ({ setData }) => {
    const [lot, setLot] = useState(0.1);
    const [incresFactor, setIncresFactor] = useState(3);
    const [steps, setSteps] = useState(10);
    // const [profit, setProfit] = useState(0);
    // const [balaceRequired, setBalanceRequired] = useState(0);
    const [points, setPoints] = useState(5);
    const [traies, setTraies] = useState(0);

    const calclate = () => {
        let _lot = Round(lot);
        let _points = Round(points * 10);
        total = Round(_points * _lot);
        let profits = [{ lot: "-", profit: "-", total, balanceRequired: "-", traies: "-" }];
        for (let index = 0; index < steps; index++) {
            profits.push(getResult(_lot, _points));
            _lot = Round(_lot * incresFactor);
        }
        setData({ items: profits, keys: Object.keys(profits[0]) });
    };

    const getResult = (_lot, _points) => {
        const profit = Round(_lot * _points * incresFactor);
        const balanceRequired = Round(_lot * 320 - total);
        total = Round(total + profit);

        return {
            lot: Formatter(_lot),
            profit: Formatter(profit),
            total: Formatter(total),
            balanceRequired: Formatter(balanceRequired),
            traies: (total / _points) * 10,
        };
    };

    useEffect(() => {
        calclate();
    }, [lot, incresFactor, steps, points]);

    return (
        <div style={{ direction: "ltr" }} className="flex flex-col gap-3 background py-5 rounded-md">
            <TextInput value={lot} setValue={setLot} Key="Lot" type="number" step="0.1" />
            <TextInput value={incresFactor} setValue={setIncresFactor} Key="IncresFactor" type="number" />
            <TextInput value={steps} setValue={setSteps} Key="Steps" type="number" />
            <TextInput value={points} setValue={setPoints} Key="Points" type="number" />
            {/* <Label title="Result" info={profit} />
            <Label title="BalanceRequired" info={balaceRequired} /> */}
            {/* <Button onClick={calclate} color="green" title="Calculate" /> */}
        </div>
    );
};

export default CalclateLotCard;
