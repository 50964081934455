import useLocalStorage from "@rehooks/local-storage";
import { Route, Routes } from "react-router-dom";
import Bot from "./Bot/Bot";
import MachineLearningView from "./MachineLearning/MachineLearningView";
import TreadsView from "./Treads/TreadsView";

function App() {
    const [theme, setTheme] = useLocalStorage("theme");

    return (
        <div className={`${theme || "dark"} min-h-full text-1`}>
            <p
                onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                className="bg-orange-1 text-white  z-50 left-1 top-1 px-1 rounded-full opacity-70 flex cursor-pointer absolute">
                {theme || "dark"}
            </p>
            <Routes>
                <Route path="/" element={<Bot />} />;
                <Route path="/macine" element={<MachineLearningView />} />;
                <Route path="/Capito" element={<TreadsView />} />;
            </Routes>
        </div>
    );
}

export default App;
