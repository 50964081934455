import TreadCard from "./TreadCard";
import React, { useState, useEffect } from "react";
import { JsonToTabel, orderBy } from "../Utiles/JsonFormater";
import { Samples } from "../Samples/firsto";
import Chart from "../../Components/Chart";
// import BarChart from "../../Components/BarChart";

let filterType = 1;
let arrFiles = [];
let revers = false;
const ReadFile = () => {
    const [showChart, setShowChart] = useState(false);
    const [text, setText] = useState(false);
    // let [treads, setTreads] = useState(JSON.parse(localStorage.getItem("treads"))[0]?.treads || []);
    // const [customInfo, setCustomInfo] = useState(JSON.parse(localStorage.getItem("treads"))[0].customInfo || "");
    let [fileHolder, setFileHolder] = useState("");
    const [selectedFile, setSelectedFile] = useState(-1);
    const [selections, setSelections] = useState([]);
    const [displayFile, setdisplayFile] = useState(false); // localStorage.getItem("_index") !== "0");
    const [draggStart, setDraggStart] = useState("");
    const showFile = (e) => {
        e.preventDefault();
        FormateFiles(Object.values(e.target.files));
    };

    // fetch("C:/Users/pc/AppData/Roaming/MetaQuotes/Terminal/D826E87964DB7C8F6A16A8CF69B5E2A6/MQL4/Files/Capito_XAUUSD_5 Real Time")
    // fetch("M:\\C.txt")
    // fetch("file:///M:/C.txt")
    // fetch("https://127.0.0.1:8887/file:///M:/C.txt")
    //     .then((response) => {
    //         console.log({ response });
    //         // response.text();
    //     })
    //     .then((data) => {
    //         // Do something with your data
    //         console.log(data);
    //     });

    fetch(
        // url +
        //   "?" +
        //   new URLSearchParams({
        //     email: email,
        //   })
        {
            // url: "http://127.0.0.1:8887/file///M:/C.txt",
            method: "GET",
            // headers: {
            //     Authorization: `Bearer ${token}`,
            // },
            mode: "no-cors",
        }
    ).then((res) => {
        console.log(res);
    });

    const GetSmaple = () => {
        arrFiles = [];
        Samples.forEach((sample, index) => {
            JsonToTabel(sample).then((fileObj) => {
                arrFiles.push({ ...fileObj, fileName: sample.Name });
                if (index + 1 === Samples.length) ensureAllFiles();
            });
        });
    };

    const FormateFiles = (files) => {
        arrFiles = [];
        files.forEach((file, index) => {
            const reader = new FileReader();
            reader.onload = ({ target }) => {
                JsonToTabel(target.result).then((fileObj) => {
                    arrFiles.push({ ...fileObj, fileName: file.name });
                    if (index + 1 === files.length) ensureAllFiles();
                });
            };
            reader.readAsText(file);
        });
    };

    const OrderByFile = (key) => {
        arrFiles = arrFiles.sort((o, l) => (o.customInfo.sectionTwo[key] > l.customInfo.sectionTwo[key] ? -1 : 1));
        if (revers) {
            revers = false;
            arrFiles = arrFiles.reverse();
        } else revers = true;
        localStorage.setItem("treads", JSON.stringify(arrFiles));
        ensureAllFiles();
    };

    const ensureAllFiles = () => {
        // OrderByFile("Eqity");
        localStorage.setItem("treads", JSON.stringify(arrFiles));
        inputRef.current.value = "";
        setFilesToView();
        setDraggStart("");
    };

    const setFilesToView = () => {
        let _selections = [];
        arrFiles.forEach((item) => {
            let info = item.customInfo.sectionTwo;

            let MaxRisk = item.treads.sort((o, l) => (o.MaxRisk > l.MaxRisk ? 1 : -1))[0].MaxRisk;
            let MaxProfit = item.treads.sort((o, l) => (o.MaxProfit < l.MaxProfit ? 1 : -1))[0].MaxProfit;
            _selections.push({ fileName: item.fileName, Eq: info.Eqity, MaxRisk, MaxProfit });
        });
        setSelectedFile(0);
        setFileHolder(arrFiles[0]);
        setSelections(_selections);
    };

    const inputRef = React.useRef(null);

    const handleFilter = async (key) => {
        filterType = filterType === 0 ? 1 : 0;
        fileHolder.treads = await orderBy(key, filterType, fileHolder.treads);
        setText(!text);
    };
    const choseFile = () => inputRef.current.click();

    const Clear = () => {
        localStorage.removeItem("treads");

        setSelectedFile(-1);
        setFileHolder([]);
        setSelections([]);
    };

    useEffect(() => {
        try {
            arrFiles = JSON.parse(localStorage.getItem("treads")) || [];
            if (arrFiles.length > 0) setFilesToView();
        } catch {
            Clear();
        }
    }, []);
    const fileDroped = (e) => {
        prevent(e);
        FormateFiles(Object.values(e.dataTransfer.files));
    };
    const prevent = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const onDragOver = (e) => {
        prevent(e);
        setDraggStart("drag");
    };
    const viewChart = async () => {
        fileHolder.treads = await orderBy("StartDate", false, fileHolder.treads);
        setShowChart(true);
    };
    return (
        <>
            <div onDrop={fileDroped} onDragOver={onDragOver} onDragLeave={prevent} className={`trans main-container scroller background ${draggStart}`}>
                <input ref={inputRef} multiple type="file" onChange={showFile} className="hidden" />

                {displayFile && (
                    <div
                        id="13"
                        onClick={({ target }) => {
                            if (target.id === "13") setdisplayFile(false);
                        }}
                        className="fixed background-opacity inset-0 ">
                        <div id="13" className="max-w-3xl h-screen scroller  background px-10 popup">
                            <div className="flex flex-row justify-between p-2">
                                {/* <p className="bg-orange-1 px-2 py-1 cursor-pointer rounded-lg text-white text-center mt-4" onClick={() => setdisplayFile(false)}>
                                Close
                            </p> */}
                                <p className="bg-orange-1 px-2 py-1 cursor-pointer rounded-lg text-white text-center mt-4" onClick={choseFile}>
                                    Chose
                                </p>
                                <p className="bg-blue-1 px-2 py-1 cursor-pointer rounded-lg text-white text-center mt-4" onClick={GetSmaple}>
                                    Sample
                                </p>
                                <p className="bg-red-1 px-2 py-1  cursor-pointer rounded-lg text-white text-center mt-4" onClick={Clear}>
                                    Clear
                                </p>
                            </div>
                            {selections.map((section, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-row my-4 text-white overflow-hidden items-center font-bold rounded-md cursor-pointer ${
                                        index === selectedFile ? "bg-gray-500" : "bg-gray-700 "
                                    } `}
                                    onClick={() => {
                                        setSelectedFile(index);
                                        setFileHolder(arrFiles[index]);
                                    }}>
                                    <span onClick={() => OrderByFile("MaxRisk")} className="p-2 m-2 rounded-md w-20  text-center text-gray-700   bg-red-300">
                                        {section.MaxRisk}
                                    </span>
                                    <span onClick={() => OrderByFile("MaxProfit")} className="p-2 m-2 rounded-md w-20  text-center text-gray-700 bg-green-200">
                                        {section.MaxProfit}
                                    </span>
                                    <span className="p-2 m-2 rounded-md flex-grow w-64 text-center">{section.fileName}</span>
                                    <span onClick={() => OrderByFile("Eqity")} className="p-2 m-2 rounded-md w-20  text-center text-gray-700 bg-sky-300">
                                        {section.Eq}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="flex flex-row items-center">
                    <p className="shadow card-1 py-1 px-3 ml-5 cursor-pointer rounded-lg text-1" onClick={() => setdisplayFile(true)}>
                        showFiles
                    </p>
                    <p className="shadow rounded-lg py-1 px-3 cursor-pointer text-white font-bold bg-red-1" onClick={choseFile}>
                        {"Selected : "}
                        {fileHolder.fileName || "Chose File"}
                    </p>
                    <p onClick={viewChart} className="shadow rounded-lg py-1 px-3 cursor-pointer text-white font-bold bg-purple-1 mx-5">
                        Chart
                    </p>
                </div>
                <TreadCard onClick={handleFilter} fileHolder={fileHolder} />
            </div>
            {showChart && (
                <>
                    <Chart treads={fileHolder.treads} />
                    <p
                        onClick={() => setShowChart(!showChart)}
                        className="shadow absolute top-10 rounded-lg py-1 px-3 cursor-pointer text-white font-bold bg-purple-1 mx-5">
                        Close
                    </p>
                </>
            )}
        </>
    );
};

export default ReadFile;
