import React, { useEffect, useState } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer, //, BarChart, Bar
} from "recharts";
import CheckBox from "./CheckBox";

let arr = [];
let start = 0;
let width = "100%";
let reduceValue = parseInt(localStorage.getItem("reduceValue") || 75);
const Chart = ({ treads }) => {
    // {
    //     name: "Page A",
    //     uv: 4000,
    //     pv: 2400,
    //     amt: 2400,
    // },
    let timeColor = localStorage.getItem("theme") === "dark" ? "#ffffff" : "#1f1d2b";
    const [localData, setlocalData] = useState([]);
    const [showOptions, setShowOptions] = useState(true);

    const [_endLot, set_endLot] = useState(localStorage.getItem("_EndLot") !== "0");
    const [_profit, set_profit] = useState(localStorage.getItem("_Profit") !== "0");
    const [_Time, set_Time] = useState(localStorage.getItem("_ChartTime") !== "0");
    const [_risk, set_risk] = useState(localStorage.getItem("_Risk") !== "0");

    useEffect(() => {
        arr = [];
        start = 0;
        treads?.forEach((tread) => {
            let { Profit, EndLot, StartDate, Time, MaxRisk } = tread;
            arr.push({
                Profit,
                EndLot: EndLot * 10,
                StartDate: StartDate.split(" ")[0],
                Time,
                MaxRisk,
            });
        });
        reducer();
    }, [treads]);

    const reducer = (refresh = true) => {
        let reduced = [];
        start > arr.length && (start = 0);
        let end = start + reduceValue;
        end > arr.length && (end = arr.length);

        for (let index = start; index < end; index++) {
            reduced.push(arr[index]);
        }
        setlocalData(reduced);
        if (refresh) start += 10;
    };

    const changeReducerValue = (newVal) => {
        reduceValue += newVal;
        localStorage.setItem("reduceValue", reduceValue);
        reducer(false);
    };

    // useEffect(() => {
    //     // let arr = [];
    //     // Object.keys(data.customInfo.sectionFive).forEach((key) => {
    //     //     arr.push({ lot: key, times: data.customInfo.sectionFive[key] });
    //     // });
    //     // console.log(data.customInfo.sectionFive);
    //     // console.log(arr);
    //     // // setlocalData(data.customInfo.sectionFive);
    //     // setlocalData(arr);
    // }, []);
    return (
        <>
            <div className="main-container font-bold flex flex-col scroller background select-none text-1">
                <ResponsiveContainer width={width} height="100%">
                    <LineChart data={localData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="StartDate" fontSize={12} stroke={timeColor} />
                        <YAxis />
                        <Tooltip />
                        <Legend />

                        {_endLot && <Line animationDuration={600} type="monotoneX" dataKey="EndLot" stroke="#766cfa" activeDot={{ r: 8 }} />}
                        {_profit && <Line animationDuration={600} type="monotone" dataKey="Profit" stroke="#82ca9d" />}
                        {_Time && <Line animationDuration={600} type="monotone" dataKey="Time" stroke="#ffa44e" />}
                        {_risk && <Line animationDuration={600} type="monotone" dataKey="MaxRisk" stroke="#df3743" />}
                    </LineChart>
                    {/* <BarChart
                    // width={500}
                    // height={300}
                    data={localData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="lot" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="times" stackId="a" fill="#8884d8" />
                    <Bar dataKey="lot" stackId="a" fill="#82ca9d" />
                </BarChart> */}
                </ResponsiveContainer>
            </div>
            <div className="absolute top-6 left-10">
                <CheckBox checked={showOptions} setChecked={setShowOptions} storge="showOptions" title="Options" />
            </div>
            {showOptions && (
                <div className="flex flex-col items-end absolute top-16 left-10 card-3 p-3 rounded-lg shadow ">
                    <div className="flex flex-row px-1 mb-4">
                        <CheckBox checked={_endLot} setChecked={set_endLot} storge="_EndLot" title="EndLot" />
                        <CheckBox checked={_profit} setChecked={set_profit} storge="_Profit" title="Profit" />
                        <CheckBox checked={_risk} setChecked={set_risk} storge="_Risk" title="MaxRisk" />
                        <CheckBox checked={_Time} setChecked={set_Time} storge="_ChartTime" title="Time" />
                    </div>
                    <div className="flex flex-row">
                        <p onClick={() => changeReducerValue(-5)} className="shadow rounded-lg py-1 px-3.5 cursor-pointer text-white font-bold bg-red-1">
                            -
                        </p>

                        <p onClick={reducer} className="shadow rounded-lg py-1 mx-2 px-3 cursor-pointer text-white font-bold bg-purple-1">
                            ShowMore {reduceValue}
                        </p>
                        <p onClick={() => changeReducerValue(5)} className="shadow rounded-lg py-1 px-3 cursor-pointer text-white font-bold bg-blue-1  ">
                            +
                        </p>
                        <p
                            onClick={() => {
                                width = `${arr.length}%`;
                                setlocalData(arr);
                            }}
                            className="shadow rounded-lg py-1 mx-2 px-3 cursor-pointer text-white font-bold bg-blue-1">
                            ShowAll {arr.length}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};
export default Chart;
