import React, { useState } from "react";
import CalclateLotCard from "./Components/CalclateLotCard";
import Tabel from "./Components/Tabel";

const MachineLearningView = () => {
    const [data, setData] = useState(null);
    return (
        <div className="w-screen overflow-auto px-3" onClick={() => {}}>
            <CalclateLotCard setData={setData} />
            <Tabel data={data} />
        </div>
    );
};

export default MachineLearningView;
