import React from "react";
import ReadFile from "./Components/ReadFile";

const TreadsView = () => {
    return (
        <>
            <ReadFile />
        </>
    );
};

export default TreadsView;
