import Round from "../../Components/Round";

export const JsonToTabel = async (text) => {
    let formatedObj = { treads: [], customInfo: {} };
    try {
        let parsed;
        try {
            parsed = JSON.parse(text);
        } catch {
            parsed = text;
        }
        if (Object.keys(parsed.Start || {}).includes("Aversion")) formatedObj = await Capito(parsed);
        else formatedObj = await NewToNow(parsed);

        // const lotInfo = { buy: makeLotInfo(toOrderBuy), sale: makeLotInfo(toOrderSale) };
        return formatedObj;
    } catch (err) {
        console.log(err);
        // return formatedObj;
    }
};

const makeLotInfo = (arr) => {
    let lotInfo = {};
    arr.forEach((item) => {
        let testLot = item.LastTread + " _ " + item.EndLot;
        if (lotInfo[testLot]) {
            lotInfo[testLot].lot++;
            lotInfo[testLot].Profit += Round(item.Profit);
        } else {
            lotInfo[testLot] = { lot: 1, Profit: Round(item.Profit) };
        }
    });
    Object.keys(lotInfo).forEach((key) => {
        let { lot, Profit } = lotInfo[key];
        lotInfo[key] = ` ( ${Round(Profit)} ) / ${lot} / ${Round(Profit / lot)}`;
    });
    return lotInfo;
};

const stringArray = ["LastTread", "StartDate", "EndDate"];
export const orderBy = async (key, dis, treads) => {
    let isString = stringArray.includes(key);
    if (!isString)
        treads.forEach((obj) => {
            obj[key] *= 1000;
        });
    let _ordered = dis ? treads.sort((o, l) => (o[key] > l[key] ? -1 : 1)) : treads.sort((o, l) => (o[key] < l[key] ? -1 : 1));
    if (!isString)
        treads.forEach((obj) => {
            obj[key] /= 1000;
        });
    return _ordered;
};
const NewToNow = async ({ Treads, Start, End }) => {
    try {
        Treads = Treads.slice(1);

        let EndMaxRisk = await orderBy("MaxRisk", false, Treads);
        End.MaxRisk = EndMaxRisk[0]?.MaxRisk;
        End.MaxProfit = Round(End.ProfitToClose - Start.Balance);
        End.AllWinProfit = Round(End.Eqity - Start.Balance);

        Treads.forEach((item) => {
            item.Time = Round((new Date(item.EndDate) - new Date(item.StartDate)) / (1000 * 3600));
            //item.StartDate = item.StartDate.substring(0, 15); //.split(" ")[0]; .split(".")[1];
            //item.EndDate = item.EndDate.substring(0, 15); //.split(" ")[0]; .split(".")[1];
        });
        const customInfo = {
            sectionOne: Start,
            sectionTwo: End,
        };
        return { treads: Treads, customInfo };
    } catch (error) {
        console.log(error);
    }
};

//
//
//
//
//
//

const Capito = async ({ Treads, Start, End }) => {
    let { EndDate, Balance, Eqity, MaxmimLotsRiched } = End;
    let { AllWin, AllWinProfit, AllLost, AllLostProfit, Deals } = End;

    // let { FreeMargin, MarginLevel, Margin } = _endInfo;
    Treads = Treads.slice(1);
    Treads.forEach((item) => {
        let isBuy = item.LastTread === "Buy";
        item.OtherLot = isBuy ? item.SaleLot : item.BuyLot;
        item.lotInfo = `${item.LastTread} ${isBuy ? item.BuyLot : item.SaleLot}  `;
        item.SafeGain = Round(item.Profit - item.StartedProfit);
        // item.lotInfo = `${item.LastTread} ${isBuy ? item.BuyLot + " - " + item.SaleLot : item.SaleLot + " - " + item.BuyLot} => ${item.LotDifference} `;
        item.Time = Round((new Date(item.EndDate) - new Date(item.StartDate)) / (1000 * 3600));
        // item.StartDate = item.StartDate.substring(0, 15); //.split(" ")[0]; .split(".")[1];
        // item.EndDate = item.EndDate.substring(0, 15); //.split(" ")[0]; .split(".")[1];
        item.LastLotSartedDate = item.LastLotSartedDate.substring(5, 15); //.split(" ")[0]; .split(".")[1];
    });

    let toOrderSale = Treads.filter((o) => o.LastTread === "Sale");
    let toOrderBuy = Treads.filter((o) => o.LastTread === "Buy");
    toOrderSale = await orderBy("EndLot", false, toOrderSale);
    toOrderBuy = await orderBy("EndLot", false, toOrderBuy);

    const customInfo = {
        sectionOne: Start,
        sectionTwo: { EndDate, Balance, Eqity, MaxmimLotsRiched },
        sectionThree: { Deals, AllWin, AllWinProfit, AllLost, AllLostProfit },
        sectionFour: makeLotInfo(toOrderBuy),
        sectionFive: makeLotInfo(toOrderSale),
    };
    return { treads: Treads, customInfo };
};
